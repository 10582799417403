
import build from './ja-JP/build';
import capped_amount from './ja-JP/capped_amount';
import email_translation from './ja-JP/email_translation';
import tracking_page from './ja-JP/tracking_page';

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
};
