import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { SettingDrawer } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import { Link, RunTimeLayoutConfig } from 'umi';
import { history, RequestConfig } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import { currentUser as queryCurrentUser, getCurrentMerchant } from './services/ant-design-pro/api';
import { BookOutlined, LinkOutlined, ShoppingOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
/**
 * 不需要登录的页面的路径在这里配置
 */
const nonLoginPages = [
  'user', 'tracking-page1',
]
const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/user/install';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  // currentUser?: API.CurrentUser;
  currentMerchant?: API.CurrentMerchant;
  loading?: boolean;
  fetchMerchantInfo?: () => Promise<API.CurrentMerchant | undefined>;
}> {
  const fetchMerchantInfo = async () => {
    try {
      const msg = await getCurrentMerchant();
      return msg.data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  }


  if (!(nonLoginPages.includes(history.location.pathname.split('/')[1]))) {
    const currentMerchant = await fetchMerchantInfo();
    return {
      fetchMerchantInfo,
      currentMerchant,
      settings: {},
    };
  }
  return {
    fetchMerchantInfo,
    settings: {},
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentMerchant && !(nonLoginPages.includes(history.location.pathname.split('/')[1]))) {
        history.push(loginPath);
      }
    },
    links: [<a href={`https://${initialState?.currentMerchant?.shop}/admin`} target="_blank" >
      <ShoppingOutlined />
      <span> Return to Shopify</span>
    </a >
    ],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children, props) => {
      // if (initialState?.loading) return <PageLoading />;
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "6e936bbb-ee68-453e-a42b-d402ec9f0edb";

      (function () {
        let d = document;
        let s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
        window.$crisp.push(["set", "user:email", initialState?.currentMerchant?.email])
        window.$crisp.push(["set", "user:company", [initialState?.currentMerchant?.name, { url: initialState?.currentMerchant?.shop, description: initialState?.currentMerchant?.iana_timezone, geolocation: [initialState?.currentMerchant?.primary_locale] }]]);

      })();
      return (
        <>

          {children}
          {/* {!props.location?.pathname?.includes('/user') && (
            <SettingDrawer
              enableDarkTheme
              disableUrlParams={true}
              settings={{
                navTheme: 'dark',
                // 拂晓蓝
                primaryColor: '#257cff',
                layout: 'side',
                contentWidth: 'Fixed',
                fixedHeader: true,
                fixSiderbar: true,
                colorWeak: false,
              }}
              onSettingChange={(settings) => {
                setInitialState((preInitialState) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )} */}
        </>
      );
    },
    ...initialState?.settings,
  };
};


const authHeaderInterceptor = (url: string, options: any) => {
  let token = Cookies.get('token')
  if (null === token) {
    token = '';
  }
  const authHeader = { Authorization: `Bearer ${token}` };
  return {
    url: url,
    options: { ...options, interceptors: true, headers: authHeader },
  };
};


export const request: RequestConfig = {
  // timeout: 6000,
  middlewares: [],
  requestInterceptors: [authHeaderInterceptor],
  responseInterceptors: [],
};

