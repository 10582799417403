
export default{
   "tracking_page.page_theme":"页面主题",
   "tracking_page.element_color":"元素颜色",
   "tracking_page.languages":"语言",
   "tracking_page.auto_translate":"自动翻译",
   "tracking_page.custom_html":"自定义 HTML",
   "tracking_page.preview":"预览",
   "tracking_page.apply":"应用",
   "tracking_page.pick_color":"选择您想要的主题色以自定义跟踪页面上的元素（例如按钮）。点击保存并重新加载跟踪页面以查看更改。",
   "tracking_page.save":"救",
   "tracking_page.send_email":"如果您有订单跟踪页面的模型，并希望将其作为Parcelecho的主题选项之一，请发送电子邮件至：",
   "tracking_page.applied":"应用的",
   "tracking_page.translate.desc1":"默认情况下，跟踪页面以英语显示，并且仅在添加了多种显示语言时自动提供翻译。,",
   "tracking_page.translate.desc2":"Parcelecho现在支持11种语言自定义跟踪页面上使用的措辞。如果要应用所需的英语以外的显示语言，请从下拉菜单中选择该语言，然后在文本框中输入自定义翻译。",
   "tracking_page.select_language":"选择语言",
   "tracking_page.setting_saved":"已保存设置。",
   "tracking_page.send_email_tips":"*如果您想要的语言未包含在列表中，请随时通过下面的聊天图标告知我们，或在 support@parcelecho.com 给我们发送电子邮件。",
   "tracking_page.custom_html.desc":"在跟踪页面的顶部和底部创建自定义页眉和页脚。",
   "tracking_page.custom_html.before_html_label":"在顶部添加自定义 HTML（可选）",
   "tracking_page.custom_html.after_html_label":"在底部添加自定义 HTML（可选）",
   "tracking_page.custom_html.css":"自定义 CSS（可选）",
   "tracking_page.auto_translate.title":"启用自动翻译（由微软翻译提供支持）",
   "tracking_page.auto_translate.desc":"默认情况下，所有跟踪信息都以英语显示，即使您的跟踪页面具有多种主题语言也是如此。如果您希望自动向客户提供翻译，可以启用“自动翻译”选项。跟踪信息将自动翻译，按照跟踪页面的显示语言，",
   "tracking_page.porduct_recommendation":"产品推荐",
   "tracking_page.not_show_product_recommendation":"不在跟踪页面上显示项目建议。",
   "tracking_page.collections_show_on_tracking_page":"在运输页面上显示产品系列"
}