// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/nickfan/code/parcelecho/parcelecho-front/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/Users/nickfan/code/parcelecho/parcelecho-front/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/nickfan/code/parcelecho/parcelecho-front/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/Users/nickfan/code/parcelecho/parcelecho-front/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1640672749000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "Environment Prepare",
                  "heading": "environment-prepare"
                },
                {
                  "depth": 2,
                  "value": "Provided Scripts",
                  "heading": "provided-scripts"
                },
                {
                  "depth": 3,
                  "value": "Start project",
                  "heading": "start-project"
                },
                {
                  "depth": 3,
                  "value": "Build project",
                  "heading": "build-project"
                },
                {
                  "depth": 3,
                  "value": "Check code style",
                  "heading": "check-code-style"
                },
                {
                  "depth": 3,
                  "value": "Test code",
                  "heading": "test-code"
                },
                {
                  "depth": 2,
                  "value": "More",
                  "heading": "more"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "Login",
                "path": "/user/auth",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Auth' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/user/Auth'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Install or Login",
                "path": "/user/install",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Install' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/user/Install'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Unsubscribe Email",
                "path": "/user/unsubscribe",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__UnsubscribeEmail' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/user/UnsubscribeEmail'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/tracking",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tracking' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Tracking'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Dashboard",
        "icon": "dashboard",
        "path": "/dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Shipments",
        "icon": "fileDone",
        "path": "/shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Shipments' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Shipments'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Analytics",
        "path": "/analytics",
        "icon": "RiseOutlined",
        "routes": [
          {
            "name": "Tracking Page",
            "path": "/analytics/tracking-page",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analytics__TrackingPage' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Analytics/TrackingPage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Notifications",
            "path": "/analytics/notifications",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Analytics__Notifications' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Analytics/Notifications'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "Notifications",
        "icon": "sound",
        "path": "/notifications",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Notification' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Notification'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Tracking Page",
        "path": "/tracking-page",
        "icon": "BookOutlined",
        "layout": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TrackingPage' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/TrackingPage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Billing",
        "icon": "solution",
        "path": "/billing",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Billing' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Billing'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Settings",
        "icon": "SettingOutlined",
        "path": "/settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Settings' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/Settings'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/result",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ReturnResult' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/ReturnResult'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/release-notes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ReleaseNote' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/ReleaseNote'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/nickfan/code/parcelecho/parcelecho-front/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
