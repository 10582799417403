import tracking_page from './zh-TW/tracking_page';
import build from './zh-TW/build';
import capped_amount from './zh-TW/capped_amount';
import email_translation from './zh-TW/email_translation';


export default {
  'navBar.lang': '語言',
  'layout.user.link.help': '幫助',
  'layout.user.link.privacy': '隱私',
  'layout.user.link.terms': '條款',
  'app.preview.down.block': '下載此頁面到本地項目',
  ...build,
  ...capped_amount,
  ...email_translation,
  ...tracking_page
};
