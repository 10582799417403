import build from "./bn-BD/build";
import capped_amount from "./bn-BD/capped_amount";
import email_translation from "./bn-BD/email_translation";
import tracking_page from "./bn-BD/tracking_page";


export default {
    ...build,
    ...capped_amount,
    ...email_translation,
     ...tracking_page,
};
