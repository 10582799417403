import { Settings as LayoutSettings } from '@ant-design/pro-layout';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
} = {
 navTheme: 'dark',
                // 拂晓蓝
                primaryColor: '#257cff',
                layout: 'side',
                contentWidth: 'Fixed',
                fixedHeader: true,
                fixSiderbar: true,
                colorWeak: false,
  title: 'Parcelecho',
  pwa: false,
  logo: '../logo.svg',
  iconfontUrl: '',
};

export default Settings;
