
export default{
   "analytic.tracking_page_pv":" Paginaweergaven bijhouden",
   "analytic.last_7_days":" Laatste 7 dagen",
   "analytic.last_30_days":" Afgelopen 30 dagen",
   "analytic.last_90_days":" Afgelopen 90 dagen",
   "analytic.average_pv":" Gemiddelde dagelijkse paginaweergaven",
   "analytic.total_pv":" Totaal aantal paginaweergaven",
   "analytic.tracking_pv_by_day":" Paginaweergaven per dag bijhouden",
   "analytic.pv":" Paginaweergaven",
   "analytic.date":" Datum",
   "analytic.email_sent_by_day":" Gemiddelde dagelijkse verzonden e-mails",
   "analytic.total_emails_sent":" Totaal aantal verzonden e-mails",
   "analytic.emails_sent_by_day":" E-mails verzonden per dag",
   "analytic.email_sent_count":" Aantal verzonden e-mails",
   "billing.plan_feature_1":" Toegang tot meer dan 800 vervoerders",
   "billing.plan_feature_2":" Pagina voor het bijhouden van merknamen",
   "billing.plan_feature_3":" E-mail notificatie",
   "billing.plan_feature_4":" Dashboard & Analyse",
   "billing.plan_feature_5":" Snelle ondersteuning",
   "billing.plan_feature_6":" Toegewijde ondersteuning",
   "billing.redeem_coupon":" Coupon inwisselen",
   "billing.please_enter_coupon_code":" Voer de couponcode in",
   "billing.total_shipment_last 30 days":" Totaal aantal zendingen in de afgelopen 30 dagen",
   "billing.upgarde_tips":" Zodra alle 100 trackings zijn opgebruikt, moet u upgraden naar een betaald abonnement,",
   "billing.choose_plan":" Kies Plan",
   "billing.essential":" ESSENTIEEL",
   "billing.month":" Maand",
   "billing.orders":"orders",
   "billing.extra_price":"* $0,04 voor per extra zending boven abonnementsquota",
   "billing.tip_1":" Voor trackingpagina- en e-mailmeldingen - Hoewel deze twee services worden aangedreven door Parcelecho, zorgen we ervoor dat we uw klanten nooit zullen vragen om iets te downloaden of hun informatie op te vragen, zoals sommige van onze tegenhangers doen. Onze missie is om u een betere ervaring na de verkoop te bieden, niet om onszelf te promoten bij uw klanten.",
   "billing.tip_2":" Wanneer momenteel het aantal trackings in uw abonnement is opgebruikt, wordt het opwaarderen automatisch geactiveerd. Elk herlaadpakket bevat 25 extra trackings en kost $ 1. In dit geval, als u trackings blijft gebruiken voordat het abonnement automatisch wordt verlengd - waarmee uw trackingquota ook worden verlengd - kan uw account worden toegevoegd met meer dan één opwaardeerpakket. U ontvangt een e-mailmelding telkens wanneer een opwaardeerpakket aan uw account wordt toegevoegd. De totale betaling van alle gebruikte opwaardeerpakketten wordt in rekening gebracht op je volgende Shopify-factureringsdag.,",
   "menu.Dashboard":" Dashboard",
   "menu.Analytics":" Analytics",
   "menu.Analytics.Notifications":" Meldingen",
   "menu.Analytics.Tracking Page":" Tracking pagina",
   "menu.Shipments":" Zendingen",
   "menu.Notifications":" Meldingen",
   "menu.Tracking Page":" Tracking pagina",
   "menu.Billing":" Facturering",
   "menu.Settings":" Instellingen",
   "dashboard.tab.sync_order":" Bestellingen synchroniseren",
   "dashboard.tab.enable_email_notification":" E-mailmeldingen inschakelen",
   "dashboard.tab.add_tracking_page":" Trackingpagina toevoegen",
   "dashboard.content.enable_email_notification.one":" Als u ervoor kiest om de e-mailmeldingsservice in te schakelen, ontvangen uw klanten een update-e-mail over elke belangrijke statuswijziging van hun bestellingen,",
   "dashboard.content.enable_email_notification.two":" Je zou naar de",
   "dashboard.content.enable_email_notification.three":" Meldingen",
   "dashboard.content.enable_email_notification.four":"tabblad aan de linkerkant om verder te configureren of de e-mailmelding van een specifieke verzendgebeurtenis moet worden ingeschakeld.",
   "dashboard.content.add_tracking_page.one":" De standaardlink van de Parcelecho-trackingpagina gebruikt het domein van je eigen Shopify-winkel.",
   "dashboard.content.add_tracking_page.two":" Dit is nuttig voor SEO en kan de Alexa-ranking van uw winkel verbeteren. Kopieer de onderstaande link en",
   "dashboard.content.add_tracking_page.three":"volg onze stapsgewijze handleiding",
   "dashboard.content.add_tracking_page.four":"on het toevoegen van de trackingpagina aan je Shopify-winkel.",
   "dashboard.content.add_tracking_page.five":" Momenteel past de trackingpagina standaard de kleur blauw toe. U kunt naar het tabblad Trackingpagina aan de linkerkant gaan en de themakleur van de trackingpagina naar wens aanpassen.,",
   "dashboard.content.sync_order.one":" Bedankt dat je Parcelecho eens hebt geprobeerd!",
   "dashboard.content.sync_order.two":" Nu heeft Parcelecho 10 van uw laatste afhandelingen gesynchroniseerd in het trackingsysteem. Bekijk hieronder hoe de analyses van Parcelecho werken, evenals de huidige gebruiksstatus van uw plan. Als u de gesynchroniseerde uitvoeringen wilt bekijken, gaat u naar de,",
   "dashboard.content.sync_order.shipment":" Zendingen",
   "dashboard.content.sync_order.three":"tab aan de linkerkant. Daar kunt u ervoor kiezen om meer uitgevoerde bestellingen te synchroniseren en kiezen of u een gesynchroniseerde uitvoering wilt verwijderen van het volgen om de trackingquota op te slaan. Als een afhandeling op schema ligt, kan Parcelecho uw klant op de hoogte stellen met e-mails wanneer deze bestelling wordt bijgewerkt.",
   "dashboard.content.sync_order.four":" Ga naar het volgende tabblad op de e-mailmeldingsservice om door te gaan met het instellen voor het starten met Parcelecho.",
   "dashboard.dismiss":" Ontslaan",
   "dashboard.get_started":" Slag",
   "dashboard.proportion_status.order_by_status":" Bestellingen op status",
   "dashboard.intro.vaild_rate":" Geldig tarief",
   "dashboard.intro.vaild_rate_tip":" Geldig tarief daalt wanneer het aantal lopende orders stijgt. Klik op de kaart om alle lopende bestellingen weer te geven die aandacht nodig hebben.",
   "dashboard.intro.exceptions":" Uitzonderingen",
   "dashboard.intro.exceptions_tip":" Hier wordt het huidige aantal uitzonderingsorders weergegeven. Klik op de kaart om alle uitzonderingsorders weer te geven.",
   "dashboard.intro.total_exceptions":" Totaal aantal uitzonderingen",
   "dashboard.intro.tracking_pv":" Pagina PV bijhouden",
   "dashboard.intro.tracking_pv_tip":" Paginaweergaven naar uw trackingpagina in de afgelopen 7 dagen.",
   "dashboard.intro.today_views":" De standpunten van vandaag",
   "dashboard.intro.unfulfilled_orders":" Onvervulde bestellingen",
   "dashboard.intro.unfulfilled_orders_tip":" Toont het huidige aantal niet-uitgevoerde bestellingen en artikelen binnen. Klik op de kaart om alle onvervulde bestellingen te bekijken.",
   "dashboard.intro.total_unfulfilled_order":" Totaal niet-uitgevoerde bestellingen",
   "dashboard.intro.unfulfilled_items":" Onvervulde items",
   "dashboard.intor.total_pending_count":" Totaal aantal in behandeling",
   "dashboard.fulfillment_card.title":" Afhandeling en bestellingen per dag",
   "dashboard.fulfillment_card.daily_fulfillment":" Dagelijkse vervulling",
   "dashboard.fulfillment_card.date":" Datum",
   "dashboard.plan.cacel_plan_tip":" Weet u zeker dat u uw huidige abonnement wilt annuleren?",
   "dashboard.plan.cacel_text":" Nee laat maar,",
   "dashboard.plan.cacel_confirm":" Mijn abonnement annuleren",
   "dashboard.plan.overview":" Plan Overzicht",
   "dashboard.plan.upgrade":" Opwaarderen",
   "dashboard.plan.tracking_used":" Gebruikte tracking",
   "dashboard.plan.next_invoice":" Volgende factuur",
   "dashboard.plan.cancel_subscription":" Abonnement opzeggen",
   "notifications.title":" E-mailmeldingen",
   "notifications.in_transit_desc":" Breng uw klant op de hoogte met een e-mail wanneer hun pakket onderweg is naar de bestemming.",
   "notifications.exception_desc":" Breng uw klant op de hoogte met een e-mail wanneer de koerier heeft ontdekt dat hun pakket niet kan worden bezorgd of gewoon de levering niet kan voltooien omdat het pakket is teruggestuurd naar de afzender of het is verloren / beschadigd.",
   "notifications.on_delivery_desc":" Breng uw klant op de hoogte met een e-mail wanneer hun pakket is afgeleverd voor levering of klaar is om te worden opgehaald bij het lokale kantoor of de postfaciliteit.",
   "notifications.delivered_desc":" Breng uw klant op de hoogte met een e-mail wanneer hun pakket succesvol is afgeleverd.",
   "notifications.view_template":" Bekijk template",
   "settings.title":" Instellingen",
   "settings.powered_by_parcelecho":" Aangedreven door Parcelecho",
   "settings.powered_by_parcelecho_desc":" Toon of verwijder Powered By Parcelecho op de trackingpagina",
   "settings.get_started_card":" Kaart Aan de slag",
   "settings.get_started1_card_desc":" Aan de slag op Dashboard weergeven of verbergen",
   "tracking_page.page_theme":" Pagina Thema",
   "tracking_page.element_color":" Element Kleur",
   "tracking_page.languages":" Talen",
   "tracking_page.auto_translate":" Automatisch vertalen",
   "tracking_page.custom_html":" Aangepaste HTML",
   "tracking_page.preview":" Voorbeeld",
   "tracking_page.apply":" Toepassen",
   "tracking_page.pick_color":" Kies een accentkleur die u leuk vindt om de elementen (bijvoorbeeld de knoppen) op de trackingpagina aan te passen. Klik op Opslaan en laad de trackingpagina opnieuw om de wijziging weer te geven.",
   "tracking_page.save":" Redden",
   "tracking_page.send_email":" Als u een mockup van de pagina voor het volgen van bestellingen heeft en deze een van de thema-opties in Parcelecho wilt maken, stuur dan een e-mail naar ons op,",
   "tracking_page.applied":" Toegepast",
   "tracking_page.translate.desc1":" Standaard wordt de trackingpagina in het Engels weergegeven en biedt alleen automatisch vertalingen wanneer er meer dan één weergavetaal is toegevoegd. ,",
   "tracking_page.translate.desc2":" Parcelecho ondersteunt nu 11 talen die de gebruikte bewoordingen op de trackingpagina aanpassen. Als u de gewenste weergavetaal anders dan Engels wilt toepassen, selecteert u de taal in het vervolgkeuzemenu en voert u uw aangepaste vertaling in het tekstvak in.",
   "tracking_page.select_language":" Taal selecteren",
   "tracking_page.setting_saved":" Instellingen opgeslagen.",
   "tracking_page.send_email_tips":"* Als de gewenste taal niet in de lijst is opgenomen, aarzel dan niet om ons dit te laten weten via het onderstaande chatpictogram of stuur ons een e-mail op support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Maak een aangepaste kop- en voettekst boven en onder aan uw trackingpagina.",
   "tracking_page.custom_html.before_html_label":" Voeg aangepaste HTML toe aan de bovenkant (optioneel)",
   "tracking_page.custom_html.after_html_label":" Voeg aangepaste HTML onderaan toe (optioneel)",
   "tracking_page.custom_html.css":" Aangepaste CSS (optioneel)",
   "tracking_page.auto_translate.title":" Auto Translate inschakelen (mogelijk gemaakt door Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Standaard wordt alle trackinginformatie in het Engels weergegeven, zelfs als uw trackingpagina meerdere thematalen heeft. Als u vertalingen automatisch aan uw klanten wilt aanbieden, kunt u de optie Automatisch vertalen inschakelen. Trackinginformatie wordt automatisch vertaald, in overeenstemming met de weergavetaal van de trackingpagina,",
   "shipments.sync_7_days":" Afhandelingen van 7 dagen synchroniseren",
   "shipments.sync_14_days":" 14 dagen afhandelingen synchroniseren",
   "shipments.sync_30_days":" Afhandelingen van 30 dagen synchroniseren",
   "shipments.order_no":" Bestelnr.",
   "shipments.product":" Product",
   "shipments.carrier":" Vervoerder",
   "shipments.tracking_number":" Tracking nr.",
   "shipments.ship_date":" Verzenddatum",
   "shipments.status":" Status",
   "shipments.from":" Van",
   "shipments.to":" Aan",
   "shipments.time_used":" Gebruikte tijd",
   "shipments.on_track":" Op schema",
   "shipments.all":" Alle",
   "shipments.pending":" Aanhangig",
   "shipments.exception":" Uitzondering",
   "shipments.undelivered":" Niet geleverd",
   "shipments.stalled":" Vastgelopen",
   "shipments.in_transit":" Onderweg",
   "shipments.on_delivery":" Bij levering",
   "shipments.delivered":" Geleverd",
   "shipments.by_tracking_no":" Door trackingnr.",
   "shipments.by_carrier_name":" Op naam van de vervoerder",
   "shipments.by_order_no":" Op bestelling nr.",
   "shipments.search_to_select_carrier":" Zoeken om vervoerder te selecteren",
   "shipments.type_keyword":" Typ trefwoord...",
   "shipments.search":" Zoeken",
   "shipments.reset":" Terugstellen",
   "shipments.not_set":" Niet ingesteld",
   "shipments.modify_success":" is gewijzigd",
   "shipments.modfiy_error":" Fout wijzigen.",
   "shipments.select_a_shipment":" Selecteer in ieder geval een zending.",
   "shipments.edit_carrier":" Vervoerder bewerken",
   "shipments.cancel":" Annuleren",
   "shipments.submit":" Opslaan",
   "shipments.change_to":" Wijzigen in"
}