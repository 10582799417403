export default {
    'shipments.sync_7_days': 'Sync 7 days fulfillments',
    'shipments.sync_14_days': 'Sync 14 days fulfillments',
    'shipments.sync_30_days': 'Sync 30 days fulfillments',
    'shipments.order_no': 'Order No.',
    'shipments.product': 'Product',
    'shipments.carrier': 'Carrier',
    'shipments.tracking_number': 'Tracking No.',
    'shipments.ship_date': 'Ship Date',
    'shipments.status': 'Status',
    'shipments.from': 'From',
    'shipments.to': 'To',
    'shipments.time_used': 'Time Used',
    'shipments.on_track': 'On Track',
    'shipments.all': 'All',
    'shipments.pending': 'Pending',
    'shipments.exception': 'Exception',
    'shipments.undelivered': 'Undelivered',
    'shipments.stalled': 'Stalled',
    'shipments.in_transit': 'In Transit',
    'shipments.on_delivery': 'On Delivery',
    'shipments.delivered': 'Delivered',
    'shipments.by_tracking_no': 'By Tracking No.',
    'shipments.by_carrier_name': 'By Carrier Name',
    'shipments.by_order_no': 'By Order No.',
    'shipments.search_to_select_carrier': 'Search to Select Carrier',
    'shipments.type_keyword': 'Type keyword...',
    'shipments.search': 'Search',
    'shipments.reset': 'Reset',
     'shipments.not_set': 'Not Set',
    'shipments.modify_success': 'was modified successfully',  
    'shipments.modfiy_error': 'Modify error.',  
    'shipments.select_a_shipment': 'Please at least select a shipment.', 
    'shipments.edit_carrier': 'Edit Carrier', 
      'shipments.cancel': 'Cancel', 
    'shipments.submit': 'Submit', 
        'shipments.change_to': 'Change to', 
}