
export default{
   "tracking_page.page_theme":" Tema della pagina",
   "tracking_page.element_color":" Colore elemento",
   "tracking_page.languages":" Lingue",
   "tracking_page.auto_translate":" Traduzione automatica",
   "tracking_page.custom_html":" HTML personalizzato",
   "tracking_page.preview":" Anteprima",
   "tracking_page.apply":" Applicare",
   "tracking_page.pick_color":" Scegli un colore di accento che ti piace per personalizzare gli elementi (ad esempio i pulsanti) nella pagina di monitoraggio. Fare clic su Salva e ricaricare la pagina di monitoraggio per visualizzare la modifica.",
   "tracking_page.save":" Salvare",
   "tracking_page.send_email":" Se hai un mockup della pagina di tracciamento dell'ordine e desideri renderlo una delle opzioni tematiche in Parcelecho, ti preghiamo di inviarci un'e-mail all'indirizzo,",
   "tracking_page.applied":" Applicato",
   "tracking_page.translate.desc1":" Per impostazione predefinita, la pagina di monitoraggio viene visualizzata in inglese e offre automaticamente le traduzioni solo quando è stata aggiunta più di una lingua di visualizzazione. ,",
   "tracking_page.translate.desc2":" Parcelecho ora supporta 11 lingue personalizzando la dicitura utilizzata nella pagina di monitoraggio. Se desideri applicare la lingua di visualizzazione desiderata diversa dall'inglese, seleziona la lingua dal menu a discesa e inserisci la traduzione personalizzata nella casella di testo.",
   "tracking_page.select_language":" Seleziona lingua",
   "tracking_page.setting_saved":" Impostazioni salvate.",
   "tracking_page.send_email_tips":" * Se la lingua desiderata non è inclusa nell'elenco, non esitate a farcelo sapere tramite l'icona della chat qui sotto o inviateci un'e-mail al support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Crea intestazione e piè di pagina personalizzati nella parte superiore e inferiore della pagina di monitoraggio.",
   "tracking_page.custom_html.before_html_label":" Aggiungi HTML personalizzato in alto (facoltativo)",
   "tracking_page.custom_html.after_html_label":" Aggiungi HTML personalizzato in basso (opzionale)",
   "tracking_page.custom_html.css":" CSS personalizzato (opzionale)",
   "tracking_page.auto_translate.title":" Abilita traduzione automatica (con tecnologia Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Per impostazione predefinita, tutte le informazioni di monitoraggio vengono visualizzate in inglese anche quando la pagina di monitoraggio ha più lingue del tema. Se desideri offrire traduzioni automaticamente ai tuoi clienti, puoi abilitare l'opzione Traduzione automatica. Le informazioni di tracciamento verranno tradotte automaticamente, in conformità con la lingua di visualizzazione della pagina di tracciamento,",
   "tracking_page.porduct_recommendation":" Raccomandazione del prodotto"
}