import build from './fa-IR/build';
import capped_amount from './fa-IR/capped_amount';
import email_translation from './fa-IR/email_translation';
import tracking_page from './fa-IR/tracking_page';

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
};
