
export default{
   "tracking_page.page_theme":" تم صفحه",
   "tracking_page.element_color":" رنگ عنصر",
   "tracking_page.languages":" زبان",
   "tracking_page.auto_translate":" ترجمه خودکار",
   "tracking_page.custom_html":" اچ ام تی سفارشی",
   "tracking_page.preview":" پیشنمایش",
   "tracking_page.apply":" درخواست",
   "tracking_page.pick_color":" انتخاب رنگ لهجه شما می خواهم برای سفارشی کردن عناصر (به عنوان مانند دکمه ها) در صفحه ردیابی. برای مشاهده تغییر روی ذخیره و بارگذاری مجدد صفحه ردیابی کلیک کنید.",
   "tracking_page.save":" ذخیره",
   "tracking_page.send_email":" اگر شما یک تمسخر از سفارش ردیابی صفحه و می خواهم آن را به یکی از گزینه های تم در Parcelecho لطفا ارسال یک ایمیل به ما در،",
   "tracking_page.applied":" کاربردی",
   "tracking_page.translate.desc1":" به طور پیش فرض صفحه ردیابی به زبان انگلیسی ظاهر می شود، و ترجمه ها را به طور خودکار تنها زمانی ارائه می دهد که بیش از یک زبان نمایش اضافه شد. ,",
   "tracking_page.translate.desc2":" Parcelecho در حال حاضر پشتیبانی از 11 زبان سفارشی جمله بندی مورد استفاده در صفحه ردیابی. اگر می خواهید زبان نمایش مورد نظر خود را غیر از زبان انگلیسی اعمال کنید، زبان را از منوی کشویی انتخاب کرده و ترجمه سفارشی خود را در جعبه متن وارد کنید.,",
   "tracking_page.select_language":" انتخاب زبان",
   "tracking_page.setting_saved":" تنظیمات ذخیره شده.",
   "tracking_page.send_email_tips":"*If the language you want is not included in the list don\\t hesit to let us know via the chat icon below or drop us an email at support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" ایجاد هدر سفارشی و پاورقی در بالا و پایین صفحه ردیابی خود را.",
   "tracking_page.custom_html.before_html_label":" افزودن HTML سفارشی در بالا (اختیاری)",
   "tracking_page.custom_html.after_html_label":" اضافه کردن HTML سفارشی در پایین(اختیاری)",
   "tracking_page.custom_html.css":" CSS سفارشی (اختیاری)",
   "tracking_page.auto_translate.title":" فعال کردن ترجمه خودکار (شده توسط مترجم مایکروسافت)",
   "tracking_page.auto_translate.desc":" به طور پیش فرض تمام اطلاعات ردیابی به زبان انگلیسی ظاهر می شود حتی زمانی که صفحه ردیابی شما دارای چندین زبان تم است. اگر می خواهید ترجمه ها را به صورت خودکار به مشتریان خود ارائه کنید، می توانید گزینه Auto Translate را فعال کنید. اطلاعات ردیابی به طور خودکار ترجمه خواهد شد، مطابق با زبان نمایش صفحه ردیابی ،",
   "tracking_page.porduct_recommendation":" توصیه محصول",
   "tracking_page.not_show_product_recommendation":" توصیه پورکت را در صفحه ردیابی نشان نمی دهد.",
   "tracking_page.collections_show_on_tracking_page":" مجموعه ها در صفحه trakcing نشان می دهد",
   "tracking_page.select_a_collection":" یک مجموعه را انتخاب کنید"
}