
export default{
   "analytic.tracking_page_pv":" Seguimiento de páginas vistas de página",
   "analytic.last_7_days":" Últimos 7 días",
   "analytic.last_30_days":" Últimos 30 días",
   "analytic.last_90_days":" Últimos 90 días",
   "analytic.average_pv":" Promedio de páginas vistas diarias",
   "analytic.total_pv":" Total de páginas vistas",
   "analytic.tracking_pv_by_day":" Seguimiento de páginas vistas por día",
   "analytic.pv":" Páginas vistas",
   "analytic.date":" Fecha",
   "analytic.email_sent_by_day":" Promedio de correos electrónicos diarios enviados",
   "analytic.total_emails_sent":" Total de correos electrónicos enviados",
   "analytic.emails_sent_by_day":" Correos electrónicos enviados por día",
   "analytic.email_sent_count":" Recuento de correos electrónicos enviados",
   "billing.plan_feature_1":" Acceso a más de 800 operadores",
   "billing.plan_feature_2":" Página de seguimiento de marca",
   "billing.plan_feature_3":" Notificación por correo electrónico",
   "billing.plan_feature_4":" Panel de control y análisis",
   "billing.plan_feature_5":" Soporte rápido",
   "billing.plan_feature_6":" Soporte dedicado",
   "billing.redeem_coupon":" Canjear cupón",
   "billing.please_enter_coupon_code":" Por favor, introduzca el código de cupón",
   "billing.total_shipment_last 30 days":" Total de envíos en los últimos 30 días",
   "billing.upgarde_tips":" Una vez que se agoten los 100 seguimientos, deberá actualizar a un plan pago,",
   "billing.choose_plan":" Elija plan",
   "billing.essential":" ESENCIAL",
   "billing.month":" Mes",
   "billing.orders":"ordenes",
   "billing.extra_price":"* $0.04 por envío adicional más allá de las cuotas de suscripción",
   "billing.tip_1":" Para la página de seguimiento y las notificaciones por correo electrónico: aunque estos dos servicios funcionan con Parcelecho, le aseguramos que nunca le pediremos a sus clientes que descarguen nada o soliciten su información, como lo hacen algunos de nuestros homólogos. Nuestra misión es brindarle una mejor experiencia post-venta, no promocionarnos entre sus clientes.",
   "billing.tip_2":" Actualmente, cuando se agote el número de seguimientos en su plan de suscripción, la recarga se activará automáticamente. Cada paquete de recarga incluye 25 seguimientos adicionales y cuesta $ 1. En este caso, si sigue consumiendo seguimientos antes de la renovación automática de la suscripción, con la que también se renuevan sus cuotas de seguimiento, su cuenta podría agregarse con más de un paquete de recarga. Recibirá un correo electrónico de notificación cada vez que se agregue un paquete de recarga a su cuenta. El pago total de todos los paquetes de recarga utilizados se cobrará el próximo día de facturación de Shopify.",
   "menu.Dashboard":" Salpicadero",
   "menu.Analytics":" Analítica",
   "menu.Analytics.Notifications":" Notificaciones",
   "menu.Analytics.Tracking Page":" Página de seguimiento",
   "menu.Shipments":" Envíos",
   "menu.Notifications":" Notificaciones",
   "menu.Tracking Page":" Página de seguimiento",
   "menu.Billing":" Facturación",
   "menu.Settings":" Configuración",
   "dashboard.tab.sync_order":" Sincronizar pedidos",
   "dashboard.tab.enable_email_notification":" Habilitar notificaciones por correo electrónico",
   "dashboard.tab.add_tracking_page":" Agregar página de seguimiento",
   "dashboard.content.enable_email_notification.one":" Si elige habilitar el servicio de notificación por correo electrónico, sus clientes recibirán un correo electrónico de actualización sobre cada cambio de estado importante de sus pedidos,",
   "dashboard.content.enable_email_notification.two":" Podrías ir al",
   "dashboard.content.enable_email_notification.three":" Notificaciones",
   "dashboard.content.enable_email_notification.four":" de la izquierda para configurar aún más si habilitar la notificación por correo electrónico de un evento de envío específico.",
   "dashboard.content.add_tracking_page.one":" El enlace predeterminado de la página de seguimiento de Parcelecho utiliza el dominio de tu propia tienda Shopify.",
   "dashboard.content.add_tracking_page.two":" Esto es útil para el SEO y podría mejorar el ranking de Alexa de su tienda. Por favor, copie el enlace a continuación y",
   "dashboard.content.add_tracking_page.three":"seguir nuestra guía paso a paso",
   "dashboard.content.add_tracking_page.four":" al agregar la página de seguimiento a tu tienda Shopify.",
   "dashboard.content.add_tracking_page.five":" Actualmente la página de seguimiento aplica el color de azul de forma predeterminada. Puede ir a la pestaña Página de seguimiento a la izquierda y ajustar el color del tema de la página de seguimiento como desee.",
   "dashboard.content.sync_order.one":" ¡Gracias por probar Parcelecho!",
   "dashboard.content.sync_order.two":" Ahora Parcelecho ha sincronizado 10 de sus últimos cumplimientos en el sistema de seguimiento. Vea a continuación cómo funcionan los análisis de Parcelecho, así como el estado de uso actual de su plan. Para ver los cumplimientos sincronizados, vaya a,",
   "dashboard.content.sync_order.shipment":" Envíos",
   "dashboard.content.sync_order.three":"tablada a la izquierda. Allí puede seleccionar sincronizar más pedidos cumplidos y elegir si desea eliminar un cumplimiento sincronizado de ser rastreado para guardar las cuotas de seguimiento. Si un cumplimiento está en camino, Parcelecho puede notificar a su cliente con correos electrónicos cuando se actualice este pedido.",
   "dashboard.content.sync_order.four":" Vaya a la siguiente pestaña en el servicio de notificación por correo electrónico para continuar con su configuración para comenzar a usar Parcelecho.",
   "dashboard.dismiss":" Despedir",
   "dashboard.get_started":" Comenzar",
   "dashboard.proportion_status.order_by_status":" Pedidos por estado",
   "dashboard.intro.vaild_rate":" Tarifa válida",
   "dashboard.intro.vaild_rate_tip":" La tasa válida disminuye cuando aumenta el número de órdenes pendientes. Haga clic en la tarjeta para ver todos los pedidos pendientes que necesitan atención.",
   "dashboard.intro.exceptions":" Excepciones",
   "dashboard.intro.exceptions_tip":" Muestra el número actual de órdenes de excepción. Haga clic en la tarjeta para ver todos los pedidos de excepción.",
   "dashboard.intro.total_exceptions":" Total de excepciones",
   "dashboard.intro.tracking_pv":" Página de seguimiento PV",
   "dashboard.intro.tracking_pv_tip":" Páginas vistas a tu página de seguimiento en los últimos 7 días.",
   "dashboard.intro.today_views":" Vistas de hoy",
   "dashboard.intro.unfulfilled_orders":" Pedidos no cumplidos",
   "dashboard.intro.unfulfilled_orders_tip":" Muestra el número actual de pedidos y artículos no completados. Haga clic en la tarjeta para ver todos los pedidos no completados.",
   "dashboard.intro.total_unfulfilled_order":" Total de pedidos no cumplidos",
   "dashboard.intro.unfulfilled_items":" Artículos no cumplidos",
   "dashboard.intor.total_pending_count":" Recuento total pendiente",
   "dashboard.fulfillment_card.title":" Cumplimiento y pedidos por día",
   "dashboard.fulfillment_card.daily_fulfillment":" Cumplimiento diario",
   "dashboard.fulfillment_card.date":" Fecha",
   "dashboard.plan.cacel_plan_tip":" ¿Está seguro de que desea cancelar su plan actual?",
   "dashboard.plan.cacel_text":" No importa, no importa,",
   "dashboard.plan.cacel_confirm":" Cancelar mi plan",
   "dashboard.plan.overview":" Información general del plan",
   "dashboard.plan.upgrade":" Actualizar",
   "dashboard.plan.tracking_used":" Seguimiento utilizado",
   "dashboard.plan.next_invoice":" Próxima factura",
   "dashboard.plan.cancel_subscription":" Cancelar suscripción",
   "notifications.title":" Notificaciones por correo electrónico",
   "notifications.in_transit_desc":" Notifique a su cliente con un correo electrónico cuando su paquete esté en camino al destino.",
   "notifications.exception_desc":" Notifique a su cliente con un correo electrónico cuando el transportista descubra que su paquete no se puede entregar o simplemente no puede completar la entrega porque el paquete ha sido devuelto al remitente o se ha perdido / dañado.",
   "notifications.on_delivery_desc":" Notifique a su cliente con un correo electrónico cuando su paquete haya sido entregado para su entrega o listo para ser recogido en la oficina local o en las instalaciones postales.",
   "notifications.delivered_desc":" Notifique a su cliente con un correo electrónico cuando su paquete se haya entregado correctamente.",
   "notifications.view_template":" Ver plantilla",
   "tracking_page.page_theme":" Tema de la página",
   "tracking_page.element_color":" Color del elemento",
   "tracking_page.languages":" Idiomas",
   "tracking_page.auto_translate":" Traducción automática",
   "tracking_page.custom_html":" HTML personalizado",
   "tracking_page.preview":" Vista previa",
   "tracking_page.apply":" Aplicar",
   "tracking_page.pick_color":" Elija un color de acento que le guste para personalizar los elementos (por ejemplo, los botones) en la página de seguimiento. Haga clic en Guardar y vuelva a cargar la página de seguimiento para ver el cambio.",
   "tracking_page.save":" Salvar",
   "tracking_page.send_email":" Si tiene una maqueta de la página de seguimiento de pedidos y desea convertirla en una de las opciones de tema en Parcelecho, envíenos un correo electrónico a,",
   "tracking_page.applied":" Aplicado",
   "tracking_page.translate.desc1":" De forma predeterminada, la página de seguimiento aparece en inglés y ofrece traducciones automáticamente solo cuando se ha agregado más de un idioma de visualización. ,",
   "tracking_page.translate.desc2":" Parcelecho ahora admite 11 idiomas para personalizar la redacción utilizada en la página de seguimiento. Si desea aplicar el idioma de visualización que desee distinto del inglés, seleccione el idioma en el menú desplegable e introduzca la traducción personalizada en el cuadro de texto.",
   "tracking_page.select_language":" Seleccionar idioma",
   "tracking_page.setting_saved":" Configuración guardada.",
   "tracking_page.send_email_tips":"*Si el idioma que desea no está incluido en la lista, no dude en hacérnoslo saber a través del icono de chat a continuación o envíenos un correo electrónico a support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Cree un encabezado y pie de página personalizados en la parte superior e inferior de su página de seguimiento.",
   "tracking_page.custom_html.before_html_label":" Agregar HTML personalizado en la parte superior (opcional)",
   "tracking_page.custom_html.after_html_label":" Agregar HTML personalizado en la parte inferior (opcional)",
   "tracking_page.custom_html.css":" CSS personalizado (opcional)",
   "tracking_page.auto_translate.title":" Habilitar la traducción automática (con tecnología de Microsoft Translator)",
   "tracking_page.auto_translate.desc":" De forma predeterminada, toda la información de seguimiento aparece en inglés, incluso cuando su página de seguimiento tiene varios idiomas de tema. Si desea ofrecer traducciones automáticamente a sus clientes, puede habilitar la opción Traducción automática. La información de seguimiento se traducirá automáticamente, de acuerdo con el idioma de visualización de la página de seguimiento,",
   "shipments.sync_7_days":" Sincronizar cumplimientos de 7 días",
   "shipments.sync_14_days":" Sincronizar cumplimientos de 14 días",
   "shipments.sync_30_days":" Sincronizar cumplimientos de 30 días",
   "shipments.order_no":" Orden No.",
   "shipments.product":" Producto",
   "shipments.carrier":" Portador",
   "shipments.tracking_number":" Seguimiento No.",
   "shipments.ship_date":" Fecha de envío",
   "shipments.status":" Estado",
   "shipments.from":" De",
   "shipments.to":" Para",
   "shipments.time_used":" Tiempo utilizado",
   "shipments.on_track":" En camino",
   "shipments.all":" Todo",
   "shipments.pending":" Pendiente",
   "shipments.exception":" Excepción",
   "shipments.undelivered":" No entregado",
   "shipments.stalled":" Estancado",
   "shipments.in_transit":" En tránsito",
   "shipments.on_delivery":" A la entrega",
   "shipments.delivered":" Entregado",
   "shipments.by_tracking_no":" Mediante el seguimiento No.",
   "shipments.by_carrier_name":" Por nombre del transportista",
   "shipments.by_order_no":" Por Orden No.",
   "shipments.search_to_select_carrier":" Buscar para seleccionar operador",
   "shipments.type_keyword":" Escriba la palabra clave...",
   "shipments.search":" Buscar",
   "shipments.reset":" Restablecimiento",
   "shipments.not_set":" No configurado",
   "shipments.modify_success":" se modificó correctamente",
   "shipments.modfiy_error":" Error de modificación.",
   "shipments.select_a_shipment":" Por favor, al menos seleccione un envío.",
   "shipments.edit_carrier":" Editar portador",
   "shipments.cancel":" Cancelar",
   "shipments.submit":" Enviar",
   "shipments.change_to":" Cambiar a",
   "settings.title":" Configuración",
   "settings.powered_by_parcelecho":" Desarrollado por Parcelecho",
   "settings.powered_by_parcelecho_desc":" Mostrar o eliminar Powered By Parcelecho en la página de seguimiento",
   "settings.get_started_card":" Tarjeta de introducción",
   "settings.get_started1_card_desc":" Mostrar u ocultar introducción en el panel"
}