
import build from './id-ID/build';
import capped_amount from './id-ID/capped_amount';
import email_translation from './id-ID/email_translation';
import tracking_page from './id-ID/tracking_page';

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
};
