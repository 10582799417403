export default {
    'analytic.tracking_page_pv': 'Tracking Page Pageviews',
    'analytic.last_7_days': 'Last 7 days',
    'analytic.last_30_days': 'Last 30 days',
    'analytic.last_90_days': 'Last 90 days',
    'analytic.average_pv': 'Average Daily Pageviews',
    'analytic.total_pv': 'Total Pageviews',
    'analytic.tracking_pv_by_day':'Tracking Page Pageviews By Day',
    'analytic.pv': 'Pageviews',
    'analytic.date': 'Date',
    'analytic.email_sent_by_day': 'Average Daily Emails Sent',
    'analytic.total_emails_sent': 'Total Emails Sent',
    'analytic.emails_sent_by_day': 'Emails Sent By Day',
    'analytic.email_sent_count':'Email sent count',
}