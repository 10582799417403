
export default{
   "analytic.tracking_page_pv":"跟蹤網頁流覽量",
   "analytic.last_7_days":"最近 7 天",
   "analytic.last_30_days":"最近30天",
   "analytic.last_90_days":"最近 90 天",
   "analytic.average_pv":"平均每日流覽量",
   "analytic.total_pv":"總流覽量",
   "analytic.tracking_pv_by_day":"按天跟蹤頁面流覽量",
   "analytic.pv":"網頁流覽量",
   "analytic.date":"日期",
   "analytic.email_sent_by_day":"平均每日發送的電子郵件",
   "analytic.total_emails_sent":"發送的電子郵件總數",
   "analytic.emails_sent_by_day":"每天發送的電子郵件",
   "analytic.email_sent_count":"電子郵件發送計數",
   "billing.plan_feature_1":"訪問800多個運營商",
   "billing.plan_feature_2":"品牌跟蹤頁面",
   "billing.plan_feature_3":"電子郵件通知",
   "billing.plan_feature_4":"儀錶板和分析",
   "billing.plan_feature_5":"快速支援",
   "billing.plan_feature_6":"專業支援",
   "billing.redeem_coupon":"兌換優惠券",
   "billing.please_enter_coupon_code":"請輸入優惠券代碼",
   "billing.total_shipment_last 30 days":"最近 30 天內的總出貨量",
   "billing.upgarde_tips":"一旦所有100個跟蹤用完，您將需要升級到付費計劃，",
   "billing.choose_plan":"選擇計劃",
   "billing.essential":"基本",
   "billing.month":"月",
   "billing.orders":"訂單",
   "billing.extra_price":"* 超出訂閱配額的每件額外貨件 0.04 USD",
   "billing.tip_1":"對於跟蹤頁面和電子郵件通知 - 雖然這兩項服務由Parcelecho提供支援，但我們向您保證，我們永遠不會像我們的一些同行那樣要求您的客戶下載任何內容或請求他們的資訊。我們的使命是為您提供更好的售後體驗，而不是在您的客戶中推廣自己。",
   "billing.tip_2":"目前，當您的訂閱計劃中的跟蹤數量用完時，將自動觸發充值。每個充值包包括25個額外的跟蹤，費用為1美元。在這種情況下，如果您在訂閱自動續訂之前繼續使用跟蹤 - 您的跟蹤配額也會續訂 - 您的帳戶可能會添加多個充值包。每次將充值包添加到您的帳戶時，您都會收到一封通知電子郵件。所有已使用充值包的總付款將在您的下一個 Shopify 結算日收取。",
   "dashboard.tab.sync_order":"同步訂單",
   "dashboard.tab.enable_email_notification":"啟用電子郵件通知",
   "dashboard.tab.add_tracking_page":"添加跟蹤頁面",
   "dashboard.content.enable_email_notification.one":"如果您選擇啟用電子郵件通知服務，您的客戶將收到有關其訂單的每個重大狀態更改的更新電子郵件，",
   "dashboard.content.enable_email_notification.two":"您可以轉到",
   "dashboard.content.enable_email_notification.three":"通知",
   "dashboard.content.enable_email_notification.four":"左側",
   "dashboard.content.add_tracking_page.one":"Parcelecho追蹤頁面的默認連結使用您自己的Shopify商店的域。",
   "dashboard.content.add_tracking_page.two":"這對SEO很有説明，可以提高您商店的Alexa排名。請複製下面的連結，並",
   "dashboard.content.add_tracking_page.three":"請遵循我們的分步指南",
   "dashboard.content.add_tracking_page.four":"將跟蹤頁面添加到您的 Shopify 商店。",
   "dashboard.content.add_tracking_page.five":"目前，跟蹤頁面默認應用藍色。您可以轉到左側的「跟蹤頁面」選項卡，然後根據需要調整跟蹤頁面的主題顏色。",
   "dashboard.content.sync_order.one":"感謝您嘗試一下Parcelecho！",
   "dashboard.content.sync_order.two":"現在，Parcelecho已將10個最新履行同步到跟蹤系統中。在下面查看，您可以看到Parcelecho的分析工作原理，以及您計劃的當前使用狀態。要查看同步的履行，請轉到，",
   "dashboard.content.sync_order.shipment":"發貨",
   "dashboard.content.sync_order.three":"左側的選項卡。在那裡，您可以選擇同步更多已發貨訂單，並選擇是否從跟蹤中刪除同步的履行以保存跟蹤配額。如果履行正常，Parcelecho可以在此訂單更新時通過電子郵件通知您的客戶。",
   "dashboard.content.sync_order.four":"轉到電子郵件通知服務上的下一個選項卡，繼續設置以開始使用 Parcelecho。",
   "dashboard.dismiss":"解僱",
   "dashboard.get_started":"立即開始",
   "dashboard.proportion_status.order_by_status":"按狀態分類的訂單",
   "dashboard.intro.vaild_rate":"優惠價",
   "dashboard.intro.vaild_rate_tip":"當掛單數量增加時，有效匯率下降。按兩下該卡可查看所有需要注意的掛單。",
   "dashboard.intro.exceptions":"異常",
   "dashboard.intro.exceptions_tip":"顯示當前異常訂單數。按兩下該卡以查看所有例外訂單。",
   "dashboard.intro.total_exceptions":"異常總數",
   "dashboard.intro.tracking_pv":"跟蹤頁面PV",
   "dashboard.intro.tracking_pv_tip":"過去 7 天內跟蹤網頁的網頁流覽量。",
   "dashboard.intro.today_views":"今天的觀點",
   "dashboard.intro.unfulfilled_orders":"未發貨訂單",
   "dashboard.intro.unfulfilled_orders_tip":"顯示當前未發貨訂單和其中的物料數。按兩下該卡以查看所有未發貨的訂單。",
   "dashboard.intro.total_unfulfilled_order":"未發貨訂單總數",
   "dashboard.intro.unfulfilled_items":"未發貨商品",
   "dashboard.intor.total_pending_count":"待處理總數",
   "dashboard.fulfillment_card.title":"每日履行和訂單",
   "dashboard.fulfillment_card.daily_fulfillment":"每日履行",
   "dashboard.fulfillment_card.date":"日期",
   "dashboard.plan.cacel_plan_tip":"是否確實要取消當前計劃？",
   "dashboard.plan.cacel_text":"不，沒關係，",
   "dashboard.plan.cacel_confirm":"取消我的計劃",
   "dashboard.plan.overview":"計劃概述",
   "dashboard.plan.upgrade":"升級",
   "dashboard.plan.tracking_used":"使用的跟蹤",
   "dashboard.plan.next_invoice":"下一張發票",
   "dashboard.plan.cancel_subscription":"取消訂閱",
   "menu.Dashboard":"擋泥板",
   "menu.Analytics":"分析學",
   "menu.Analytics.Notifications":"通知",
   "menu.Analytics.Tracking Page":"跟蹤頁面",
   "menu.Shipments":"發貨",
   "menu.Notifications":"通知",
   "menu.Tracking Page":"跟蹤頁面",
   "menu.Billing":"計費",
   "menu.Settings":"設置",
   "notifications.title":"電子郵件通知",
   "notifications.in_transit_desc":"當客戶的包裹在前往目的地的途中時，通過電子郵件通知客戶。",
   "notifications.exception_desc":"當承運人發現他們的包裹無法送達或由於包裹已退回給寄件者或丟失/損壞而無法完成遞送時，請通過電子郵件通知您的客戶。",
   "notifications.on_delivery_desc":"當客戶的包裹已送達或準備在當地辦事處或郵政設施取件時，請通過電子郵件通知客戶。",
   "notifications.delivered_desc":"當客戶的包裹成功送達后，通過電子郵件通知客戶。",
   "notifications.view_template":"視圖範本",
   "settings.title":"設置",
   "settings.powered_by_parcelecho":"由 Parcelecho 提供技術支援",
   "settings.powered_by_parcelecho_desc":"在跟蹤頁面上顯示或刪除由 Parcelecho 提供支援",
   "settings.get_started_card":"入門卡",
   "settings.get_started1_card_desc":"在儀錶板上顯示或隱藏入門",
   "shipments.sync_7_days":"同步 7 天發貨",
   "shipments.sync_14_days":"同步 14 天發貨",
   "shipments.sync_30_days":"同步 30 天發貨",
   "shipments.order_no":"訂貨號",
   "shipments.product":"產品",
   "shipments.carrier":"載體",
   "shipments.tracking_number":"追蹤號碼",
   "shipments.ship_date":"發貨日期",
   "shipments.status":"地位",
   "shipments.from":"從",
   "shipments.to":"自",
   "shipments.time_used":"使用時間",
   "shipments.on_track":"步入正軌",
   "shipments.all":"都",
   "shipments.pending":"待定",
   "shipments.exception":"例外",
   "shipments.undelivered":"未交付",
   "shipments.stalled":"停滯",
   "shipments.in_transit":"運輸中",
   "shipments.on_delivery":"交貨時",
   "shipments.delivered":"交付",
   "shipments.by_tracking_no":"通過跟蹤號。",
   "shipments.by_carrier_name":"按運營商名稱",
   "shipments.by_order_no":"訂單號",
   "shipments.search_to_select_carrier":"搜索以選擇運營商",
   "shipments.type_keyword":"鍵入關鍵字...",
   "shipments.search":"搜索",
   "shipments.reset":"重置",
   "shipments.not_set":"未設置",
   "shipments.modify_success":"已成功修改",
   "shipments.modfiy_error":"修改錯誤。",
   "shipments.select_a_shipment":"請至少選擇一個貨件。",
   "shipments.edit_carrier":"編輯運營商",
   "shipments.cancel":"取消",
   "shipments.submit":"提交",
   "shipments.change_to":"更改為",
   "tracking_page.page_theme":"頁面主題",
   "tracking_page.element_color":"元素顏色",
   "tracking_page.languages":"語言",
   "tracking_page.auto_translate":"自動翻譯",
   "tracking_page.custom_html":"自訂 HTML",
   "tracking_page.preview":"預覽",
   "tracking_page.apply":"應用",
   "tracking_page.pick_color":"選擇您想要的主題色以自訂追蹤頁面上的元素（例如按鈕）。點擊保存並重新載入跟蹤頁面以查看更改。",
   "tracking_page.save":"救",
   "tracking_page.send_email":"如果您有訂單跟蹤頁面的模型，並希望將其作為Parcelecho的主題選項之一，請發送電子郵件至：",
   "tracking_page.applied":"應用的",
   "tracking_page.translate.desc1":"默認情況下，跟蹤頁面以英語顯示，並且僅在添加了多種顯示語言時自動提供翻譯。,",
   "tracking_page.translate.desc2":"Parcelecho現在支援11種語言自定義跟蹤頁面上使用的措辭。如果要應用所需的英語以外的顯示語言，請從下拉功能表中選擇該語言，然後在文本框中輸入自定義翻譯。",
   "tracking_page.select_language":"選擇語言",
   "tracking_page.setting_saved":"已保存設置。",
   "tracking_page.send_email_tips":"*如果您想要的語言未包含在清單中，請隨時通過下面的聊天圖示告知我們，或在 support@parcelecho.com 給我們發送電子郵件。",
   "tracking_page.custom_html.desc":"在跟蹤頁面的頂部和底部創建自定義頁眉和頁腳。",
   "tracking_page.custom_html.before_html_label":"在頂部新增自訂 HTML（選擇）",
   "tracking_page.custom_html.after_html_label":"在底部新增自訂 HTML（選擇）",
   "tracking_page.custom_html.css":"自訂 CSS（選擇）",
   "tracking_page.auto_translate.title":"開啟自動翻譯（由微軟翻譯提供支援）",
   "tracking_page.auto_translate.desc":"默認情況下，所有跟蹤資訊都以英語顯示，即使您的跟蹤頁面具有多種主題語言也是如此。如果您希望自動向客戶提供翻譯，可以啟用「自動翻譯」選項。跟蹤資訊將自動翻譯，按照跟蹤頁面的顯示語言，"
}