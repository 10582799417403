export default {
    'dashboard.tab.sync_order': 'Synchronize Orders',
    'dashboard.tab.enable_email_notification': 'Enable Email Notifications',
    'dashboard.tab.add_tracking_page': 'Add Tracking Page',
    'dashboard.content.enable_email_notification.one': 'If you choose to enable the email notification service, your customers will receive an update email on each major status change of their orders',
    'dashboard.content.enable_email_notification.two': ' You could go to the',
    'dashboard.content.enable_email_notification.three': 'Notifications',
    'dashboard.content.enable_email_notification.four': ' tab on the left to further configure whether to enable the email notification of a specific shipment event.',
    'dashboard.content.add_tracking_page.one': 'The default link of Parcelecho tracking page uses the domain of your own Shopify store.',
    'dashboard.content.add_tracking_page.two': 'This is helpful to  SEO and could improve the Alexa ranking of your store. Please copy the link below and ',
    'dashboard.content.add_tracking_page.three': 'follow our step-by-step guide',
    'dashboard.content.add_tracking_page.four': 'on adding the tracking page to your Shopify store.',
    'dashboard.content.add_tracking_page.five': 'Currently, the tracking page applies the color of blue by default. You could go to the Tracking Page tab on the left, and adjust the theme color of the tracking page as you like.',
    'dashboard.content.sync_order.one': ' Thanks for giving Parcelecho a try!',
    'dashboard.content.sync_order.two': 'Now Parcelecho has synchronized 10 of your latest fulfillments into the tracking system. View below, you could see how Parcelecho\'s analytics work, as well as the current usage state of your plan. To view the synchronized fulfillments, go to the',
    'dashboard.content.sync_order.shipment': 'Shipments',
    'dashboard.content.sync_order.three': 'tab on the left. There you could select to synchronize more fulfilled orders, and choose whether to remove a synchronized fulfillment from being tracked to save the tracking quotas. If a fulfillment is on track, Parcelecho can notify your customer with emails when it this order gets updated.',
    'dashboard.content.sync_order.four': 'Go to the next tab on email notification service to continue your set-up for starting using Parcelecho.',
    'dashboard.dismiss': 'Dismiss',
    'dashboard.get_started': 'Get Started',
    'dashboard.proportion_status.order_by_status': 'Orders By Status',
    'dashboard.intro.vaild_rate': 'Valid Rate',
    'dashboard.intro.vaild_rate_tip': 'Valid Rate drops when the number of Pending orders rises. Click the card to view all Pending orders that need attention.',
    'dashboard.intro.exceptions': 'Exceptions',
    'dashboard.intro.exceptions_tip': 'Shows the current number of Exception orders. Click the card to view all Exception orders. ',
    'dashboard.intro.total_exceptions': 'Total Exceptions',
    'dashboard.intro.tracking_pv': 'Tracking Page PV',
    'dashboard.intro.tracking_pv_tip': 'Page views to your tracking page in last 7 days. ',
    'dashboard.intro.today_views': 'Today\'s views:',
    'dashboard.intro.unfulfilled_orders': 'Unfulfilled Orders',
    'dashboard.intro.unfulfilled_orders_tip': 'Shows the current number of unfulfilled orders and items within. Click the card to view all unfulfilled orders. ',
    'dashboard.intro.total_unfulfilled_order': 'Total unfulfilled orders',
    'dashboard.intro.unfulfilled_items': 'Unfulfilled Items:', 
    'dashboard.intor.total_pending_count':'Total Pending Count',
    'dashboard.fulfillment_card.title': 'Fulfillment & Orders By Day',  
    'dashboard.fulfillment_card.daily_fulfillment': 'Daily Fulfillment',  
    'dashboard.fulfillment_card.date': 'Date',  
    'dashboard.plan.cacel_plan_tip': 'Are you sure you want to cancel your current plan?', 
    'dashboard.plan.cacel_text': 'No, never mind',  
    'dashboard.plan.cacel_confirm': 'Cancel my plan',
    'dashboard.plan.overview': 'Plan Overview', 
    'dashboard.plan.upgrade': 'Upgrade', 
    'dashboard.plan.tracking_used': 'Tracking used:', 
    'dashboard.plan.next_invoice': 'Next invoice:', 
    'dashboard.plan.cancel_subscription':'Cancel Subscription',
}