
export default{
   "tracking_page.page_theme":" Pagina Thema",
   "tracking_page.element_color":" Element Kleur",
   "tracking_page.languages":" Talen",
   "tracking_page.auto_translate":" Automatisch vertalen",
   "tracking_page.custom_html":" Aangepaste HTML",
   "tracking_page.preview":" Voorbeeld",
   "tracking_page.apply":" Toepassen",
   "tracking_page.pick_color":" Kies een accentkleur die u leuk vindt om de elementen (bijvoorbeeld de knoppen) op de trackingpagina aan te passen. Klik op Opslaan en laad de trackingpagina opnieuw om de wijziging weer te geven.",
   "tracking_page.save":" Redden",
   "tracking_page.send_email":" Als u een mockup van de pagina voor het volgen van bestellingen heeft en deze een van de thema-opties in Parcelecho wilt maken, stuur dan een e-mail naar ons op,",
   "tracking_page.applied":" Toegepast",
   "tracking_page.translate.desc1":" Standaard wordt de trackingpagina in het Engels weergegeven en biedt alleen automatisch vertalingen wanneer er meer dan één weergavetaal is toegevoegd. ,",
   "tracking_page.translate.desc2":" Parcelecho ondersteunt nu 11 talen die de gebruikte bewoordingen op de trackingpagina aanpassen. Als u de gewenste weergavetaal anders dan Engels wilt toepassen, selecteert u de taal in het vervolgkeuzemenu en voert u uw aangepaste vertaling in het tekstvak in.",
   "tracking_page.select_language":" Taal selecteren",
   "tracking_page.setting_saved":" Instellingen opgeslagen.",
   "tracking_page.send_email_tips":"* Als de gewenste taal niet in de lijst is opgenomen, aarzel dan niet om ons dit te laten weten via het onderstaande chatpictogram of stuur ons een e-mail op support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Maak een aangepaste kop- en voettekst boven en onder aan uw trackingpagina.",
   "tracking_page.custom_html.before_html_label":" Voeg aangepaste HTML toe aan de bovenkant (optioneel)",
   "tracking_page.custom_html.after_html_label":" Voeg aangepaste HTML onderaan toe (optioneel)",
   "tracking_page.custom_html.css":" Aangepaste CSS (optioneel)",
   "tracking_page.auto_translate.title":" Auto Translate inschakelen (mogelijk gemaakt door Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Standaard wordt alle trackinginformatie in het Engels weergegeven, zelfs als uw trackingpagina meerdere thematalen heeft. Als u vertalingen automatisch aan uw klanten wilt aanbieden, kunt u de optie Automatisch vertalen inschakelen. Trackinginformatie wordt automatisch vertaald, in overeenstemming met de weergavetaal van de trackingpagina,",
   "tracking_page.porduct_recommendation":" Productaanbeveling",
   "tracking_page.not_show_product_recommendation":" Geef Porduct Recommendation niet weer op de trackingpagina.",
   "tracking_page.collections_show_on_tracking_page":" Collecties worden weergegeven op de trakcing-pagina",
   "tracking_page.select_a_collection":" Selecteer een collectie"
}