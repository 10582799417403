
export default{
   "tracking_page.page_theme":" Tema da página",
   "tracking_page.element_color":" Cor do elemento",
   "tracking_page.languages":" Idiomas",
   "tracking_page.auto_translate":" Tradução automática",
   "tracking_page.custom_html":" HTML personalizado",
   "tracking_page.preview":" Visualizar",
   "tracking_page.apply":" Aplicar",
   "tracking_page.pick_color":" Escolha uma cor de acento que você gosta de personalizar os elementos (por exemplo, os botões) na página de rastreamento. Clique em Salvar e recarregue a página de rastreamento para visualizar a alteração.",
   "tracking_page.save":" Salvar",
   "tracking_page.send_email":" Se você tem uma página de rastreamento de pedidos e gostaria de torná-la uma das opções temáticas no Parcelecho, envie um e-mail para nós em:",
   "tracking_page.applied":" Aplicado",
   "tracking_page.translate.desc1":" Por padrão, a página de rastreamento aparece em inglês e oferece traduções automaticamente somente quando mais de um idioma de exibição foi adicionado. ,",
   "tracking_page.translate.desc2":" O Parcelecho agora suporta 11 idiomas que personalizam a redação usada na página Tracking. Se você quiser aplicar o idioma de exibição desejado que não seja o inglês, selecione o idioma no menu suspenso e digite sua tradução personalizada na caixa de texto.,",
   "tracking_page.select_language":" Selecionar idioma",
   "tracking_page.setting_saved":" Configurações salvas.",
   "tracking_page.send_email_tips":"*Se o idioma que você deseja não estiver incluído na lista, não hesite em nos informar através do ícone de bate-papo abaixo ou nos deixe enviar um e-mail em support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Crie cabeçalho e rodapé personalizados na parte superior e inferior da sua página de rastreamento.",
   "tracking_page.custom_html.before_html_label":" Adicionar HTML personalizado na parte superior (opcional)",
   "tracking_page.custom_html.after_html_label":" Adicionar HTML personalizado na parte inferior (opcional)",
   "tracking_page.custom_html.css":" CSS personalizado (opcional)",
   "tracking_page.auto_translate.title":" Habilitar tradução automática (alimentado pelo Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Por padrão, todas as informações de rastreamento são exibidas em inglês mesmo quando sua página de rastreamento tem vários idiomas temáticos. Se você quiser oferecer traduções automaticamente para seus clientes, você pode ativar a opção Traduzir automaticamente. As informações de rastreamento serão traduzidas automaticamente, de acordo com o idioma de exibição da página de rastreamento,",
   "tracking_page.porduct_recommendation":" Recomendação do produto",
   "tracking_page.not_show_product_recommendation":" Não mostre recomendação porduto na página de rastreamento.",
   "tracking_page.collections_show_on_tracking_page":" Coleções mostram na página trakcing",
   "tracking_page.select_a_collection":" Selecione uma coleção"
}