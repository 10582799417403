
import build from './zh-CN/build';
import capped_amount from './zh-CN/capped_amount';
import email_translation from './zh-CN/email_translation';
import tracking_page from './zh-CN/tracking_page';

export default {
  ...build,
  ...capped_amount,
  ...email_translation,
  ...tracking_page
};
