export default {
    'tracking_page.page_theme': 'Page Theme',
    'tracking_page.element_color': 'Element Color',
    'tracking_page.languages': 'Languages',
    'tracking_page.auto_translate': 'Auto Translate',
    'tracking_page.custom_html': 'Custom HTML',
    'tracking_page.preview': 'Preview',
    'tracking_page.apply': 'Apply',
    'tracking_page.pick_color': 'Pick an accent color you like to customize the elements (e.g. the buttons) on the tracking page. Click "Save" and reload the tracking page to view the change.',
    'tracking_page.save': 'Save',
    'tracking_page.send_email': 'If you have a mockup of order tracking page and would like to make it one of theme options in Parcelecho, please send an email to us at',
    'tracking_page.applied': 'Applied',
    'tracking_page.translate.desc1':'By default, the tracking page appears in English, and offers translations automatically only when more than one display language was added. ',
    'tracking_page.translate.desc2': 'Parcelecho now supports 11 languages customizing the wording used on Tracking page. If you\'d like to apply your desired display language other than English, select the language from the drop-down menu and enter your customized translation in the text box.',
    'tracking_page.select_language': 'Select Language',
    'tracking_page.setting_saved': 'Settings saved.',
    'tracking_page.send_email_tips': '*If the language you want is not included in the list, don\'t hesitate to let us know via the chat icon below or drop us an email at support@parcelecho.com.',
    'tracking_page.custom_html.desc': 'Create custom header and footer on the top and bottom of your tracking page.',
    'tracking_page.custom_html.before_html_label': 'Add custom HTML at the top (optional)',
  'tracking_page.custom_html.after_html_label':'Add custom HTML at the bottom(optional)',
    'tracking_page.custom_html.css': ' Custom CSS (optional)',
 'tracking_page.auto_translate.title':'Enable Auto Translate (powered by Microsoft Translator)',
  'tracking_page.auto_translate.desc': 'By default, all tracking information appears in English even when your tracking page has multiple theme languages. If you\'d like to offer translations automatically to your customers, you can enable the Auto Translate option. Tracking information will be automatically translated, in accordance with the display language of tracking page ',
  'tracking_page.porduct_recommendation': 'Product Recommendation',
  'tracking_page.not_show_product_recommendation': 'Not show Porduct Recommendation on tracking page.',
  'tracking_page.collections_show_on_tracking_page': 'Collections show on trakcing page:',
  'tracking_page.select_a_collection':'Select a collection',
}