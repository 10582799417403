
export default{
   "analytic.tracking_page_pv":"ページビューのトラッキング",
   "analytic.last_7_days":"過去7日間",
   "analytic.last_30_days":"過去30日間",
   "analytic.last_90_days":"過去90日間",
   "analytic.average_pv":"1日の平均ページビュー数",
   "analytic.total_pv":"合計ページビュー数",
   "analytic.tracking_pv_by_day":"日別ページビューの追跡",
   "analytic.pv":"ページビュー",
   "analytic.date":"日付",
   "analytic.email_sent_by_day":"1 日の平均送信メール数",
   "analytic.total_emails_sent":"送信されたメールの合計数",
   "analytic.emails_sent_by_day":"日単位で送信されたメール",
   "analytic.email_sent_count":"メール送信数",
   "billing.plan_feature_1":"800以上のキャリアへのアクセス",
   "billing.plan_feature_2":"ブランドトラッキングページ",
   "billing.plan_feature_3":"電子メール通知",
   "billing.plan_feature_4":"ダッシュボードと分析",
   "billing.plan_feature_5":"迅速なサポート",
   "billing.plan_feature_6":"専用サポート",
   "billing.redeem_coupon":"クーポンの引き換え",
   "billing.please_enter_coupon_code":"クーポンコードを入力してください",
   "billing.total_shipment_last 30 days":"過去30日間の出荷合計",
   "billing.upgarde_tips":"100の追跡がすべて使い果たされたら、有料プランにアップグレードする必要があります。",
   "billing.choose_plan":"プランを選択",
   "billing.essential":"肝要",
   "billing.month":"月",
   "billing.orders":"注文",
   "billing.extra_price":"* $0.04 (サブスクリプションクォータを超える追加出荷あたり)",
   "billing.tip_1":"追跡ページと電子メール通知用 - これら2つのサービスはParcelechoによって供給されていますが、一部の対応サービスのように、顧客に何かをダウンロードしたり、情報を要求したりすることは決してありません。私たちの使命は、より良い販売後の体験を提供することであり、顧客の間で自分自身を宣伝することではありません。",
   "billing.tip_2":"現在、サブスクリプションプランの追跡数が使い果たされると、トップアップが自動的にトリガーされます。各トップアップパックには25の追加追跡が含まれており、費用は$ 1です。この場合、サブスクリプションの自動更新 (追跡クォータも更新される) の前に追跡を消費し続けると、アカウントに複数のトップアップ パックを追加することができます。トップアップパックがアカウントに追加されるたびに通知メールが届きます。使用されたすべてのトップアップパックの合計支払い額は、次のShopify請求日に請求されます。",
   "notifications.title":"電子メール通知",
   "notifications.in_transit_desc":"荷物が目的地に向かう途中にメールでお客様に通知します。",
   "notifications.exception_desc":"配送業者が荷物が配達不能であることが判明した場合、または荷物が送信者に返送されたか、紛失/破損したために配達を完了できなかった場合に、電子メールで顧客に通知します。",
   "notifications.on_delivery_desc":"荷物が配達のために手渡されたとき、または地元のオフィスや郵便施設で集荷の準備が整ったときに、電子メールで顧客に通知します。",
   "notifications.delivered_desc":"荷物が正常に配達されたら、メールでお客様に通知します。",
   "notifications.view_template":"テンプレートの表示",
   "settings.title":"設定",
   "settings.powered_by_parcelecho":"によって供給 パーセレコ",
   "settings.powered_by_parcelecho_desc":"追跡ページでパーセレコによって供給された表示または削除",
   "settings.get_started_card":"はじめにカード",
   "settings.get_started1_card_desc":"ダッシュボードで開始する表示または非表示にする",
   "dashboard.tab.sync_order":"注文の同期",
   "dashboard.tab.enable_email_notification":"電子メール通知を有効にする",
   "dashboard.tab.add_tracking_page":"トラッキングページの追加",
   "dashboard.content.enable_email_notification.one":"メール通知サービスを有効にすることを選択した場合、顧客は注文のステータスが大きく変わるたびに更新メールを受信します。",
   "dashboard.content.enable_email_notification.two":"あなたは行くことができます",
   "dashboard.content.enable_email_notification.three":"通知",
   "dashboard.content.enable_email_notification.four":"左側の",
   "dashboard.content.add_tracking_page.one":"Parcelechoトラッキングページのデフォルトリンクは、独自のShopifyストアのドメインを使用します。",
   "dashboard.content.add_tracking_page.two":"これはSEOに役立ち、ストアのAlexaランキングを向上させることができます。以下のリンクをコピーし、",
   "dashboard.content.add_tracking_page.three":"ステップバイステップガイドに従ってください",
   "dashboard.content.add_tracking_page.four":"Shopifyストアにトラッキングページを追加する際に。",
   "dashboard.content.add_tracking_page.five":"現在、追跡ページではデフォルトで青の色が適用されます。左側の[トラッキングページ]タブに移動し、トラッキングページのテーマの色を好きなように調整できます。",
   "dashboard.content.sync_order.one":"パーセレコを試してみてくれてありがとう!",
   "dashboard.content.sync_order.two":"これで、Parcelechoは最新のフルフィルメントの10件を追跡システムに同期しました。以下で見ると、Parcelecho\\s 分析がどのように機能するか、およびプランの現在の使用状況を確認できます。同期されたフルフィルメントを表示するには、",
   "dashboard.content.sync_order.shipment":"出荷",
   "dashboard.content.sync_order.three":"左側の",
   "dashboard.content.sync_order.four":"メール通知サービスの次のタブに移動して、Parcelechoの使用を開始するためのセットアップを続行します。",
   "dashboard.dismiss":"解任する",
   "dashboard.get_started":"はじめに",
   "dashboard.proportion_status.order_by_status":"ステータス別の注文",
   "dashboard.intro.vaild_rate":"有効レート",
   "dashboard.intro.vaild_rate_tip":"有効なレートは、未決注文の数が増加すると低下します。カードをクリックすると、注意が必要なすべての未決注文が表示されます。",
   "dashboard.intro.exceptions":"例外",
   "dashboard.intro.exceptions_tip":"例外注文の現在の数が表示されます。カードをクリックすると、すべての例外注文が表示されます。",
   "dashboard.intro.total_exceptions":"例外の合計数",
   "dashboard.intro.tracking_pv":"トラッキングページPV",
   "dashboard.intro.tracking_pv_tip":"過去 7 日間のトラッキング ページへのページ ビュー。",
   "dashboard.intro.today_views":"今日の\\sビュー",
   "dashboard.intro.unfulfilled_orders":"未発送の注文",
   "dashboard.intro.unfulfilled_orders_tip":"未発送の注文とアイテムの現在の数が表示されます。カードをクリックすると、未発送の注文がすべて表示されます。",
   "dashboard.intro.total_unfulfilled_order":"未発送注文の合計数",
   "dashboard.intro.unfulfilled_items":"未発送アイテム",
   "dashboard.intor.total_pending_count":"保留中の合計数",
   "dashboard.fulfillment_card.title":"日ごとのフルフィルメントと注文",
   "dashboard.fulfillment_card.daily_fulfillment":"デイリーフルフィルメント",
   "dashboard.fulfillment_card.date":"日付",
   "dashboard.plan.cacel_plan_tip":"現在のプランをキャンセルしてもよろしいですか?",
   "dashboard.plan.cacel_text":"気にしないで、",
   "dashboard.plan.cacel_confirm":"プランをキャンセルする",
   "dashboard.plan.overview":"計画の概要",
   "dashboard.plan.upgrade":"アップグレード",
   "dashboard.plan.tracking_used":"使用される追跡",
   "dashboard.plan.next_invoice":"次の請求書",
   "dashboard.plan.cancel_subscription":"サブスクリプションのキャンセル",
   "menu.Dashboard":"ダッシュボード",
   "menu.Analytics":"解析学",
   "menu.Analytics.Notifications":"通知",
   "menu.Analytics.Tracking Page":"トラッキングページ",
   "menu.Shipments":"出荷",
   "menu.Notifications":"通知",
   "menu.Tracking Page":"トラッキングページ",
   "menu.Billing":"請求",
   "menu.Settings":"設定",
   "shipments.sync_7_days":"フルフィルメントを7日間同期",
   "shipments.sync_14_days":"フルフィルメントを同期する 14 日間",
   "shipments.sync_30_days":"フルフィルメントを30日間同期",
   "shipments.order_no":"注文番号",
   "shipments.product":"積",
   "shipments.carrier":"キャリア",
   "shipments.tracking_number":"トラッキング番号",
   "shipments.ship_date":"出荷日",
   "shipments.status":"地位",
   "shipments.from":"差出人",
   "shipments.to":"宛先",
   "shipments.time_used":"使用時間",
   "shipments.on_track":"順調に進んでいる",
   "shipments.all":"すべての",
   "shipments.pending":"ペンディング",
   "shipments.exception":"例外",
   "shipments.undelivered":"未配信",
   "shipments.stalled":"停止",
   "shipments.in_transit":"トランジット中",
   "shipments.on_delivery":"配達時",
   "shipments.delivered":"配信",
   "shipments.by_tracking_no":"追跡番号",
   "shipments.by_carrier_name":"キャリア名別",
   "shipments.by_order_no":"注文番号",
   "shipments.search_to_select_carrier":"検索してキャリアを選択",
   "shipments.type_keyword":"キーワードを入力...",
   "shipments.search":"捜索",
   "shipments.reset":"リセット",
   "shipments.not_set":"未設定",
   "shipments.modify_success":" は正常に変更されました",
   "shipments.modfiy_error":"エラーを変更します。",
   "shipments.select_a_shipment":"少なくとも貨物を選択してください。",
   "shipments.edit_carrier":"キャリアの編集",
   "shipments.cancel":"キャンセル",
   "shipments.submit":"送信",
   "shipments.change_to":"への変更",
   "tracking_page.page_theme":"ページのテーマ",
   "tracking_page.element_color":"要素の色",
   "tracking_page.languages":"言語",
   "tracking_page.auto_translate":"自動翻訳",
   "tracking_page.custom_html":"カスタム HTML",
   "tracking_page.preview":"プレビュー",
   "tracking_page.apply":"申し込む",
   "tracking_page.pick_color":"トラッキングページの要素(ボタンなど)をカスタマイズするアクセントカラーを選択します。[保存] をクリックし、追跡ページを再読み込みして変更を表示します。",
   "tracking_page.save":"セーブ",
   "tracking_page.send_email":"注文追跡ページのモックアップをお持ちで、Parcelechoのテーマオプションの1つにしたい場合は、",
   "tracking_page.applied":"応用",
   "tracking_page.translate.desc1":"デフォルトでは、追跡ページは英語で表示され、複数の表示言語が追加された場合にのみ自動的に翻訳が提供されます。,",
   "tracking_page.translate.desc2":"Parcelecho は、追跡ページで使用される文言をカスタマイズする 11 の言語をサポートするようになりました。英語以外の表示言語を適用する場合は、ドロップダウンメニューから言語を選択し、カスタマイズされた翻訳をテキストボックスに入力します。",
   "tracking_page.select_language":"言語の選択",
   "tracking_page.setting_saved":"設定が保存されました。",
   "tracking_page.send_email_tips":"*あなたが欲しい言語がリストに含まれていないならば、下のチャットアイコンを通して私達に知らせるか私達に電子メールをドロップすることを躊躇しないでください support@parcelecho.com..",
   "tracking_page.custom_html.desc":"トラッキングページの上部と下部にカスタムヘッダーとフッターを作成します。",
   "tracking_page.custom_html.before_html_label":"カスタム HTML を先頭に追加する (オプション)",
   "tracking_page.custom_html.after_html_label":"下部にカスタムHTMLを追加する(オプション)",
   "tracking_page.custom_html.css":"カスタム CSS (オプション)",
   "tracking_page.auto_translate.title":"自動翻訳を有効にする (マイクロソフト翻訳によって供給)",
   "tracking_page.auto_translate.desc":"デフォルトでは、トラッキングページに複数のテーマ言語がある場合でも、すべてのトラッキング情報は英語で表示されます。顧客に翻訳を自動的に提供したい場合は、[自動翻訳] オプションを有効にすることができます。追跡情報は、追跡ページの表示言語に従って自動的に翻訳され、"
}