export default {
   
    'billing.plan_feature_1': 'Access to 800+ carriers',
    'billing.plan_feature_2': 'Branded tracking page',
    'billing.plan_feature_3': 'Email notification',
    'billing.plan_feature_4': 'Dashboard & Analytics',
    'billing.plan_feature_5': 'Fast support',
    'billing.plan_feature_6': 'Dedicated support',
    'billing.redeem_coupon': 'Redeem Coupon',
    'billing.please_enter_coupon_code': 'Please Enter Coupon Code',
    'billing.total_shipment_last 30 days': 'Total shipments in last 30 days',
    'billing.upgarde_tips':'Once all 100 trackings are used up, you will need to upgrade to a paid plan',
    'billing.choose_plan': 'Choose Plan',
    'billing.essential': 'ESSENTIAL',
    'billing.month': 'Month',
    'billing.orders': 'orders',
    'billing.extra_price': '* $0.04 for per extra shipment beyond subscription quotas',
    'billing.tip_1': 'For tracking page and Email notifications - Though these two services are powered by Parcelecho, we ensure you that we\'ll never ask your customers to download anything or request their information, as some of our counterparts do. Our mission is to provide you with a better post-sale experience, not promote ourselves among your customers.',
    'billing.tip_2':'Currently, when the number of trackings in your subscription plan is used up, top-up will be automatically triggered. Each top-up pack includes 25 extra trackings and costs $1. In this case, if you keep consuming trackings before the subscription\'s automatic renewal – with which your tracking quotas are renewed as well – your account could be added with more than one top-up pack. You\'ll receive a notification email each time when a top-up pack is added to your account. The total payment of all top-up packs used will be charged on your next Shopify billing day.',
}