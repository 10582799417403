import build from "./it-IT/build";
import capped_amount from "./it-IT/capped_amount";
import email_translation from "./it-IT/email_translation";
import tracking_page from "./it-IT/tracking_page";

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
}