
export default{
   "analytic.tracking_page_pv":"订单查询页 PV",
   "analytic.last_7_days":"最近 7 天",
   "analytic.last_30_days":"最近 30 天",
   "analytic.last_90_days":"最近 90 天",
   "analytic.average_pv":"平均每日浏览量",
   "analytic.total_pv":"总浏览量",
   "analytic.tracking_pv_by_day":"每日订单查询页 PV",
   "analytic.pv":"PV",
   "analytic.date":"日期",
   "analytic.email_sent_by_day":"平均每日发送的电子邮件",
   "analytic.total_emails_sent":"发送的电子邮件总数",
   "analytic.emails_sent_by_day":"每天发送的电子邮件",
   "analytic.email_sent_count":"电子邮件发送数量",
   "menu.Dashboard":"仪表盘",
   "menu.Analytics":"分析",
   "menu.Analytics.Notifications":"通知",
   "menu.Analytics.Tracking Page":"订单查询页",
   "menu.Shipments":"发货",
   "menu.Notifications":"通知",
   "menu.Tracking Page":"订单查询页",
   "menu.Billing":"计费",
   "menu.Settings":"设置",
   "billing.plan_feature_1":"支持800多个运营商",
   "billing.plan_feature_2":"品牌订单查询页面",
   "billing.plan_feature_3":"电子邮件通知",
   "billing.plan_feature_4":"仪表板和分析",
   "billing.plan_feature_5":"快速支持",
   "billing.plan_feature_6":"专业支持",
   "billing.redeem_coupon":"兑换优惠券",
   "billing.please_enter_coupon_code":"请输入优惠券代码",
   "billing.total_shipment_last 30 days":"最近 30 天内的总出货量",
   "billing.upgarde_tips":"一旦所有100个跟踪用完，您将需要升级到付费计划，",
   "billing.choose_plan":"选择计划",
   "billing.essential":"基本",
   "billing.month":"月",
   "billing.orders":"订单",
   "billing.extra_price":"* 超出订阅配额的每件额外货件 0.04 USD",
   "billing.tip_1":"对于跟踪页面和电子邮件通知 - 虽然这两项服务由Parcelecho提供支持，但我们向您保证，我们永远不会像我们的一些同行那样要求您的客户下载任何内容或请求他们的信息。我们的使命是为您提供更好的售后体验，而不是在您的客户中推广自己。",
   "billing.tip_2":"目前，当您的订阅计划中的跟踪数量用完时，将自动触发充值。每个充值包包括25个额外的跟踪，费用为1美元。在这种情况下，如果您在订阅自动续订之前继续使用跟踪 - 您的跟踪配额也会续订 - 您的帐户可能会添加多个充值包。每次将充值包添加到您的帐户时，您都会收到一封通知电子邮件。所有已使用充值包的总付款将在您的下一个 Shopify 结算日收取。",
   "notifications.title":"电子邮件通知",
   "notifications.in_transit_desc":"当客户的包裹在前往目的地的途中时，通过电子邮件通知客户。",
   "notifications.exception_desc":"当承运人发现他们的包裹无法送达或由于包裹已退回给发件人或丢失/损坏而无法完成递送时，通过电子邮件通知您的客户。",
   "notifications.on_delivery_desc":"当客户的包裹已送达或准备在当地办事处或邮政设施取件时，通过电子邮件通知客户。",
   "notifications.delivered_desc":"当客户的包裹成功送达后，通过电子邮件通知客户。",
   "notifications.view_template":"查看邮件模板",
   "dashboard.tab.sync_order":"同步订单",
   "dashboard.tab.enable_email_notification":"启用电子邮件通知",
   "dashboard.tab.add_tracking_page":"添加订单查询页",
   "dashboard.content.enable_email_notification.one":"如果您选择启用电子邮件通知服务，您的客户将收到有关其订单的每个重大状态更改的更新电子邮件，",
   "dashboard.content.enable_email_notification.two":"您可以转到",
   "dashboard.content.enable_email_notification.three":"通知",
   "dashboard.content.enable_email_notification.four":"左侧",
   "dashboard.content.add_tracking_page.one":"Parcelecho 订单查询页的默认链接使用您自己的 Shopify 商店域名。",
   "dashboard.content.add_tracking_page.two":"这对SEO很有帮助，可以提高您商店的Alexa排名。请复制下面的链接，并",
   "dashboard.content.add_tracking_page.three":"请按照我们的说明操作",
   "dashboard.content.add_tracking_page.four":"将订单查询页添加到您的 Shopify 商店。",
   "dashboard.content.add_tracking_page.five":"目前，订单查询页默认应用黑色。您可以转到左侧的“订单查询页”选项卡，然后根据需要调整跟踪页面的主题颜色。",
   "dashboard.content.sync_order.one":"感谢您尝试一下Parcelecho！",
   "dashboard.content.sync_order.two":"Parcelecho 会默认同步最新的10个订单。你可以点击 “分析” 查看套餐的使用情况。如要查看已同步的订单，请转到，",
   "dashboard.content.sync_order.shipment":"发货",
   "dashboard.content.sync_order.three":"左侧的选项卡。在那里，您可以选择同步更多已发货订单，或者决定是否继续跟踪。如果发货正常，Parcelecho可以在此订单更新时通过电子邮件通知您的客户。",
   "dashboard.content.sync_order.four":"转到电子邮件通知服务上的下一个选项卡，继续设置以开始使用 Parcelecho。",
   "dashboard.dismiss":"隐藏",
   "dashboard.get_started":"新手引导",
   "dashboard.proportion_status.order_by_status":"按状态分类的订单",
   "dashboard.intro.vaild_rate":"包裹有效率",
   "dashboard.intro.vaild_rate_tip":"当 Pending 状态添加，可能为物流商未更新或者选错了物流商导致的",
   "dashboard.intro.exceptions":"异常",
   "dashboard.intro.exceptions_tip":"显示当前异常订单数。单击该卡以查看所有异常订单。",
   "dashboard.intro.total_exceptions":"异常总数",
   "dashboard.intro.tracking_pv":"订单查询页 PV",
   "dashboard.intro.tracking_pv_tip":"过去 7 天内订单查询页的浏览量",
   "dashboard.intro.today_views":"今日浏览量",
   "dashboard.intro.unfulfilled_orders":"未发货订单",
   "dashboard.intro.unfulfilled_orders_tip":"显示当前未发货订单和未发货的商品数。单击该卡以查看所有未发货的订单。",
   "dashboard.intro.total_unfulfilled_order":"未发货订单总数",
   "dashboard.intro.unfulfilled_items":"未发货商品",
   "dashboard.intor.total_pending_count":"待处理总数",
   "dashboard.fulfillment_card.title":"每日发货和新增订单",
   "dashboard.fulfillment_card.daily_fulfillment":"每日发货",
   "dashboard.fulfillment_card.date":"日期",
   "dashboard.plan.cacel_plan_tip":"是否确实要取消当前计划？",
   "dashboard.plan.cacel_text":"不，没关系，",
   "dashboard.plan.cacel_confirm":"取消我的计划",
   "dashboard.plan.overview":"计划概述",
   "dashboard.plan.upgrade":"升级",
   "dashboard.plan.tracking_used":"已使用数量",
   "dashboard.plan.next_invoice":"计费日期",
   "dashboard.plan.cancel_subscription":"取消订阅",
   "shipments.sync_7_days":"同步 7 天订单",
   "shipments.sync_14_days":"同步 14 天订单",
   "shipments.sync_30_days":"同步 30 天订单",
   "shipments.order_no":"订单号",
   "shipments.product":"产品",
   "shipments.carrier":"物流商",
   "shipments.tracking_number":"快递单号",
   "shipments.ship_date":"发货日期",
   "shipments.status":"状态",
   "shipments.from":"从",
   "shipments.to":"到",
   "shipments.time_used":"花费时间",
   "shipments.on_track":"是否追踪",
   "shipments.all":"所有",
   "shipments.pending":"待定",
   "shipments.exception":"异常",
   "shipments.undelivered":"未交付",
   "shipments.stalled":"停滞",
   "shipments.in_transit":"运输中",
   "shipments.on_delivery":"正在派送",
   "shipments.delivered":"已签收",
   "shipments.by_tracking_no":"通过跟踪号",
   "shipments.by_carrier_name":"按物流商名称",
   "shipments.by_order_no":"订单号",
   "shipments.search_to_select_carrier":"搜索以选择物流商",
   "shipments.type_keyword":"键入关键字...",
   "shipments.search":"搜索",
   "shipments.reset":"重置",
   "shipments.not_set":"未设置",
   "shipments.modify_success":"已成功修改",
   "shipments.modfiy_error":"修改错误。",
   "shipments.select_a_shipment":"请至少选择一个货件。",
   "shipments.edit_carrier":"编辑物流商",
   "shipments.cancel":"取消",
   "shipments.submit":"提交",
   "shipments.change_to":"更改为",
   "settings.title":"设置",
   "settings.powered_by_parcelecho":"由 Parcelecho 提供技术支持",
   "settings.powered_by_parcelecho_desc":"在订单查询页面上显示或删除由 Parcelecho 提供支持",
   "settings.get_started_card":"新手引导",
   "settings.get_started1_card_desc":"在仪表板上显示或隐藏新手引导",
   "tracking_page.page_theme":"页面主题",
   "tracking_page.element_color":"元素颜色",
   "tracking_page.languages":"语言",
   "tracking_page.auto_translate":"自动翻译",
   "tracking_page.custom_html":"自定义 HTML",
   "tracking_page.preview":"预览",
   "tracking_page.apply":"应用",
   "tracking_page.pick_color":"选择您想要的主题色以自定义跟踪页面上的元素（例如按钮）。点击保存并重新加载订单查询页面以查看更改。",
   "tracking_page.save":"保存",
   "tracking_page.send_email":"如果您想自定义订单查询页，并希望将其作为Parcelecho的主题选项之一，请发送电子邮件至：",
   "tracking_page.applied":"正在使用",
   "tracking_page.translate.desc1":"默认情况下，订单查询页的记录以英语显示，并且仅在添加了多种主题语言时自动提供翻译。,",
   "tracking_page.translate.desc2":"Parcelecho现在支持11种语言自定义跟踪页面上使用的措辞。如果要应用所需的英语以外的显示语言，请从下拉菜单中选择该语言，然后在文本框中输入自定义翻译。",
   "tracking_page.select_language":"选择语言",
   "tracking_page.setting_saved":"已保存设置。",
   "tracking_page.send_email_tips":"*如果您想要的语言未包含在列表中，请点击右边下角的聊天图标告知我们，或在 support@parcelecho.com 给我们发送电子邮件。我们更尽快提供支持",
   "tracking_page.custom_html.desc":"在订单查询页的顶部和底部创建自定义内容。",
   "tracking_page.custom_html.before_html_label":"在顶部添加自定义 HTML（可选）",
   "tracking_page.custom_html.after_html_label":"在底部添加自定义 HTML（可选）",
   "tracking_page.custom_html.css":"自定义 CSS（可选）",
   "tracking_page.auto_translate.title":"启用自动翻译（由微软翻译提供支持）",
   "tracking_page.auto_translate.desc":"默认情况下，所有物流跟踪信息都以英语显示，即使您的跟踪页面具有多种主题语言也是如此。如果您希望自动向客户提供翻译，可以启用“自动翻译”选项。跟踪信息将自动翻译，按照跟踪页面的显示语言，"
}