
export default{
   "analytic.tracking_page_pv":" ردیابی Pageviews صفحه",
   "analytic.last_7_days":" 7 روز گذشته",
   "analytic.last_30_days":" 30 روز گذشته",
   "analytic.last_90_days":" 90 روز گذشته",
   "analytic.average_pv":" میانگین Pageviews روزانه",
   "analytic.total_pv":" مجموع Pageviews",
   "analytic.tracking_pv_by_day":" ردیابی Pageviews صفحه در روز",
   "analytic.pv":" Pageviews",
   "analytic.date":" تاریخ",
   "analytic.email_sent_by_day":" متوسط ایمیل های روزانه ارسال شده",
   "analytic.total_emails_sent":" مجموع ایمیل های ارسال شده",
   "analytic.emails_sent_by_day":" ایمیل های ارسال شده توسط روز",
   "analytic.email_sent_count":" ایمیل ارسال تعداد",
   "menu.Dashboard":" داشبورد",
   "menu.Analytics":" آنالیز",
   "menu.Analytics.Notifications":" اطلاعیه ها",
   "menu.Analytics.Tracking Page":" صفحه ردیابی",
   "menu.Shipments":" محموله",
   "menu.Notifications":" اطلاعیه ها",
   "menu.Tracking Page":" صفحه ردیابی",
   "menu.Billing":" صدور صورت حساب",
   "menu.Settings":" تنظیمات",
   "notifications.title":" اعلان های ایمیل",
   "notifications.in_transit_desc":" زمانی که بسته آنها در راه مقصد است، با ایمیل به مشتری خود اطلاع دهید.",
   "notifications.exception_desc":" اطلاع مشتری خود را با یک ایمیل هنگامی که حامل در بر داشت بسته خود را غیر قابل تحویل است و یا فقط نمی تواند تحویل کامل به دلیل بسته به فرستنده بازگشته است و یا آن را از دست داده است / آسیب دیده است.",
   "notifications.on_delivery_desc":" هنگامی که بسته آنها برای تحویل یا آماده برای وانت در دفتر محلی یا تسهیلات پستی تحویل داده شده است، با ایمیل به مشتری خود اطلاع دهید.",
   "notifications.delivered_desc":" زمانی که بسته آنها با موفقیت تحویل داده شده است، با ایمیل به مشتری خود اطلاع دهید.",
   "notifications.view_template":" مشاهده الگو",
   "settings.title":" تنظیمات",
   "settings.powered_by_parcelecho":" Powered By Parcelecho",
   "settings.powered_by_parcelecho_desc":" نمایش یا حذف شده توسط Parcelecho در صفحه ردیابی",
   "settings.get_started_card":" دریافت کارت آغاز شده",
   "settings.get_started1_card_desc":" نمایش یا مخفی کردن شروع در داشبورد",
   "dashboard.tab.sync_order":" هماهنگ کردن دستورات",
   "dashboard.tab.enable_email_notification":" فعال کردن اعلان های ایمیل",
   "dashboard.tab.add_tracking_page":" افزودن صفحه ردیابی",
   "dashboard.content.enable_email_notification.one":" اگر شما انتخاب می کنید برای فعال کردن خدمات اطلاع رسانی ایمیل مشتریان خود را یک ایمیل به روز رسانی در هر تغییر وضعیت عمده ای از سفارشات خود را دریافت خواهید کرد،",
   "dashboard.content.enable_email_notification.two":" تو ميتوني بري به",
   "dashboard.content.enable_email_notification.three":" اطلاعیه ها",
   "dashboard.content.enable_email_notification.four":" سمت چپ برای پیکربندی بیشتر که آیا برای فعال کردن اطلاع رسانی ایمیل از یک رویداد حمل و نقل خاص است.",
   "dashboard.content.add_tracking_page.one":" لینک پیش فرض صفحه ردیابی Parcelecho از دامنه فروشگاه Shopify خود استفاده می کند.",
   "dashboard.content.add_tracking_page.two":" این به جستجوگرها مفید است و می تواند رتبه الکسا از فروشگاه خود را بهبود بخشد. لطفا لینک زیر را کپی کنید و",
   "dashboard.content.add_tracking_page.three":" راهنمای گام به گام ما را دنبال کنید",
   "dashboard.content.add_tracking_page.four":" اضافه کردن صفحه ردیابی به فروشگاه Shopify خود را.",
   "dashboard.content.add_tracking_page.five":" در حال حاضر صفحه ردیابی رنگ آبی را به طور پیش فرض اعمال می کند. شما می توانید به تب صفحه ردیابی در سمت چپ بروید، و تنظیم رنگ تم از صفحه ردیابی به عنوان دوست دارید.،",
   "dashboard.content.sync_order.one":" با تشکر برای دادن Parcelecho سعی کنید!",
   "dashboard.content.sync_order.two":" در حال حاضر Parcelecho تا به همگام 10 از آخرین تحقق خود را به سیستم ردیابی. مشاهده زیر شما می توانید ببینید که چگونه تجزیه و تحلیل Parcelecho کار می کنند، و همچنین وضعیت استفاده فعلی از طرح خود را. برای مشاهده برآورده سازی های همگام شده، به سر می بریم،",
   "dashboard.content.sync_order.shipment":" محموله",
   "dashboard.content.sync_order.three":" سمت چپ. در آنجا می توانید برای همگام سازی دستورات برآورده شده تر انتخاب کنید و انتخاب کنید که آیا برای حذف یک تحقق همگام از ردیابی شدن برای صرفه جویی در سهمیه های ردیابی. اگر تحقق در مسیر است، Parcelecho می تواند مشتری خود را با ایمیل اطلاع زمانی که آن را این سفارش به روز می شود.,",
   "dashboard.content.sync_order.four":" به برگه بعدی در سرویس اطلاع رسانی ایمیل بروید تا راه اندازی خود را برای شروع استفاده از Parcelecho ادامه دهد.",
   "dashboard.dismiss":" اخراج",
   "dashboard.get_started":" شروع",
   "dashboard.proportion_status.order_by_status":" سفارشات بر اساس وضعیت",
   "dashboard.intro.vaild_rate":" نرخ معتبر",
   "dashboard.intro.vaild_rate_tip":" نرخ معتبر زمانی کاهش می یابد که تعداد دستورات در انتظار بالا می رود. با کلیک بر روی کارت برای مشاهده تمام سفارشات در انتظار که نیاز به توجه.",
   "dashboard.intro.exceptions":" استثنا",
   "dashboard.intro.exceptions_tip":" تعداد فعلی دستورات استثنا را نشان می دهد. برای مشاهده همه دستورات استثنا روی کارت کلیک کنید.",
   "dashboard.intro.total_exceptions":" استثناهای کل",
   "dashboard.intro.tracking_pv":" ردیابی صفحه PV",
   "dashboard.intro.tracking_pv_tip":" نمایش صفحه به صفحه ردیابی خود را در 7 روز گذشته.",
   "dashboard.intro.today_views":" دیدگاه های امروز",
   "dashboard.intro.unfulfilled_orders":" دستورات انجام نشده",
   "dashboard.intro.unfulfilled_orders_tip":" تعداد فعلی دستورات و موارد انجام نشده را در داخل نشان می دهد. برای مشاهده تمام دستورات انجام نشده روی کارت کلیک کنید.",
   "dashboard.intro.total_unfulfilled_order":" مجموع دستورات انجام نشده",
   "dashboard.intro.unfulfilled_items":" موارد انجام نشده",
   "dashboard.intor.total_pending_count":" تعداد کل در انتظار",
   "dashboard.fulfillment_card.title":" Fulfillment & Orders By Day",
   "dashboard.fulfillment_card.daily_fulfillment":" تحقق روزانه",
   "dashboard.fulfillment_card.date":" تاریخ",
   "dashboard.plan.cacel_plan_tip":" آیا مطمئن هستید که می خواهید طرح فعلی خود را لغو کنید؟",
   "dashboard.plan.cacel_text":" مهم نيست،",
   "dashboard.plan.cacel_confirm":" نقشه ام رو کنسل کن",
   "dashboard.plan.overview":" بررسی اجمالی طرح",
   "dashboard.plan.upgrade":" ارتقاء",
   "dashboard.plan.tracking_used":" ردیابی استفاده می شود",
   "dashboard.plan.next_invoice":" فاکتور بعدی",
   "dashboard.plan.cancel_subscription":" لغو اشتراک",
   "billing.plan_feature_1":" دسترسی به حامل های 800+",
   "billing.plan_feature_2":" صفحه ردیابی مارک دار",
   "billing.plan_feature_3":" اعلان ایمیل",
   "billing.plan_feature_4":" Dashboard & Analytics",
   "billing.plan_feature_5":" پشتیبانی سریع",
   "billing.plan_feature_6":" پشتیبانی اختصاصی",
   "billing.redeem_coupon":" ردیم کوپن",
   "billing.please_enter_coupon_code":" لطفا کد کوپن را وارد کنید",
   "billing.total_shipment_last 30 days":" مجموع محموله ها در 30 روز گذشته",
   "billing.upgarde_tips":" هنگامی که تمام 100 ردیابی استفاده می شود تا شما نیاز به ارتقاء به یک طرح پرداخت می شود،",
   "billing.choose_plan":" طرح را انتخاب کنید",
   "billing.essential":" ضروری",
   "billing.month":" ماه",
   "billing.orders":" ها",
   "billing.extra_price":" $ 0.04 برای هر محموله اضافی فراتر از سهمیه اشتراک",
   "billing.tip_1":" برای ردیابی صفحه و اعلان ایمیل -- هر چند این دو خدمات توسط Parcelecho ما به شما اطمینان حاصل شود که ما هرگز از مشتریان خود را به دانلود هر چیزی و یا درخواست اطلاعات خود را ، به عنوان برخی از همتایان ما انجام دهد. ماموریت ما این است که شما را با یک تجربه بهتر پس از فروش ارائه, خودمان را در میان مشتریان خود را ترویج نیست.,",
   "billing.tip_2":" در حال حاضر هنگامی که تعداد ردیابی ها در طرح اشتراک شما استفاده می شود، بالا به بالا به طور خودکار آغاز خواهد شد. هر بسته بالا به بالا شامل 25 ردیابی اضافی و هزینه های $1. در این مورد، اگر شما نگه داشتن ردیابی مصرف قبل از اشتراک \\s تجدید خودکار - که با آن سهمیه های ردیابی خود را نیز تجدید - حساب خود را می تواند با بیش از یک بسته بالا به بالا اضافه شده است. هر بار که یک بسته بالا به بالا به حساب شما اضافه می شود، ایمیل اعلانی دریافت می کنید. پرداخت کل تمام بسته های بالا به بالا استفاده خواهد شد در روز بعدی خود را Shopify صورتحساب به اتهام.,",
   "shipments.sync_7_days":" همگام سازی 7 روز تحقق",
   "shipments.sync_14_days":" همگام سازی 14 روز تحقق",
   "shipments.sync_30_days":" همگام سازی 30 روز تحقق",
   "shipments.order_no":" سفارش شماره.",
   "shipments.product":" محصول",
   "shipments.carrier":" حامل",
   "shipments.tracking_number":" ردیابی نه.",
   "shipments.ship_date":" تاریخ کشتی",
   "shipments.status":" وضعیت",
   "shipments.from":" از",
   "shipments.to":" به",
   "shipments.time_used":" زمان مورد استفاده",
   "shipments.on_track":" در مسیر",
   "shipments.all":" همه",
   "shipments.pending":" انتظار",
   "shipments.exception":" استثنا",
   "shipments.undelivered":" تحویل داده نشده",
   "shipments.stalled":" متوقف",
   "shipments.in_transit":" در ترانزیت",
   "shipments.on_delivery":" در تحویل",
   "shipments.delivered":" تحویل داده",
   "shipments.by_tracking_no":" با ردیابی شماره.",
   "shipments.by_carrier_name":" بر اساس نام حامل",
   "shipments.by_order_no":" به دستور شماره.",
   "shipments.search_to_select_carrier":" جستجو برای انتخاب حامل",
   "shipments.type_keyword":" نوع کلمه کلیدی...",
   "shipments.search":" جستجو",
   "shipments.reset":" تنظیم مجدد",
   "shipments.not_set":" تنظیم نشده",
   "shipments.modify_success":" با موفقیت اصلاح شد",
   "shipments.modfiy_error":" تغییر خطا.",
   "shipments.select_a_shipment":" لطفا حداقل یک محموله را انتخاب کنید.",
   "shipments.edit_carrier":" ویرایش حامل",
   "shipments.cancel":" لغو",
   "shipments.submit":" ارسال",
   "shipments.change_to":" تغییر به",
   "tracking_page.page_theme":" تم صفحه",
   "tracking_page.element_color":" رنگ عنصر",
   "tracking_page.languages":" زبان",
   "tracking_page.auto_translate":" ترجمه خودکار",
   "tracking_page.custom_html":" اچ ام تی سفارشی",
   "tracking_page.preview":" پیشنمایش",
   "tracking_page.apply":" درخواست",
   "tracking_page.pick_color":" انتخاب رنگ لهجه شما می خواهم برای سفارشی کردن عناصر (به عنوان مانند دکمه ها) در صفحه ردیابی. برای مشاهده تغییر روی ذخیره و بارگذاری مجدد صفحه ردیابی کلیک کنید.",
   "tracking_page.save":" ذخیره",
   "tracking_page.send_email":" اگر شما یک تمسخر از سفارش ردیابی صفحه و می خواهم آن را به یکی از گزینه های تم در Parcelecho لطفا ارسال یک ایمیل به ما در،",
   "tracking_page.applied":" کاربردی",
   "tracking_page.translate.desc1":" به طور پیش فرض صفحه ردیابی به زبان انگلیسی ظاهر می شود، و ترجمه ها را به طور خودکار تنها زمانی ارائه می دهد که بیش از یک زبان نمایش اضافه شد. ,",
   "tracking_page.translate.desc2":" Parcelecho در حال حاضر پشتیبانی از 11 زبان سفارشی جمله بندی مورد استفاده در صفحه ردیابی. اگر می خواهید زبان نمایش مورد نظر خود را غیر از زبان انگلیسی اعمال کنید، زبان را از منوی کشویی انتخاب کرده و ترجمه سفارشی خود را در جعبه متن وارد کنید.,",
   "tracking_page.select_language":" انتخاب زبان",
   "tracking_page.setting_saved":" تنظیمات ذخیره شده.",
   "tracking_page.send_email_tips":"*If the language you want is not included in the list don\\t hesit to let us know via the chat icon below or drop us an email at support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" ایجاد هدر سفارشی و پاورقی در بالا و پایین صفحه ردیابی خود را.",
   "tracking_page.custom_html.before_html_label":" افزودن HTML سفارشی در بالا (اختیاری)",
   "tracking_page.custom_html.after_html_label":" اضافه کردن HTML سفارشی در پایین(اختیاری)",
   "tracking_page.custom_html.css":" CSS سفارشی (اختیاری)",
   "tracking_page.auto_translate.title":" فعال کردن ترجمه خودکار (شده توسط مترجم مایکروسافت)",
   "tracking_page.auto_translate.desc":" به طور پیش فرض تمام اطلاعات ردیابی به زبان انگلیسی ظاهر می شود حتی زمانی که صفحه ردیابی شما دارای چندین زبان تم است. اگر می خواهید ترجمه ها را به صورت خودکار به مشتریان خود ارائه کنید، می توانید گزینه Auto Translate را فعال کنید. اطلاعات ردیابی به طور خودکار ترجمه خواهد شد، مطابق با زبان نمایش صفحه ردیابی ،"
}