import build from './pt-BR/build';
import capped_amount from './pt-BR/capped_amount';
import email_translation from './pt-BR/email_translation';
import tracking_page from './pt-BR/tracking_page';

export default {

    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
};
