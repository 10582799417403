
export default{
   "tracking_page.page_theme":" Tema de la página",
   "tracking_page.element_color":" Color del elemento",
   "tracking_page.languages":" Idiomas",
   "tracking_page.auto_translate":" Traducción automática",
   "tracking_page.custom_html":" HTML personalizado",
   "tracking_page.preview":" Vista previa",
   "tracking_page.apply":" Aplicar",
   "tracking_page.pick_color":" Elija un color de acento que le guste para personalizar los elementos (por ejemplo, los botones) en la página de seguimiento. Haga clic en Guardar y vuelva a cargar la página de seguimiento para ver el cambio.",
   "tracking_page.save":" Salvar",
   "tracking_page.send_email":" Si tiene una maqueta de la página de seguimiento de pedidos y desea convertirla en una de las opciones de tema en Parcelecho, envíenos un correo electrónico a,",
   "tracking_page.applied":" Aplicado",
   "tracking_page.translate.desc1":" De forma predeterminada, la página de seguimiento aparece en inglés y ofrece traducciones automáticamente solo cuando se ha agregado más de un idioma de visualización. ,",
   "tracking_page.translate.desc2":" Parcelecho ahora admite 11 idiomas para personalizar la redacción utilizada en la página de seguimiento. Si desea aplicar el idioma de visualización que desee distinto del inglés, seleccione el idioma en el menú desplegable e introduzca la traducción personalizada en el cuadro de texto.",
   "tracking_page.select_language":" Seleccionar idioma",
   "tracking_page.setting_saved":" Configuración guardada.",
   "tracking_page.send_email_tips":"*Si el idioma que desea no está incluido en la lista, no dude en hacérnoslo saber a través del icono de chat a continuación o envíenos un correo electrónico a support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Cree un encabezado y pie de página personalizados en la parte superior e inferior de su página de seguimiento.",
   "tracking_page.custom_html.before_html_label":" Agregar HTML personalizado en la parte superior (opcional)",
   "tracking_page.custom_html.after_html_label":" Agregar HTML personalizado en la parte inferior (opcional)",
   "tracking_page.custom_html.css":" CSS personalizado (opcional)",
   "tracking_page.auto_translate.title":" Habilitar la traducción automática (con tecnología de Microsoft Translator)",
   "tracking_page.auto_translate.desc":" De forma predeterminada, toda la información de seguimiento aparece en inglés, incluso cuando su página de seguimiento tiene varios idiomas de tema. Si desea ofrecer traducciones automáticamente a sus clientes, puede habilitar la opción Traducción automática. La información de seguimiento se traducirá automáticamente, de acuerdo con el idioma de visualización de la página de seguimiento,",
   "tracking_page.porduct_recommendation":" Recomendación de producto",
   "tracking_page.not_show_product_recommendation":" No mostrar la recomendación de porducto en la página de seguimiento.",
   "tracking_page.collections_show_on_tracking_page":" Las colecciones se muestran en la página de trakcing",
   "tracking_page.select_a_collection":" Seleccionar una colección"
}