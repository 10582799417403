
export default{
   "analytic.tracking_page_pv":" Sporing af sidevisninger",
   "analytic.last_7_days":" Sidste 7 dage",
   "analytic.last_30_days":" Sidste 30 dage",
   "analytic.last_90_days":" Sidste 90 dage",
   "analytic.average_pv":" Gennemsnitlige daglige sidevisninger",
   "analytic.total_pv":" Sidevisninger i alt",
   "analytic.tracking_pv_by_day":" Sporing af sidevisninger efter dag",
   "analytic.pv":" Sidevisninger",
   "analytic.date":" Dato",
   "analytic.email_sent_by_day":" Gennemsnitlige daglige e-mails sendt",
   "analytic.total_emails_sent":" Sendte e-mails i alt",
   "analytic.emails_sent_by_day":" E-mails sendt om dagen",
   "analytic.email_sent_count":" Antal sendte mails",
   "billing.plan_feature_1":" Adgang til mere end 800 transportører",
   "billing.plan_feature_2":" Side med sporing af brandet",
   "billing.plan_feature_3":" E-mail-meddelelse",
   "billing.plan_feature_4":" Dashboard og analyse",
   "billing.plan_feature_5":" Hurtig support",
   "billing.plan_feature_6":" Dedikeret support",
   "billing.redeem_coupon":" Indløs kupon",
   "billing.please_enter_coupon_code":" Indtast venligst kuponkode",
   "billing.total_shipment_last 30 days":" Forsendelser i alt inden for de seneste 30 dage",
   "billing.upgarde_tips":" Når alle 100 sporinger er opbrugte, skal du opgradere til en betalt plan,",
   "billing.choose_plan":" Vælg abonnement",
   "billing.essential":" ESSENTIEL",
   "billing.month":" Måned",
   "billing.orders":"bestillinger",
   "billing.extra_price":"* $0,04 for pr. ekstra forsendelse ud over abonnementskvoter",
   "billing.tip_1":" Til sporing af side- og e-mail-meddelelser - Selvom disse to tjenester drives af Parcelecho, sikrer vi dig, at vi aldrig vil bede dine kunder om at downloade noget eller anmode om deres oplysninger, som nogle af vores kolleger gør. Vores mission er at give dig en bedre oplevelse efter salg, ikke at promovere os selv blandt dine kunder.,",
   "billing.tip_2":" Når antallet af sporinger i din abonnementsplan er opbrugte, udløses oppåding automatisk. Hver pånningspakke indeholder 25 ekstra sporinger og koster $ 1. I dette tilfælde, hvis du fortsætter med at forbruge sporinger før abonnementets automatiske fornyelse - som dine sporingskvoter også fornyes med - kan din konto tilføjes med mere end en pådømningspakke. Du modtager en notifikationsmail, hver gang der føjes en på op-pakke til din konto. Den samlede betaling af alle anvendte påpån-pakker debiteres på din næste Shopify-faktureringsdag.",
   "dashboard.tab.sync_order":" Synkroniser ordrer",
   "dashboard.tab.enable_email_notification":" Aktivér e-mail-underretninger",
   "dashboard.tab.add_tracking_page":" Tilføj sporingsside",
   "dashboard.content.enable_email_notification.one":" Hvis du vælger at aktivere e-mail-meddelelsestjenesten, modtager dine kunder en opdaterings-e-mail om hver større statusændring af deres ordrer,",
   "dashboard.content.enable_email_notification.two":" Du kan gå til",
   "dashboard.content.enable_email_notification.three":" Meddelelser",
   "dashboard.content.enable_email_notification.four":" til venstre for yderligere at konfigurere, om mailmeddelelsen om en bestemt forsendelseshændelse skal aktiveres.",
   "dashboard.content.add_tracking_page.one":" Standardlinket på Parcelecho-sporingssiden bruger domænet for din egen Shopify butik.",
   "dashboard.content.add_tracking_page.two":" Dette er nyttigt for SEO og kan forbedre Alexa-placeringen af din butik. Kopier venligst nedenstående link og",
   "dashboard.content.add_tracking_page.three":"følg vores trinvise vejledning",
   "dashboard.content.add_tracking_page.four":"på at føje sporingssiden til din Shopify-butik.",
   "dashboard.content.add_tracking_page.five":" I øjeblikket anvender sporingssiden som standard farven blå. Du kan gå til fanen Sporingsside til venstre og justere temafarven på sporingssiden, som du vil.,",
   "dashboard.content.sync_order.one":" Tak for at give Parcelecho et forsøg!",
   "dashboard.content.sync_order.two":" Nu har Parcelecho synkroniseret 10 af dine seneste opfyldelser til sporingssystemet. Nedenfor kan du se, hvordan Parcelecho\\s analyser fungerer, samt den aktuelle brugstilstand for din plan. Hvis du vil have vist de synkroniserede klargøringer, skal du gå til,",
   "dashboard.content.sync_order.shipment":" Forsendelser",
   "dashboard.content.sync_order.three":"tab til venstre. Der kan du vælge at synkronisere flere klargjorte ordrer og vælge, om du vil fjerne en synkroniseret klargøring fra at blive sporet for at gemme sporingskvoterne. Hvis en klargøring er på rette spor, kan Parcelecho underrette din kunde med e-mails, når denne ordre opdateres.",
   "dashboard.content.sync_order.four":" Gå til næste fane på e-mail-meddelelsestjenesten for at fortsætte din opsætning til at begynde at bruge Parcelecho.",
   "dashboard.dismiss":" Afskedige",
   "dashboard.get_started":" Sådan kommer du i gang",
   "dashboard.proportion_status.order_by_status":" Ordrer efter status",
   "dashboard.intro.vaild_rate":" Gyldig sats",
   "dashboard.intro.vaild_rate_tip":" Gyldig sats falder, når antallet af afventende ordrer stiger. Klik på kortet for at se alle afventende ordrer, der kræver opmærksomhed.",
   "dashboard.intro.exceptions":" Undtagelser",
   "dashboard.intro.exceptions_tip":" Viser det aktuelle antal undtagelsesordrer. Klik på kortet for at se alle undtagelsesordrer.",
   "dashboard.intro.total_exceptions":" Undtagelser i alt",
   "dashboard.intro.tracking_pv":" Sporing af side PV",
   "dashboard.intro.tracking_pv_tip":" Sidevisninger til din sporingsside inden for de seneste 7 dage.",
   "dashboard.intro.today_views":" Dagens synspunkter",
   "dashboard.intro.unfulfilled_orders":" Uopfyldte ordrer",
   "dashboard.intro.unfulfilled_orders_tip":" Viser det aktuelle antal ikke-klargjorte ordrer og varer indenfor. Klik på kortet for at se alle ikke-klargjorte ordrer.",
   "dashboard.intro.total_unfulfilled_order":" Samlede uopfyldte ordrer",
   "dashboard.intro.unfulfilled_items":" Uopfyldte varer",
   "dashboard.intor.total_pending_count":" Samlet afventende optælling",
   "dashboard.fulfillment_card.title":" Opfyldelse og ordrer om dagen",
   "dashboard.fulfillment_card.daily_fulfillment":" Daglig opfyldelse",
   "dashboard.fulfillment_card.date":" Dato",
   "dashboard.plan.cacel_plan_tip":" Er du sikker på, at du vil annullere din nuværende plan?",
   "dashboard.plan.cacel_text":" Nej pyt,",
   "dashboard.plan.cacel_confirm":" Annuller mit abonnement",
   "dashboard.plan.overview":" Oversigt over planer",
   "dashboard.plan.upgrade":" Opgradere",
   "dashboard.plan.tracking_used":" Anvendt sporing",
   "dashboard.plan.next_invoice":" Næste faktura",
   "dashboard.plan.cancel_subscription":" Annuller abonnement",
   "menu.Dashboard":" Instrumentbræt",
   "menu.Analytics":" Analytics",
   "menu.Analytics.Notifications":" Meddelelser",
   "menu.Analytics.Tracking Page":" Sporingsside",
   "menu.Shipments":" Forsendelser",
   "menu.Notifications":" Meddelelser",
   "menu.Tracking Page":" Sporingsside",
   "menu.Billing":" Fakturering",
   "menu.Settings":" Indstillinger",
   "notifications.title":" E-mail-meddelelser",
   "notifications.in_transit_desc":" Giv din kunde besked med en e-mail, når deres pakke er på vej til destinationen.",
   "notifications.exception_desc":" Giv din kunde besked med en e-mail, når transportøren fandt ud af, at deres pakke ikke kan leveres eller bare ikke kan fuldføre leveringen, fordi pakken er returneret til afsenderen, eller den er gået tabt / beskadiget.",
   "notifications.on_delivery_desc":" Giv din kunde besked med en e-mail, når deres pakke er afleveret til levering eller klar til afhentning på det lokale kontor eller postanlæg.",
   "notifications.delivered_desc":" Giv din kunde besked med en e-mail, når deres pakke er blevet leveret.",
   "notifications.view_template":" Se skabelon",
   "settings.title":" Indstillinger",
   "settings.powered_by_parcelecho":" Drevet af Parcelecho",
   "settings.powered_by_parcelecho_desc":" Vis eller fjern Powered By Parcelecho på sporingssiden",
   "settings.get_started_card":" Kom godt i gang Kort",
   "settings.get_started1_card_desc":" Vis eller skjul kom godt i gang på kontrolpanelet",
   "shipments.sync_7_days":" Synkroniser 7 dages opfyldelse",
   "shipments.sync_14_days":" Synkroniser 14 dages klargøring",
   "shipments.sync_30_days":" Synkroniser 30 dages opfyldelse",
   "shipments.order_no":" Bestil nr.",
   "shipments.product":" Produkt",
   "shipments.carrier":" Transportør",
   "shipments.tracking_number":" Sporing nr.",
   "shipments.ship_date":" Afsendelsesdato",
   "shipments.status":" Status",
   "shipments.from":" Fra",
   "shipments.to":" Til",
   "shipments.time_used":" Brugt tid",
   "shipments.on_track":" På sporet",
   "shipments.all":" Al",
   "shipments.pending":" Ventende",
   "shipments.exception":" Undtagelse",
   "shipments.undelivered":" Leverede",
   "shipments.stalled":" Standset",
   "shipments.in_transit":" I transit",
   "shipments.on_delivery":" Ved levering",
   "shipments.delivered":" Leveret",
   "shipments.by_tracking_no":" Ved at spore nr.",
   "shipments.by_carrier_name":" Efter transportørens navn",
   "shipments.by_order_no":" Ved ordre nr.",
   "shipments.search_to_select_carrier":" Søg for at vælge operatør",
   "shipments.type_keyword":" Skriv søgeord...",
   "shipments.search":" Søge",
   "shipments.reset":" Nulstille",
   "shipments.not_set":" Ikke indstillet",
   "shipments.modify_success":"blebled ændret med succes",
   "shipments.modfiy_error":" Rediger fejl.",
   "shipments.select_a_shipment":" Vælg i det mindste en forsendelse.",
   "shipments.edit_carrier":" Rediger transportør",
   "shipments.cancel":" Aflyse",
   "shipments.submit":" Indsende",
   "shipments.change_to":" Skift til",
   "tracking_page.page_theme":" Side Tema",
   "tracking_page.element_color":" Element farve",
   "tracking_page.languages":" Sprog",
   "tracking_page.auto_translate":" Automatisk oversættelse",
   "tracking_page.custom_html":" Tilpasset HTML",
   "tracking_page.preview":" Preview",
   "tracking_page.apply":" Anvende",
   "tracking_page.pick_color":" Vælg en accentfarve, du kan lide at tilpasse elementerne (f.eks. Knapperne) på sporingssiden. Klik på Gem, og genindlæs sporingssiden for at se ændringen.",
   "tracking_page.save":" Spare",
   "tracking_page.send_email":" Hvis du har en mockup af ordresporingsside og gerne vil gøre det til en af temamulighederne i Parcelecho, bedes du sende en e-mail til os på,",
   "tracking_page.applied":" Anvendes",
   "tracking_page.translate.desc1":" Som standard vises sporingssiden på engelsk og tilbyder kun oversættelser automatisk, når der er tilføjet mere end ét visningssprog. ,",
   "tracking_page.translate.desc2":" Parcelecho understøtter nu 11 sprog, der tilpasser den ordlyd, der bruges på sporingssiden. Hvis du vil anvende det ønskede visningssprog ud over engelsk, skal du vælge sproget i rullemenuen og indtaste din tilpassede oversættelse i tekstfeltet.",
   "tracking_page.select_language":" Vælg sprog",
   "tracking_page.setting_saved":" Indstillinger gemt.",
   "tracking_page.send_email_tips":" * Hvis det ønskede sprog ikke er inkluderet på listen, tøv ikke med at fortælle os det via chatikonet nedenfor eller send os en e-mail på support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Opret brugerdefineret sidehoved og sidefod øverst og nederst på din sporingsside.",
   "tracking_page.custom_html.before_html_label":" Tilføj brugerdefineret HTML øverst (valgfrit)",
   "tracking_page.custom_html.after_html_label":" Tilføj brugerdefineret HTML nederst (valgfrit)",
   "tracking_page.custom_html.css":" Brugerdefineret CSS (valgfrit)",
   "tracking_page.auto_translate.title":" Aktivér Automatisk oversættelse (drevet af Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Som standard vises alle sporingsoplysninger på engelsk, selv når din sporingsside har flere temasprog. Hvis du vil tilbyde oversættelser automatisk til dine kunder, kan du aktivere indstillingen Automatisk oversættelse. Sporingsoplysninger oversættes automatisk i overensstemmelse med visningssproget på sporingssiden ,"
}