import build from "./es-ES/build";
import capped_amount from "./es-ES/capped_amount";
import email_translation from "./es-ES/email_translation";
import tracking_page from "./es-ES/tracking_page";

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
}