import build from "./he-IL/build";
import capped_amount from "./he-IL/capped_amount";
import email_translation from "./he-IL/email_translation";
import tracking_page from "./he-IL/tracking_page";

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
}