import tracking_page from "./nl-NL/tracking_page";
import build from "./nl-NL/build";
import capped_amount from "./nl-NL/capped_amount";
import email_translation from "./nl-NL/email_translation";

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
}