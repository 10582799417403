
export default{
   "analytic.tracking_page_pv":" Visualização de pageviews de página",
   "analytic.last_7_days":" Últimos 7 dias",
   "analytic.last_30_days":" Últimos 30 dias.",
   "analytic.last_90_days":" Últimos 90 dias",
   "analytic.average_pv":" Médias diárias de pageviews",
   "analytic.total_pv":" Total Pageviews",
   "analytic.tracking_pv_by_day":" Rastreando pageviews por dia",
   "analytic.pv":" Pageviews",
   "analytic.date":" Data",
   "analytic.email_sent_by_day":" E-mails diários médios enviados",
   "analytic.total_emails_sent":" Total de e-mails enviados",
   "analytic.emails_sent_by_day":" E-mails enviados por dia",
   "analytic.email_sent_count":" Contagem de e-mails enviados",
   "billing.plan_feature_1":" Acesso a mais de 800 operadoras",
   "billing.plan_feature_2":" Página de rastreamento de marca",
   "billing.plan_feature_3":" Notificação por e-mail",
   "billing.plan_feature_4":" Dashboard & Analytics",
   "billing.plan_feature_5":" Suporte rápido",
   "billing.plan_feature_6":" Suporte dedicado",
   "billing.redeem_coupon":" Resgatar cupom",
   "billing.please_enter_coupon_code":" Digite o código do cupom",
   "billing.total_shipment_last 30 days":" Embarques totais nos últimos 30 dias",
   "billing.upgarde_tips":" Uma vez que todos os 100 rastreamentos são usados, você precisará atualizar para um plano pago,",
   "billing.choose_plan":" Escolha o Plano",
   "billing.essential":" ESSENCIAL",
   "billing.month":" Mês",
   "billing.extra_price":"* $0,04 por remessa extra além das cotas de assinatura",
   "billing.tip_1":" Para rastrear as notificações de página e e-mail - Embora esses dois serviços sejam alimentados pela Parcelecho, garantimos que nunca pediremos aos seus clientes que baixem nada ou solicitem suas informações, como alguns de nossos colegas fazem. Nossa missão é fornecer-lhe uma melhor experiência pós-venda, não nos promover entre seus clientes.,",
   "billing.tip_2":" Atualmente, quando o número de rastreamentos em seu plano de assinatura é usado, o reabasqueamento será automaticamente acionado. Cada pacote de recarga inclui 25 rastreamentos extras e custa US$ 1. Neste caso, se você continuar consumindo rastreamentos antes da renovação automática da assinatura – com a qual suas cotas de rastreamento também são renovadas – sua conta poderá ser adicionada com mais de um pacote de recarga. Você receberá um e-mail de notificação cada vez que um pacote de recarga for adicionado à sua conta. O pagamento total de todos os pacotes de recarga usados será cobrado no próximo dia de faturamento do Shopify.,",
   "dashboard.tab.sync_order":" Sincronizar ordens",
   "dashboard.tab.enable_email_notification":" Habilite notificações por e-mail",
   "dashboard.tab.add_tracking_page":" Adicionar página de rastreamento",
   "dashboard.content.enable_email_notification.one":" Se você optar por ativar o serviço de notificação por e-mail, seus clientes receberão um e-mail de atualização sobre cada mudança de status principal de seus pedidos,",
   "dashboard.content.enable_email_notification.two":" Você poderia ir para o",
   "dashboard.content.enable_email_notification.three":" Notificações",
   "dashboard.content.enable_email_notification.four":"tab à esquerda para configurar ainda mais se habilitar a notificação por e-mail de um evento de remessa específico.",
   "dashboard.content.add_tracking_page.one":" O link padrão da página de rastreamento Parcelecho usa o domínio de sua própria loja Shopify.",
   "dashboard.content.add_tracking_page.two":" Isso é útil para o SEO e pode melhorar o ranking alexa da sua loja. Por favor, copie o link abaixo e",
   "dashboard.content.add_tracking_page.three":"se nosso guia passo a passo",
   "dashboard.content.add_tracking_page.four":" adicionando a página de rastreamento à sua loja Shopify.",
   "dashboard.content.add_tracking_page.five":" Atualmente, a página de rastreamento aplica a cor do azul por padrão. Você pode ir para a guia Página de rastreamento à esquerda e ajustar a cor tema da página de rastreamento como quiser.,",
   "dashboard.content.sync_order.one":" Obrigado por dar uma chance ao Parcelecho!",
   "dashboard.content.sync_order.two":" Agora, a Parcelecho sincronizou 10 de suas últimas realizações no sistema de rastreamento. Veja abaixo como funciona a análise do Parcelecho\\s, bem como o estado de uso atual do seu plano. Para ver os cumprimentos sincronizados, vá para o,",
   "dashboard.content.sync_order.shipment":" Transferências",
   "dashboard.content.sync_order.three":"tab à esquerda. Lá você pode selecionar para sincronizar ordens mais cumpridas e escolher se deve remover um cumprimento sincronizado de ser rastreado para salvar as cotas de rastreamento. Se um cumprimento estiver no caminho certo, o Parcelecho pode notificar seu cliente com e-mails quando este pedido for atualizado.,",
   "dashboard.content.sync_order.four":" Vá para a próxima guia no serviço de notificação por e-mail para continuar sua configuração para começar a usar o Parcelecho.",
   "dashboard.dismiss":" Demitir",
   "dashboard.get_started":" Começar",
   "dashboard.proportion_status.order_by_status":" Ordens por status",
   "dashboard.intro.vaild_rate":" Taxa válida",
   "dashboard.intro.vaild_rate_tip":" A Taxa Válida cai quando o número de pedidos pendentes aumenta. Clique no cartão para visualizar todas as ordens pendentes que precisam de atenção.",
   "dashboard.intro.exceptions":" Exceções",
   "dashboard.intro.exceptions_tip":" Mostra o número atual de ordens de exceção. Clique no cartão para exibir todas as ordens de exceção.",
   "dashboard.intro.total_exceptions":" Exceções Totais",
   "dashboard.intro.tracking_pv":" Página de rastreamento PV",
   "dashboard.intro.tracking_pv_tip":" Visualizações de página para sua página de rastreamento nos últimos 7 dias.",
   "dashboard.intro.today_views":" Vistas de hoje",
   "dashboard.intro.unfulfilled_orders":" Ordens não cumpridas",
   "dashboard.intro.unfulfilled_orders_tip":" Mostra o número atual de pedidos e itens não cumpridos dentro. Clique no cartão para visualizar todas as ordens não cumpridas.",
   "dashboard.intro.total_unfulfilled_order":" Total de ordens não cumpridas",
   "dashboard.intro.unfulfilled_items":" Itens não realizados",
   "dashboard.intor.total_pending_count":" Contagem Total pendente",
   "dashboard.fulfillment_card.title":" Cumprimento e Pedidos por dia",
   "dashboard.fulfillment_card.daily_fulfillment":" Cumprimento Diário",
   "dashboard.fulfillment_card.date":" Data",
   "dashboard.plan.cacel_plan_tip":" Tem certeza que quer cancelar seu plano atual?",
   "dashboard.plan.cacel_text":" Não se preocupe,",
   "dashboard.plan.cacel_confirm":" Cancele meu plano.",
   "dashboard.plan.overview":" Visão geral do plano",
   "dashboard.plan.upgrade":" Melhoramento",
   "dashboard.plan.tracking_used":" Rastreamento usado",
   "dashboard.plan.next_invoice":" Próxima fatura",
   "dashboard.plan.cancel_subscription":" Cancelar a assinatura",
   "menu.Dashboard":" Painel",
   "menu.Analytics":" Analytics",
   "menu.Analytics.Notifications":" Notificações",
   "menu.Analytics.Tracking Page":" Página de rastreamento",
   "menu.Shipments":" Transferências",
   "menu.Notifications":" Notificações",
   "menu.Tracking Page":" Página de rastreamento",
   "menu.Billing":" Faturamento",
   "menu.Settings":" Configurações",
   "notifications.title":" Notificações por e-mail",
   "notifications.in_transit_desc":" Notifique seu cliente com um e-mail quando seu pacote estiver a caminho do destino.",
   "notifications.exception_desc":" Notifique seu cliente com um e-mail quando a operadora descobriu que seu pacote não pode ser entregue ou simplesmente não pode completar a entrega porque o pacote foi devolvido ao remetente ou ele está perdido/danificado.",
   "notifications.on_delivery_desc":" Notifique seu cliente com um e-mail quando seu pacote for entregue ou pronto para retirada no escritório local ou instalação postal.",
   "notifications.delivered_desc":" Notifique seu cliente com um e-mail quando seu pacote tiver sido entregue com sucesso.",
   "notifications.view_template":" Exibir modelo",
   "settings.title":" Configurações",
   "settings.powered_by_parcelecho":" Alimentado por Parcelecho",
   "settings.powered_by_parcelecho_desc":" Mostrar ou Remover alimentado por Parcelecho na página de rastreamento",
   "settings.get_started_card":" Cartão iniciar",
   "settings.get_started1_card_desc":" Mostrar ou esconder começar no painel",
   "shipments.sync_7_days":" Sincronizar 7 dias de cumprimentos",
   "shipments.sync_14_days":" Sincronizar 14 dias de cumprimentos",
   "shipments.sync_30_days":" Sincronizar 30 dias de cumprimentos",
   "shipments.order_no":" Ordem Nº.",
   "shipments.product":" Produto",
   "shipments.carrier":" Portador",
   "shipments.tracking_number":" Rastreando não.",
   "shipments.ship_date":" Data do navio",
   "shipments.status":" Estado",
   "shipments.from":" De",
   "shipments.to":" Para",
   "shipments.time_used":" Tempo usado",
   "shipments.on_track":" Na pista",
   "shipments.all":" Todo",
   "shipments.pending":" Pendente",
   "shipments.exception":" Exceção",
   "shipments.undelivered":" Não entregue",
   "shipments.stalled":" Parado",
   "shipments.in_transit":" Em Trânsito",
   "shipments.on_delivery":" Na Entrega",
   "shipments.delivered":" Entregue",
   "shipments.by_tracking_no":" Rastreando no.",
   "shipments.by_carrier_name":" Por Nome do Portador",
   "shipments.by_order_no":" Por Ordem Nº.",
   "shipments.search_to_select_carrier":" Pesquisar para selecionar operadora",
   "shipments.type_keyword":" Digite palavra-chave...",
   "shipments.search":" Procurar",
   "shipments.reset":" Repor",
   "shipments.not_set":" Não definido",
   "shipments.modify_success":"foi modificado com sucesso",
   "shipments.modfiy_error":" Modifique o erro.",
   "shipments.select_a_shipment":" Por favor, selecione um carregamento.",
   "shipments.edit_carrier":" Editar porta-aviões",
   "shipments.cancel":" Cancelar",
   "shipments.submit":" Enviar",
   "shipments.change_to":" Mudar para",
   "tracking_page.page_theme":" Tema da página",
   "tracking_page.element_color":" Cor do elemento",
   "tracking_page.languages":" Idiomas",
   "tracking_page.auto_translate":" Tradução automática",
   "tracking_page.custom_html":" HTML personalizado",
   "tracking_page.preview":" Visualizar",
   "tracking_page.apply":" Aplicar",
   "tracking_page.pick_color":" Escolha uma cor de acento que você gosta de personalizar os elementos (por exemplo, os botões) na página de rastreamento. Clique em Salvar e recarregue a página de rastreamento para visualizar a alteração.",
   "tracking_page.save":" Salvar",
   "tracking_page.send_email":" Se você tem uma página de rastreamento de pedidos e gostaria de torná-la uma das opções temáticas no Parcelecho, envie um e-mail para nós em:",
   "tracking_page.applied":" Aplicado",
   "tracking_page.translate.desc1":" Por padrão, a página de rastreamento aparece em inglês e oferece traduções automaticamente somente quando mais de um idioma de exibição foi adicionado. ,",
   "tracking_page.translate.desc2":" O Parcelecho agora suporta 11 idiomas que personalizam a redação usada na página Tracking. Se você quiser aplicar o idioma de exibição desejado que não seja o inglês, selecione o idioma no menu suspenso e digite sua tradução personalizada na caixa de texto.,",
   "tracking_page.select_language":" Selecionar idioma",
   "tracking_page.setting_saved":" Configurações salvas.",
   "tracking_page.send_email_tips":"*Se o idioma que você deseja não estiver incluído na lista, não hesite em nos informar através do ícone de bate-papo abaixo ou nos deixe enviar um e-mail em support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Crie cabeçalho e rodapé personalizados na parte superior e inferior da sua página de rastreamento.",
   "tracking_page.custom_html.before_html_label":" Adicionar HTML personalizado na parte superior (opcional)",
   "tracking_page.custom_html.after_html_label":" Adicionar HTML personalizado na parte inferior (opcional)",
   "tracking_page.custom_html.css":" CSS personalizado (opcional)",
   "tracking_page.auto_translate.title":" Habilitar tradução automática (alimentado pelo Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Por padrão, todas as informações de rastreamento são exibidas em inglês mesmo quando sua página de rastreamento tem vários idiomas temáticos. Se você quiser oferecer traduções automaticamente para seus clientes, você pode ativar a opção Traduzir automaticamente. As informações de rastreamento serão traduzidas automaticamente, de acordo com o idioma de exibição da página de rastreamento,"
}