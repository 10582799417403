
export default{
   "tracking_page.page_theme":"ページのテーマ",
   "tracking_page.element_color":"要素の色",
   "tracking_page.languages":"言語",
   "tracking_page.auto_translate":"自動翻訳",
   "tracking_page.custom_html":"カスタム HTML",
   "tracking_page.preview":"プレビュー",
   "tracking_page.apply":"申し込む",
   "tracking_page.pick_color":"トラッキングページの要素(ボタンなど)をカスタマイズするアクセントカラーを選択します。[保存] をクリックし、追跡ページを再読み込みして変更を表示します。",
   "tracking_page.save":"セーブ",
   "tracking_page.send_email":"注文追跡ページのモックアップをお持ちで、Parcelechoのテーマオプションの1つにしたい場合は、",
   "tracking_page.applied":"応用",
   "tracking_page.translate.desc1":"デフォルトでは、追跡ページは英語で表示され、複数の表示言語が追加された場合にのみ自動的に翻訳が提供されます。,",
   "tracking_page.translate.desc2":"Parcelecho は、追跡ページで使用される文言をカスタマイズする 11 の言語をサポートするようになりました。英語以外の表示言語を適用する場合は、ドロップダウンメニューから言語を選択し、カスタマイズされた翻訳をテキストボックスに入力します。",
   "tracking_page.select_language":"言語の選択",
   "tracking_page.setting_saved":"設定が保存されました。",
   "tracking_page.send_email_tips":"*あなたが欲しい言語がリストに含まれていないならば、下のチャットアイコンを通して私達に知らせるか私達に電子メールをドロップすることを躊躇しないでください support@parcelecho.com..",
   "tracking_page.custom_html.desc":"トラッキングページの上部と下部にカスタムヘッダーとフッターを作成します。",
   "tracking_page.custom_html.before_html_label":"カスタム HTML を先頭に追加する (オプション)",
   "tracking_page.custom_html.after_html_label":"下部にカスタムHTMLを追加する(オプション)",
   "tracking_page.custom_html.css":"カスタム CSS (オプション)",
   "tracking_page.auto_translate.title":"自動翻訳を有効にする (マイクロソフト翻訳によって供給)",
   "tracking_page.auto_translate.desc":"デフォルトでは、トラッキングページに複数のテーマ言語がある場合でも、すべてのトラッキング情報は英語で表示されます。顧客に翻訳を自動的に提供したい場合は、[自動翻訳] オプションを有効にすることができます。追跡情報は、追跡ページの表示言語に従って自動的に翻訳され、",
   "tracking_page.porduct_recommendation":"製品の推奨事項",
   "tracking_page.not_show_product_recommendation":"追跡ページにPorductの推奨事項を表示しません。",
   "tracking_page.collections_show_on_tracking_page":"コレクションはトレースページに表示されます",
   "tracking_page.select_a_collection":"コレクションの選択"
}