
export default{
   "tracking_page.page_theme":" Tema Halaman",
   "tracking_page.element_color":" Warna Elemen",
   "tracking_page.languages":" Bahasa",
   "tracking_page.auto_translate":" Terjemahkan Otomatis",
   "tracking_page.custom_html":" HTML khusus",
   "tracking_page.preview":" Pratayang",
   "tracking_page.apply":" Berlaku",
   "tracking_page.pick_color":" Pilih warna aksen yang Anda inginkan untuk menyesuaikan elemen (misalnya tombol) pada halaman pelacakan. Klik Simpan dan muat ulang halaman pelacakan untuk melihat perubahan.",
   "tracking_page.save":" Simpan",
   "tracking_page.send_email":" Jika Anda memiliki mockup halaman pelacakan pesanan dan ingin menjadikannya salah satu opsi tema di Parcelecho, silakan kirim email kepada kami di,",
   "tracking_page.applied":" Diterapkan",
   "tracking_page.translate.desc1":" Secara default, halaman pelacakan muncul dalam bahasa Inggris, dan menawarkan terjemahan secara otomatis hanya ketika lebih dari satu bahasa tampilan ditambahkan. ,",
   "tracking_page.translate.desc2":" Parcelecho sekarang mendukung 11 bahasa yang menyesuaikan kata-kata yang digunakan pada halaman Pelacakan. Jika Anda ingin menerapkan bahasa tampilan yang Anda inginkan selain bahasa Inggris, pilih bahasa dari menu tarik-turun dan masukkan terjemahan khusus Anda di kotak teks.,",
   "tracking_page.select_language":" Pilih Bahasa",
   "tracking_page.setting_saved":" Pengaturan disimpan.",
   "tracking_page.send_email_tips":"*Jika bahasa yang Anda inginkan tidak termasuk dalam daftar, jangan ragu untuk memberi tahu kami melalui ikon obrolan di bawah ini atau kirimkan email kepada kami di support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Buat header dan footer kustom di bagian atas dan bawah halaman pelacakan Anda.",
   "tracking_page.custom_html.before_html_label":" Tambahkan HTML kustom di bagian atas (opsional)",
   "tracking_page.custom_html.after_html_label":" Tambahkan HTML kustom di bagian bawah(opsional)",
   "tracking_page.custom_html.css":" CSS kustom (opsional)",
   "tracking_page.auto_translate.title":" Aktifkan Terjemahan Otomatis (didukung oleh Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Secara default semua informasi pelacakan muncul dalam bahasa Inggris bahkan ketika halaman pelacakan Anda memiliki beberapa bahasa tema. Jika Anda ingin menawarkan terjemahan secara otomatis kepada pelanggan Anda, Anda dapat mengaktifkan opsi Terjemahkan Otomatis. Informasi pelacakan akan diterjemahkan secara otomatis, sesuai dengan bahasa tampilan halaman pelacakan,",
   "tracking_page.porduct_recommendation":" Rekomendasi Produk",
   "tracking_page.not_show_product_recommendation":" Tidak menampilkan Rekomendasi Porduct di halaman pelacakan.",
   "tracking_page.collections_show_on_tracking_page":" Koleksi ditampilkan di halaman trakcing",
   "tracking_page.select_a_collection":" Memilih koleksi"
}