
export default{
   "tracking_page.page_theme":" পৃষ্ঠা থিম",
   "tracking_page.element_color":" উপাদানের রঙ",
   "tracking_page.languages":" ভাষাগুলো",
   "tracking_page.auto_translate":" স্বতঃ অনুবাদ করুন",
   "tracking_page.custom_html":" কাস্টম HTML",
   "tracking_page.preview":" পূর্বরূপ",
   "tracking_page.apply":" প্রয়োগ",
   "tracking_page.pick_color":" ট্র্যাকিং পৃষ্ঠায় উপাদানগুলি (যেমন বোতামগুলি) কাস্টমাইজ করতে আপনি পছন্দ করেন এমন একটি অ্যাকসেন্ট রঙ চয়ন করুন। পরিবর্তনটি দেখতে সংরক্ষণ করুন ক্লিক করুন এবং ট্র্যাকিং পৃষ্ঠাটি পুনরায় লোড করুন।",
   "tracking_page.save":" সংরক্ষণ",
   "tracking_page.send_email":" আপনার যদি অর্ডার ট্র্যাকিং পৃষ্ঠার একটি মকআপ থাকে এবং এটি পার্সেলেচোতে থিম বিকল্পগুলির মধ্যে একটি করতে চান তবে দয়া করে আমাদের কাছে একটি ইমেল পাঠান,",
   "tracking_page.applied":" প্রয়োগ",
   "tracking_page.translate.desc1":" ডিফল্টরূপে ট্র্যাকিং পৃষ্ঠাটি ইংরেজিতে প্রদর্শিত হয় এবং কেবলমাত্র একাধিক প্রদর্শন ভাষা যোগ করা হলে স্বয়ংক্রিয়ভাবে অনুবাদ প্রদান করে। ,",
   "tracking_page.translate.desc2":" পার্সেলেকো এখন ট্র্যাকিং পৃষ্ঠায় ব্যবহৃত শব্দগুলি কাস্টমাইজ করার জন্য 11 টি ভাষা সমর্থন করে। আপনি যদি ইংরেজি ব্যতীত আপনার পছন্দসই প্রদর্শন ভাষা প্রয়োগ করতে চান তবে ড্রপ-ডাউন মেনু থেকে ভাষাটি নির্বাচন করুন এবং পাঠ্য বাক্সে আপনার কাস্টমাইজড অনুবাদ লিখুন।,",
   "tracking_page.select_language":" ভাষা নির্বাচন করুন",
   "tracking_page.setting_saved":" সেটিংস সংরক্ষিত হয়েছে।",
   "tracking_page.send_email_tips":" * আপনি যে ভাষাটি চান তা যদি তালিকায় অন্তর্ভুক্ত না করা হয় তবে নীচের চ্যাট আইকনের মাধ্যমে আমাদের জানাতে দ্বিধা করবেন না বা support@parcelecho.com এ আমাদের একটি ইমেল ছেড়ে দিন।",
   "tracking_page.custom_html.desc":" আপনার ট্র্যাকিং পৃষ্ঠার উপরে এবং নীচে কাস্টম শিরোনাম এবং পাদচরণ তৈরি করুন।",
   "tracking_page.custom_html.before_html_label":" শীর্ষে কাস্টম HTML যোগ করুন (ঐচ্ছিক)",
   "tracking_page.custom_html.after_html_label":" নীচে কাস্টম HTML যোগ করুন (ঐচ্ছিক)",
   "tracking_page.custom_html.css":" কাস্টম সিএসএস (ঐচ্ছিক)",
   "tracking_page.auto_translate.title":" স্বতঃ অনুবাদ সক্ষম করুন (Microsoft Translator দ্বারা চালিত)",
   "tracking_page.auto_translate.desc":" ডিফল্টরূপে সমস্ত ট্র্যাকিং তথ্য ইংরেজিতে প্রদর্শিত হয় এমনকি যখন আপনার ট্র্যাকিং পৃষ্ঠায় একাধিক থিম ভাষা থাকে। আপনি যদি আপনার গ্রাহকদের স্বয়ংক্রিয়ভাবে অনুবাদগুলি অফার করতে চান তবে আপনি স্বতঃ অনুবাদ বিকল্পটি সক্ষম করতে পারেন। ট্র্যাকিং পৃষ্ঠার প্রদর্শন ভাষা অনুসারে ট্র্যাকিং তথ্য স্বয়ংক্রিয়ভাবে অনুবাদ করা হবে,",
   "tracking_page.porduct_recommendation":" পণ্যের সুপারিশ",
   "tracking_page.not_show_product_recommendation":" ট্র্যাকিং পৃষ্ঠায় Porduct সুপারিশ দেখাবেন না।",
   "tracking_page.collections_show_on_tracking_page":" সংগ্রহগুলি ট্র্যাকিং পৃষ্ঠায় দেখায়",
   "tracking_page.select_a_collection":" একটি সংগ্রহ নির্বাচন করুন"
}