
export default{
   "analytic.tracking_page_pv":" Monitoraggio delle visualizzazioni di pagina",
   "analytic.last_7_days":" Ultimi 7 giorni",
   "analytic.last_30_days":" Ultimi 30 giorni",
   "analytic.last_90_days":" Ultimi 90 giorni",
   "analytic.average_pv":" Visualizzazioni di pagina giornaliere medie",
   "analytic.total_pv":" Totale visualizzazioni di pagina",
   "analytic.tracking_pv_by_day":" Monitoraggio delle visualizzazioni di pagina per giorno",
   "analytic.pv":" Visualizzazioni di pagina",
   "analytic.date":" Dattero",
   "analytic.email_sent_by_day":" Media giornaliera delle e-mail inviate",
   "analytic.total_emails_sent":" Totale e-mail inviate",
   "analytic.emails_sent_by_day":" Email inviate di giorno",
   "analytic.email_sent_count":" Conteggio delle e-mail inviate",
   "menu.Dashboard":" Cruscotto",
   "menu.Analytics":" Analitica",
   "menu.Analytics.Notifications":" Notifiche",
   "menu.Analytics.Tracking Page":" Pagina di monitoraggio",
   "menu.Shipments":" Spedizioni",
   "menu.Notifications":" Notifiche",
   "menu.Tracking Page":" Pagina di monitoraggio",
   "menu.Billing":" Fatturazione",
   "menu.Settings":" Impostazioni",
   "dashboard.tab.sync_order":" Sincronizza gli ordini",
   "dashboard.tab.enable_email_notification":" Abilita notifiche e-mail",
   "dashboard.tab.add_tracking_page":" Aggiungi pagina di monitoraggio",
   "dashboard.content.enable_email_notification.one":" Se scegli di abilitare il servizio di notifica e-mail, i tuoi clienti riceveranno un'e-mail di aggiornamento su ogni cambiamento di stato principale dei loro ordini,",
   "dashboard.content.enable_email_notification.two":" Potresti andare al",
   "dashboard.content.enable_email_notification.three":" Notifiche",
   "dashboard.content.enable_email_notification.four":" a sinistra per configurare ulteriormente se abilitare la notifica e-mail di uno specifico evento di spedizione.",
   "dashboard.content.add_tracking_page.one":" Il link predefinito della pagina di monitoraggio Parcelecho utilizza il dominio del tuo negozio Shopify.",
   "dashboard.content.add_tracking_page.two":" Questo è utile per la SEO e potrebbe migliorare il ranking Alexa del tuo negozio. Si prega di copiare il link qui sotto e",
   "dashboard.content.add_tracking_page.three":" segui la nostra guida passo-passo",
   "dashboard.content.add_tracking_page.four":" sull'aggiunta della pagina di monitoraggio al tuo negozio Shopify.",
   "dashboard.content.add_tracking_page.five":" Attualmente la pagina di monitoraggio applica il colore blu per impostazione predefinita. Puoi andare alla scheda Pagina di monitoraggio a sinistra e regolare il colore del tema della pagina di monitoraggio come preferisci.,",
   "dashboard.content.sync_order.one":" Grazie per aver provato Parcelecho!",
   "dashboard.content.sync_order.two":" Ora Parcelecho ha sincronizzato 10 dei tuoi ultimi adempimenti nel sistema di tracciamento. Di seguito puoi vedere come funziona l'analisi di Parcelecho e lo stato di utilizzo corrente del tuo piano. Per visualizzare gli adempimenti sincronizzati, vai alla sezione,",
   "dashboard.content.sync_order.shipment":" Spedizioni",
   "dashboard.content.sync_order.three":" a sinistra. Lì puoi scegliere di sincronizzare più ordini evasi e scegliere se rimuovere un adempimento sincronizzato dal monitoraggio per salvare le quote di tracciamento. Se un adempimento è in pista, Parcelecho può avvisare il cliente con e-mail quando questo ordine viene aggiornato.,",
   "dashboard.content.sync_order.four":" Vai alla scheda successiva sul servizio di notifica e-mail per continuare la configurazione per iniziare a utilizzare Parcelecho.",
   "dashboard.dismiss":" Licenziare",
   "dashboard.get_started":" Inizia subito",
   "dashboard.proportion_status.order_by_status":" Ordini per stato",
   "dashboard.intro.vaild_rate":" Tariffa valida",
   "dashboard.intro.vaild_rate_tip":" La tariffa valida diminuisce quando aumenta il numero di ordini in sospeso. Fai clic sulla scheda per visualizzare tutti gli ordini in sospeso che richiedono attenzione.",
   "dashboard.intro.exceptions":" Eccezioni",
   "dashboard.intro.exceptions_tip":" Mostra il numero corrente di ordini di eccezione. Fare clic sulla scheda per visualizzare tutti gli ordini di eccezione.",
   "dashboard.intro.total_exceptions":" Totale eccezioni",
   "dashboard.intro.tracking_pv":" Pagina di monitoraggio PV",
   "dashboard.intro.tracking_pv_tip":" Visualizzazioni di pagina alla tua pagina di monitoraggio negli ultimi 7 giorni.",
   "dashboard.intro.today_views":" Visualizzazioni di oggi",
   "dashboard.intro.unfulfilled_orders":" Ordini non evasi",
   "dashboard.intro.unfulfilled_orders_tip":" Mostra il numero corrente di ordini e articoli non evasi all'interno. Fai clic sulla scheda per visualizzare tutti gli ordini non evasi.",
   "dashboard.intro.total_unfulfilled_order":" Totale ordini non evasi",
   "dashboard.intro.unfulfilled_items":" Oggetti non soddisfatti",
   "dashboard.intor.total_pending_count":" Conteggio totale in sospeso",
   "dashboard.fulfillment_card.title":" Evasione e ordini di giorno",
   "dashboard.fulfillment_card.daily_fulfillment":" Adempimento quotidiano",
   "dashboard.fulfillment_card.date":" Dattero",
   "dashboard.plan.cacel_plan_tip":" Sei sicuro di voler annullare il tuo piano attuale?",
   "dashboard.plan.cacel_text":" Non importa,",
   "dashboard.plan.cacel_confirm":" Annulla il mio piano",
   "dashboard.plan.overview":" Panoramica del piano",
   "dashboard.plan.upgrade":" Aggiornamento",
   "dashboard.plan.tracking_used":" Tracciamento utilizzato",
   "dashboard.plan.next_invoice":" Prossima fattura",
   "dashboard.plan.cancel_subscription":" Annulla abbonamento",
   "billing.plan_feature_1":" Accesso a oltre 800 operatori",
   "billing.plan_feature_2":" Pagina di monitoraggio con marchio",
   "billing.plan_feature_3":" Notifica e-mail",
   "billing.plan_feature_4":" Dashboard e analisi",
   "billing.plan_feature_5":" Supporto rapido",
   "billing.plan_feature_6":" Supporto dedicato",
   "billing.redeem_coupon":" Riscatta coupon",
   "billing.please_enter_coupon_code":" Inserisci il codice coupon",
   "billing.total_shipment_last 30 days":" Totale spedizioni negli ultimi 30 giorni",
   "billing.upgarde_tips":" Una volta esauriti tutti i 100 tracciamenti, dovrai passare a un piano a pagamento,",
   "billing.choose_plan":" Scegli Piano",
   "billing.essential":" ESSENZIALE",
   "billing.month":" Mese",
   "billing.orders":"ordini",
   "billing.extra_price":" * $ 0,04 per ogni spedizione extra oltre le quote di abbonamento",
   "billing.tip_1":" Per la pagina di monitoraggio e le notifiche e-mail - Sebbene questi due servizi siano alimentati da Parcelecho, ti assicuriamo che non chiederemo mai ai tuoi clienti di scaricare nulla o richiedere le loro informazioni, come fanno alcune delle nostre controparti. La nostra missione è fornirti una migliore esperienza post-vendita, non promuoverci tra i tuoi clienti.",
   "billing.tip_2":" Attualmente, quando il numero di tracciamenti nel tuo piano di abbonamento è esaurito, la ricarica verrà attivata automaticamente. Ogni pacchetto di ricarica include 25 tracciamenti extra e costa $ 1. In questo caso, se continui a utilizzare i tracciamenti prima del rinnovo automatico dell'abbonamento, con il quale vengono rinnovate anche le quote di monitoraggio, il tuo account potrebbe essere aggiunto con più di un pacchetto di ricarica. Riceverai un'e-mail di notifica ogni volta che un pacchetto di ricarica viene aggiunto al tuo account. Il pagamento totale di tutti i pacchetti di ricarica utilizzati verrà addebitato il prossimo giorno di fatturazione Shopify.,",
   "notifications.title":" Notifiche e-mail",
   "notifications.in_transit_desc":" Avvisa il tuo cliente con un'e-mail quando il suo pacco è in viaggio verso la destinazione.",
   "notifications.exception_desc":" Avvisa il tuo cliente con un'e-mail quando il corriere ha scoperto che il suo pacco non è consegnabile o semplicemente non può completare la consegna perché il pacco è stato restituito al mittente o è perso / danneggiato.",
   "notifications.on_delivery_desc":" Informa il cliente con un'e-mail quando il suo pacco è stato consegnato per la consegna o pronto per il ritiro presso l'ufficio locale o la struttura postale.",
   "notifications.delivered_desc":" Avvisa il cliente con un'e-mail quando il suo pacco è stato consegnato con successo.",
   "notifications.view_template":" Visualizza modello",
   "settings.title":" Impostazioni",
   "settings.powered_by_parcelecho":" Realizzato da Parcelecho",
   "settings.powered_by_parcelecho_desc":" Mostra o rimuovi Powered By Parcelecho nella pagina di monitoraggio",
   "settings.get_started_card":" Scheda introduttiva",
   "settings.get_started1_card_desc":" Mostra o nascondi Inizia a dashboard",
   "shipments.sync_7_days":" Sincronizza gli adempimenti di 7 giorni",
   "shipments.sync_14_days":" Sincronizza gli adempimenti di 14 giorni",
   "shipments.sync_30_days":" Sincronizza gli adempimenti di 30 giorni",
   "shipments.order_no":" Numero d'ordine",
   "shipments.product":" Prodotto",
   "shipments.carrier":" Trasportatore",
   "shipments.tracking_number":" Numero di tracciamento",
   "shipments.ship_date":" Data di spedizione",
   "shipments.status":" Stato",
   "shipments.from":" Da",
   "shipments.to":" A",
   "shipments.time_used":" Tempo utilizzato",
   "shipments.on_track":" In pista",
   "shipments.all":" Tutto",
   "shipments.pending":" In sospeso",
   "shipments.exception":" Eccezione",
   "shipments.undelivered":" Non consegnato",
   "shipments.stalled":" Stallo",
   "shipments.in_transit":" In transito",
   "shipments.on_delivery":" Alla consegna",
   "shipments.delivered":" Consegnato",
   "shipments.by_tracking_no":" Tracciando il n.",
   "shipments.by_carrier_name":" Per nome del vettore",
   "shipments.by_order_no":" Con numero d'ordine",
   "shipments.search_to_select_carrier":" Cerca per selezionare l'operatore",
   "shipments.type_keyword":" Digita parola chiave...",
   "shipments.search":" Ricerca",
   "shipments.reset":" Resettare",
   "shipments.not_set":" Non impostato",
   "shipments.modify_success":"è stato modificato correttamente",
   "shipments.modfiy_error":" Errore di modifica.",
   "shipments.select_a_shipment":" Seleziona almeno una spedizione.",
   "shipments.edit_carrier":" Modifica operatore",
   "shipments.cancel":" Annulla",
   "shipments.submit":" Invia",
   "shipments.change_to":" Passare a",
   "tracking_page.page_theme":" Tema della pagina",
   "tracking_page.element_color":" Colore elemento",
   "tracking_page.languages":" Lingue",
   "tracking_page.auto_translate":" Traduzione automatica",
   "tracking_page.custom_html":" HTML personalizzato",
   "tracking_page.preview":" Anteprima",
   "tracking_page.apply":" Applicare",
   "tracking_page.pick_color":" Scegli un colore di accento che ti piace per personalizzare gli elementi (ad esempio i pulsanti) nella pagina di monitoraggio. Fare clic su Salva e ricaricare la pagina di monitoraggio per visualizzare la modifica.",
   "tracking_page.save":" Salvare",
   "tracking_page.send_email":" Se hai un mockup della pagina di tracciamento dell'ordine e desideri renderlo una delle opzioni tematiche in Parcelecho, ti preghiamo di inviarci un'e-mail all'indirizzo,",
   "tracking_page.applied":" Applicato",
   "tracking_page.translate.desc1":" Per impostazione predefinita, la pagina di monitoraggio viene visualizzata in inglese e offre automaticamente le traduzioni solo quando è stata aggiunta più di una lingua di visualizzazione. ,",
   "tracking_page.translate.desc2":" Parcelecho ora supporta 11 lingue personalizzando la dicitura utilizzata nella pagina di monitoraggio. Se desideri applicare la lingua di visualizzazione desiderata diversa dall'inglese, seleziona la lingua dal menu a discesa e inserisci la traduzione personalizzata nella casella di testo.",
   "tracking_page.select_language":" Seleziona lingua",
   "tracking_page.setting_saved":" Impostazioni salvate.",
   "tracking_page.send_email_tips":" * Se la lingua desiderata non è inclusa nell'elenco, non esitate a farcelo sapere tramite l'icona della chat qui sotto o inviateci un'e-mail al support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Crea intestazione e piè di pagina personalizzati nella parte superiore e inferiore della pagina di monitoraggio.",
   "tracking_page.custom_html.before_html_label":" Aggiungi HTML personalizzato in alto (facoltativo)",
   "tracking_page.custom_html.after_html_label":" Aggiungi HTML personalizzato in basso (opzionale)",
   "tracking_page.custom_html.css":" CSS personalizzato (opzionale)",
   "tracking_page.auto_translate.title":" Abilita traduzione automatica (con tecnologia Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Per impostazione predefinita, tutte le informazioni di monitoraggio vengono visualizzate in inglese anche quando la pagina di monitoraggio ha più lingue del tema. Se desideri offrire traduzioni automaticamente ai tuoi clienti, puoi abilitare l'opzione Traduzione automatica. Le informazioni di tracciamento verranno tradotte automaticamente, in conformità con la lingua di visualizzazione della pagina di tracciamento,"
}