import capped_amount from "./fr-FR/capped_amount";
import build from "./fr-FR/build";
import email_translation from "./bn-BD/email_translation";
import tracking_page from "./fr-FR/tracking_page";

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
}