
export default{
   "tracking_page.page_theme":"頁面主題",
   "tracking_page.element_color":"元素顏色",
   "tracking_page.languages":"語言",
   "tracking_page.auto_translate":"自動翻譯",
   "tracking_page.custom_html":"自訂 HTML",
   "tracking_page.preview":"預覽",
   "tracking_page.apply":"應用",
   "tracking_page.pick_color":"選擇您想要的主題色以自訂追蹤頁面上的元素（例如按鈕）。點擊保存並重新載入跟蹤頁面以查看更改。",
   "tracking_page.save":"救",
   "tracking_page.send_email":"如果您有訂單跟蹤頁面的模型，並希望將其作為Parcelecho的主題選項之一，請發送電子郵件至：",
   "tracking_page.applied":"應用的",
   "tracking_page.translate.desc1":"默認情況下，跟蹤頁面以英語顯示，並且僅在添加了多種顯示語言時自動提供翻譯。,",
   "tracking_page.translate.desc2":"Parcelecho現在支援11種語言自定義跟蹤頁面上使用的措辭。如果要應用所需的英語以外的顯示語言，請從下拉功能表中選擇該語言，然後在文本框中輸入自定義翻譯。",
   "tracking_page.select_language":"選擇語言",
   "tracking_page.setting_saved":"已保存設置。",
   "tracking_page.send_email_tips":"*如果您想要的語言未包含在清單中，請隨時通過下面的聊天圖示告知我們，或在 support@parcelecho.com 給我們發送電子郵件。",
   "tracking_page.custom_html.desc":"在跟蹤頁面的頂部和底部創建自定義頁眉和頁腳。",
   "tracking_page.custom_html.before_html_label":"在頂部新增自訂 HTML（選擇）",
   "tracking_page.custom_html.after_html_label":"在底部新增自訂 HTML（選擇）",
   "tracking_page.custom_html.css":"自訂 CSS（選擇）",
   "tracking_page.auto_translate.title":"開啟自動翻譯（由微軟翻譯提供支援）",
   "tracking_page.auto_translate.desc":"默認情況下，所有跟蹤資訊都以英語顯示，即使您的跟蹤頁面具有多種主題語言也是如此。如果您希望自動向客戶提供翻譯，可以啟用「自動翻譯」選項。跟蹤資訊將自動翻譯，按照跟蹤頁面的顯示語言，",
   "tracking_page.porduct_recommendation":"產品推薦",
   "tracking_page.not_show_product_recommendation":"不在跟蹤頁面上顯示項目建議。",
   "tracking_page.collections_show_on_tracking_page":"在運輸頁面上顯示產品系列",
   "tracking_page.select_a_collection":"選擇一個集合"
}