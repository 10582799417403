
export default{
   "tracking_page.page_theme":" ערכת נושא של דף",
   "tracking_page.element_color":" צבע אלמנט",
   "tracking_page.languages":" שפות",
   "tracking_page.auto_translate":" תרגום אוטומטי",
   "tracking_page.custom_html":" HTML מותאם אישית",
   "tracking_page.preview":" תצוגה מקדימה",
   "tracking_page.apply":" החל",
   "tracking_page.pick_color":" בחר צבע הדגשה שאתה אוהב כדי להתאים אישית את הרכיבים (לדוגמה, הלחצנים) בדף המעקב. לחץ על שמור וטען מחדש את דף המעקב כדי להציג את השינוי.",
   "tracking_page.save":" שמר",
   "tracking_page.send_email":" אם יש לך mockup של דף מעקב אחר הזמנות וברצונך להפוך אותו לאחת מאפשרויות הנושא ב- Parcelecho אנא שלח לנו דוא\"ל בכתובת,",
   "tracking_page.applied":" להחיל",
   "tracking_page.translate.desc1":" כברירת מחדל, דף המעקב מופיע באנגלית, ומציע תרגומים באופן אוטומטי רק כאשר נוספה יותר משפת תצוגה אחת. ,",
   "tracking_page.translate.desc2":" Parcelecho תומך כעת ב-11 שפות בהתאמה אישית של הניסוח שבו נעשה שימוש בדף המעקב. אם ברצונך להחיל את שפת התצוגה הרצויה שאינה אנגלית בחר את השפה מהתפריט הנפתח והזן את התרגום המותאם אישית בתיבת הטקסט.,",
   "tracking_page.select_language":" בחר שפה",
   "tracking_page.setting_saved":" הגדרות שנשמרו.",
   "tracking_page.send_email_tips":"*אם השפה הרצויה אינה כלולה ברשימה אל תהססו ליידע אותנו באמצעות סמל הצ'אט למטה או לשלוח לנו דוא\"ל בכתובת support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" צור כותרת עליונה וכותרת תחתונה מותאמות אישית בחלק העליון והתחתון של דף המעקב שלך.",
   "tracking_page.custom_html.before_html_label":" הוסף HTML מותאם אישית בחלק העליון (אופציונלי)",
   "tracking_page.custom_html.after_html_label":" הוספת HTML מותאם אישית בתחתית(אופציונלי)",
   "tracking_page.custom_html.css":" CSS מותאם אישית (אופציונלי)",
   "tracking_page.auto_translate.title":" אפשר תרגום אוטומטי לזמין (מופעל על-ידי המתרגם של מיקרוסופט)",
   "tracking_page.auto_translate.desc":" כברירת מחדל, כל פרטי המעקב מופיעים באנגלית גם כאשר דף המעקב כולל מספר שפות נושא. אם ברצונך להציע תרגומים באופן אוטומטי ללקוחות שלך, באפשרותך להפעיל את האפשרות 'תרגום אוטומטי'. מידע המעקב יתורגם באופן אוטומטי, בהתאם לשפת התצוגה של דף המעקב ,",
   "tracking_page.porduct_recommendation":" המלצת מוצר",
   "tracking_page.not_show_product_recommendation":" לא להציג המלצת Porduct בדף המעקב.",
   "tracking_page.collections_show_on_tracking_page":" אוספים מוצגים בדף trakcing",
   "tracking_page.select_a_collection":" בחירת אוסף"
}