
export default{
   "analytic.tracking_page_pv":" מעקב אחר צפיות בדף",
   "analytic.last_7_days":" 7 הימים האחרונים",
   "analytic.last_30_days":" 30 הימים האחרונים",
   "analytic.last_90_days":" 90 הימים האחרונים",
   "analytic.average_pv":" צפיות יומיות ממוצעות בדף",
   "analytic.total_pv":" סה\"כ צפיות בדף",
   "analytic.tracking_pv_by_day":" מעקב אחר צפיות בדף לפי יום",
   "analytic.pv":" צפיות בדף",
   "analytic.date":" תמר",
   "analytic.email_sent_by_day":" הודעות דוא\"ל יומיות ממוצעות שנשלחו",
   "analytic.total_emails_sent":" סה\"כ הודעות דוא\"ל שנשלחו",
   "analytic.emails_sent_by_day":" הודעות דוא\"ל שנשלחו ביום",
   "analytic.email_sent_count":" ספירת דואר אלקטרוני שנשלחה",
   "menu.Dashboard":" לוח המחוונים",
   "menu.Analytics":" ניתוח",
   "menu.Analytics.Notifications":" הודעות",
   "menu.Analytics.Tracking Page":" דף מעקב",
   "menu.Shipments":" משלוחים",
   "menu.Notifications":" הודעות",
   "menu.Tracking Page":" דף מעקב",
   "menu.Billing":" חיוב",
   "menu.Settings":" הגדרות",
   "dashboard.tab.sync_order":" סנכרן הזמנות",
   "dashboard.tab.enable_email_notification":" הפיכת הודעות דואר אלקטרוני לזמינות",
   "dashboard.tab.add_tracking_page":" הוסף דף מעקב",
   "dashboard.content.enable_email_notification.one":" אם תבחר להפעיל את שירות ההודעות בדוא\"ל, הלקוחות שלך יקבלו דוא\"ל מעודכן על כל שינוי סטטוס מרכזי בהזמנות שלהם,",
   "dashboard.content.enable_email_notification.two":" אתה יכול ללכת ל",
   "dashboard.content.enable_email_notification.three":" הודעות",
   "dashboard.content.enable_email_notification.four":"tab מימין כדי לקבוע עוד יותר אם להפוך את הודעת הדואר האלקטרוני של אירוע משלוח ספציפי לזמינה.",
   "dashboard.content.add_tracking_page.one":" קישור ברירת המחדל של דף המעקב של Parcelecho משתמש בתחום של חנות Shopify משלך.",
   "dashboard.content.add_tracking_page.two":" זה מועיל לקידום אתרים ויכול לשפר את הדירוג של Alexa של החנות שלך. נא העתק את הקישור להלן ו-",
   "dashboard.content.add_tracking_page.three":"עקבו אחר המדריך שלנו צעד אחר צעד",
   "dashboard.content.add_tracking_page.four":"בהוספת דף המעקב לחנות Shopify שלך.",
   "dashboard.content.add_tracking_page.five":" כעת דף המעקב מחיל את צבע הכחול כברירת מחדל. באפשרותך לעבור לכרטיסיה דף מעקב מימין ולהתאים את צבע ערכת הנושא של דף המעקב כרצונך.,",
   "dashboard.content.sync_order.one":" תודה שניסית את פרסצ'ו!",
   "dashboard.content.sync_order.two":" עכשיו Parcelecho סנכרן 10 של ההגשמות האחרונות שלך לתוך מערכת המעקב. בתצוגה הבאה תוכל לראות כיצד ניתוח של Parcelecho\\s פועל, כמו גם את מצב השימוש הנוכחי של התוכנית שלך. כדי להציג את ההגשמות המסונכרנות, עבור אל,",
   "dashboard.content.sync_order.shipment":" משלוחים",
   "dashboard.content.sync_order.three":" בצד שמאל. שם באפשרותך לבחור לסנכרן הזמנות נוספות שמומשו ולבחור אם להסיר מימוש מסונכרן ממעקב כדי לשמור את מיכסות המעקב. אם הגשמה היא על המסלול, Parcelecho יכול להודיע ללקוח שלך עם הודעות דוא\"ל כאשר זה הזמנה זו מתעדכנת.,",
   "dashboard.content.sync_order.four":" עבור לכרטיסיה הבאה בשירות הודעות דואר אלקטרוני כדי להמשיך את ההגדרה שלך להתחלת השימוש ב- Parcelecho.",
   "dashboard.dismiss":" בטל",
   "dashboard.get_started":" תחילת העבודה",
   "dashboard.proportion_status.order_by_status":" הזמנות לפי מצב",
   "dashboard.intro.vaild_rate":" תעריף חוקי",
   "dashboard.intro.vaild_rate_tip":" התעריף החוקי יורד כאשר מספר ההזמנות הממתינות עולה. לחץ על הכרטיס כדי להציג את כל ההזמנות הממתינות הזקוקות לתשומת לב.",
   "dashboard.intro.exceptions":" חריגים",
   "dashboard.intro.exceptions_tip":" מציג את המספר הנוכחי של הזמנות חריגות. לחץ על הכרטיס כדי להציג את כל סדרי החריגה.",
   "dashboard.intro.total_exceptions":" סה\"כ חריגים",
   "dashboard.intro.tracking_pv":" מעקב אחר עמוד PV",
   "dashboard.intro.tracking_pv_tip":" צפיות בדף בדף המעקב שלך ב-7 הימים האחרונים.",
   "dashboard.intro.today_views":" תצוגות היום\\s",
   "dashboard.intro.unfulfilled_orders":" הזמנות שלא מולאו",
   "dashboard.intro.unfulfilled_orders_tip":" מציג את המספר הנוכחי של הזמנות ופריטים שלא מומשו בתוכם. לחץ על הכרטיס כדי להציג את כל ההזמנות שלא מומשו.",
   "dashboard.intro.total_unfulfilled_order":" סה\"כ הזמנות שלא מולאו",
   "dashboard.intro.unfulfilled_items":" פריטים שלא מולאו",
   "dashboard.intor.total_pending_count":" סה\"כ ספירה ממתינה",
   "dashboard.fulfillment_card.title":" מימוש והזמנות לפי יום",
   "dashboard.fulfillment_card.daily_fulfillment":" הגשמה יומית",
   "dashboard.fulfillment_card.date":" תמר",
   "dashboard.plan.cacel_plan_tip":" האם אתה בטוח שברצונך לבטל את התוכנית הנוכחית שלך?",
   "dashboard.plan.cacel_text":" לא משנה.",
   "dashboard.plan.cacel_confirm":" בטל את התוכנית שלי",
   "dashboard.plan.overview":" מבט כולל על התוכנית",
   "dashboard.plan.upgrade":" שדרג",
   "dashboard.plan.tracking_used":" מעקב בשימוש",
   "dashboard.plan.next_invoice":" החשבונית הבאה",
   "dashboard.plan.cancel_subscription":" בטל מנוי",
   "billing.plan_feature_1":" גישה ליותר מ-800 ספקים",
   "billing.plan_feature_2":" דף מעקב ממותג",
   "billing.plan_feature_3":" הודעת דואר אלקטרוני",
   "billing.plan_feature_4":" לוח מחוונים וניתוח נתונים",
   "billing.plan_feature_5":" תמיכה מהירה",
   "billing.plan_feature_6":" תמיכה ייעודית",
   "billing.redeem_coupon":" מימוש קופון",
   "billing.please_enter_coupon_code":" נא הזן קוד קופון",
   "billing.total_shipment_last 30 days":" סה\"כ משלוחים ב-30 הימים האחרונים",
   "billing.upgarde_tips":" לאחר שכל 100 המעקבים ינוצלו תצטרך לשדרג לתוכנית בתשלום,",
   "billing.choose_plan":" בחר תוכנית",
   "billing.essential":" חיוני",
   "billing.month":" חודש",
   "billing.orders":"סדרי ",
   "billing.extra_price":"* $0.04 עבור משלוח נוסף מעבר למיכסות מנוי",
   "billing.tip_1":" למעקב אחר דפים והודעות דוא\"ל - למרות ששני שירותים אלה מופעלים על ידי Parcelecho אנו מבטיחים לך שלעולם לא נבקש מהלקוחות שלך להוריד משהו או לבקש את המידע שלהם, כפי שעושים חלק מעמיתינו. המשימה שלנו היא לספק לך חוויה טובה יותר לאחר המכירה, לא לקדם את עצמנו בקרב הלקוחות שלך.,",
   "billing.tip_2":" כעת, כאשר נעשה שימוש במספר המעקבים בתוכנית המנוי שלך, למעלה יופעל באופן אוטומטי. כל חבילה כוללת 25 מעקבים נוספים ועולה $1. במקרה זה, אם תמשיך לצרוך מעקבים לפני החידוש האוטומטי של המנוי \\ - שאיתו מתחדשות גם מיכסות המעקב שלך - ניתן להוסיף את החשבון שלך עם יותר מחבילת למעלה אחת. תקבל הודעת דואר אלקטרוני עם הודעה בכל פעם כאשר חבילה למעלה תתווסף לחשבון שלך. התשלום הכולל של כל האריזות הלמעלה שבהן נעשה שימוש יחויב ביום החיוב הבא של Shopify.,",
   "notifications.title":" התראות בדואר אלקטרוני",
   "notifications.in_transit_desc":" הודע ללקוח שלך באמצעות הודעת דואר אלקטרוני כאשר החבילה שלו בדרכה ליעד.",
   "notifications.exception_desc":" הודע ללקוח שלך בדוא\"ל כאשר המפעיל מצא שהחבילה שלו אינה ניתנת למסירה או פשוט לא יכול/לא יכול להשלים את המסירה מכיוון שהחבילה הוחזרה לשולח או שהיא אבדה/ ניזוקה.",
   "notifications.on_delivery_desc":" הודע ללקוח שלך בדוא\"ל כאשר החבילה שלו נמסרה למסירה או מוכנה לאיסוף במשרד המקומי או במתקן הדואר.",
   "notifications.delivered_desc":" הודע ללקוח שלך באמצעות הודעת דואר אלקטרוני כאשר החבילה שלו נמסרה בהצלחה.",
   "notifications.view_template":" הצג תבנית",
   "shipments.sync_7_days":" סנכרן מימושים של 7 ימים",
   "shipments.sync_14_days":" סינכרון מימושים של 14 יום",
   "shipments.sync_30_days":" סינכרון מימושים של 30 יום",
   "shipments.order_no":" הזמנה מס'",
   "shipments.product":" מכפלה",
   "shipments.carrier":" נושאת",
   "shipments.tracking_number":" מעקב לא.",
   "shipments.ship_date":" תאריך משלוח",
   "shipments.status":" מצב",
   "shipments.from":" מ",
   "shipments.to":" ל",
   "shipments.time_used":" זמן שימוש",
   "shipments.on_track":" על המסלול",
   "shipments.all":" כל",
   "shipments.pending":" ממתינים",
   "shipments.exception":" חריגה",
   "shipments.undelivered":" לא נשלח",
   "shipments.stalled":" תקוע",
   "shipments.in_transit":" במעבר",
   "shipments.on_delivery":" בעת המסירה",
   "shipments.delivered":" נמסר",
   "shipments.by_tracking_no":" על ידי מעקב אחר לא.",
   "shipments.by_carrier_name":" לפי שם ספק שירות",
   "shipments.by_order_no":" בפקודה מס' 1.",
   "shipments.search_to_select_carrier":" חפש כדי לבחור ספק שירות",
   "shipments.type_keyword":" הקלד מילת מפתח...",
   "shipments.search":" חיפוש",
   "shipments.reset":" איפוס",
   "shipments.not_set":" לא מוגדר",
   "shipments.modify_success":" שונתה בהצלחה",
   "shipments.modfiy_error":" שינוי שגיאה.",
   "shipments.select_a_shipment":" נא בחר לפחות משלוח.",
   "shipments.edit_carrier":" ערוך ספק שירות",
   "shipments.cancel":" ביטל",
   "shipments.submit":" שלח",
   "shipments.change_to":" שנה ל",
   "tracking_page.page_theme":" ערכת נושא של דף",
   "tracking_page.element_color":" צבע רכיב",
   "tracking_page.languages":" שפות",
   "tracking_page.auto_translate":" תרגום אוטומטי",
   "tracking_page.custom_html":" HTML מותאם אישית",
   "tracking_page.preview":" תצוגה מקדימה",
   "tracking_page.apply":" החל",
   "tracking_page.pick_color":" בחר צבע הדגשה שברצונך להתאים אישית את הרכיבים (לדוגמה, הלחצנים) בדף המעקב. לחץ על שמור וטען מחדש את דף המעקב כדי להציג את השינוי.",
   "tracking_page.save":" שמר",
   "tracking_page.send_email":" אם יש לך מוקאפ של דף מעקב הזמנות והיית רוצה להפוך אותו לאחת מאפשרויות הנושא ב- Parcelecho אנא שלח לנו דוא\"ל ב,",
   "tracking_page.applied":" להחיל",
   "tracking_page.translate.desc1":" כברירת מחדל, דף המעקב מופיע באנגלית ומציע תרגומים באופן אוטומטי רק כאשר נוספה יותר משפת תצוגה אחת. ,",
   "tracking_page.translate.desc2":" Parcelecho תומך כעת ב- 11 שפות המתאימות אישית את הניסוח המשמש בדף המעקב. אם ברצונך להחיל את שפת התצוגה הרצויה מלבד אנגלית, בחר את השפה מהתפריט הנפתח והזן את התרגום המותאם אישית בתיבת הטקסט.,",
   "tracking_page.select_language":" בחר שפה",
   "tracking_page.setting_saved":" ההגדרות נשמרו.",
   "tracking_page.send_email_tips":"*אם השפה הרצויה אינה כלולה ברשימה אל תהססו להודיע לנו דרך סמל הצ'אט שלהלן או שחררו לנו דוא\"ל בשעה support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" צור כותרת עליונה וכותרת תחתונה מותאמות אישית בחלק העליון והתחתון של דף המעקב.",
   "tracking_page.custom_html.before_html_label":" הוספת HTML מותאם אישית בחלק העליון (אופציונלי)",
   "tracking_page.custom_html.after_html_label":" הוספת HTML מותאם אישית בתחתית (אופציונלי)",
   "tracking_page.custom_html.css":" CSS מותאם אישית (אופציונלי)",
   "tracking_page.auto_translate.title":" הפוך תרגום אוטומטי לזמין (מופעל על-ידי Microsoft Translator)",
   "tracking_page.auto_translate.desc":" כברירת מחדל, כל פרטי המעקב מופיעים באנגלית גם כאשר לדף המעקב שלך יש שפות ערכת נושא מרובות. אם ברצונך להציע תרגומים באופן אוטומטי ללקוחות שלך, באפשרותך להפוך את האפשרות תרגום אוטומטי לזמינה. פרטי המעקב יתורגמו באופן אוטומטי, בהתאם לשפת התצוגה של דף המעקב ,",
   "settings.title":" הגדרות",
   "settings.powered_by_parcelecho":" מופעל על ידי Parcelecho",
   "settings.powered_by_parcelecho_desc":" הצגה או הסרה מופעל על ידי Parcelecho בדף המעקב",
   "settings.get_started_card":" כרטיס תחילת העבודה",
   "settings.get_started1_card_desc":" הצגה או הסתרה של תחילת העבודה בלוח המחוונים"
}