
export default{
   "analytic.tracking_page_pv":" ট্র্যাকিং পৃষ্ঠা পৃষ্ঠাদর্শন",
   "analytic.last_7_days":" শেষ ৭ দিন",
   "analytic.last_30_days":" গত ৩০ দিন",
   "analytic.last_90_days":" গত ৯০ দিন",
   "analytic.average_pv":" গড় দৈনিক পৃষ্ঠাভিউ",
   "analytic.total_pv":" মোট পৃষ্ঠাদর্শন",
   "analytic.tracking_pv_by_day":" ট্র্যাকিং পৃষ্ঠা পৃষ্ঠাগুলি দিন অনুযায়ী",
   "analytic.pv":" পৃষ্ঠাদর্শন",
   "analytic.date":" তারিখ",
   "analytic.email_sent_by_day":" প্রতিদিনের গড় ইমেলগুলি প্রেরিত হয়েছে",
   "analytic.total_emails_sent":" প্রেরিত মোট ইমেল",
   "analytic.emails_sent_by_day":" দিনে প্রেরিত ইমেলগুলি",
   "analytic.email_sent_count":" ইমেল প্রেরিত গণনা",
   "dashboard.tab.sync_order":" আদেশগুলি সিঙ্ক্রোনাইজ করুন",
   "dashboard.tab.enable_email_notification":" ইমেল বিজ্ঞপ্তিগুলি সক্ষম করুন",
   "dashboard.tab.add_tracking_page":" ট্র্যাকিং পৃষ্ঠা যোগ করুন",
   "dashboard.content.enable_email_notification.one":" আপনি যদি ইমেল বিজ্ঞপ্তি পরিষেবাটি সক্ষম করতে চান তবে আপনার গ্রাহকরা তাদের অর্ডারের প্রতিটি প্রধান স্থিতি পরিবর্তনে একটি আপডেট ইমেল পাবেন,",
   "dashboard.content.enable_email_notification.two":" তুমি যেতে পারো",
   "dashboard.content.enable_email_notification.three":" প্রজ্ঞাপন",
   "dashboard.content.enable_email_notification.four":"একটি নির্দিষ্ট চালানের ইভেন্টের ইমেল বিজ্ঞপ্তি সক্ষম করা হবে কিনা তা আরও কনফিগার করতে বাম দিকে ",
   "dashboard.content.add_tracking_page.one":" পার্সেলেকো ট্র্যাকিং পৃষ্ঠার ডিফল্ট লিঙ্কটি আপনার নিজের Shopify স্টোরের ডোমেন ব্যবহার করে।",
   "dashboard.content.add_tracking_page.two":" এটি এসইওর জন্য সহায়ক এবং আপনার স্টোরের আলেক্সা র ্যাঙ্কিং উন্নত করতে পারে। অনুগ্রহ করে নিচের লিংকটি কপি করুন এবং",
   "dashboard.content.add_tracking_page.three":"আমাদের ধাপে ধাপে গাইড অনুসরণ করুন",
   "dashboard.content.add_tracking_page.four":"আপনার Shopify স্টোরে ট্র্যাকিং পৃষ্ঠাটি যোগ করার পরে।",
   "dashboard.content.add_tracking_page.five":" বর্তমানে ট্র্যাকিং পৃষ্ঠাটি ডিফল্টরূপে নীল রঙের প্রয়োগ করে। আপনি বাম দিকে ট্র্যাকিং পৃষ্ঠা ট্যাবে যেতে পারেন এবং ট্র্যাকিং পৃষ্ঠার থিমের রঙটি আপনার পছন্দ মতো সামঞ্জস্য করতে পারেন।,",
   "dashboard.content.sync_order.one":" Parcelecho একটি চেষ্টা করার জন্য ধন্যবাদ!",
   "dashboard.content.sync_order.two":" এখন Parcelecho ট্র্যাকিং সিস্টেমে আপনার সর্বশেষ পরিপূর্ণতার 10 সিঙ্ক্রোনাইজ করেছে। নীচে দেখুন আপনি দেখতে পারেন কিভাবে Parcelecho এর বিশ্লেষণ কাজ করে, সেইসাথে আপনার পরিকল্পনার বর্তমান ব্যবহারের অবস্থা। সিঙ্ক্রোনাইজড পরিপূর্ণতাগুলি দেখতে, এতে যান,",
   "dashboard.content.sync_order.shipment":" চালানসমূহ",
   "dashboard.content.sync_order.three":"বাম দিকে ",
   "dashboard.content.sync_order.four":" Parcelecho ব্যবহার শুরু করার জন্য আপনার সেট-আপ অবিরত রাখতে ইমেল বিজ্ঞপ্তি পরিষেবার পরবর্তী ট্যাবে যান।",
   "dashboard.dismiss":" বরখাস্ত করুন",
   "dashboard.get_started":" শুরু করুন",
   "dashboard.proportion_status.order_by_status":" স্থিতি অনুযায়ী আদেশ",
   "dashboard.intro.vaild_rate":" বৈধ হার",
   "dashboard.intro.vaild_rate_tip":" পেন্ডিং অর্ডারের সংখ্যা বাড়লে বৈধ হার হ্রাস পায়। মনোযোগের প্রয়োজন এমন সমস্ত মুলতুবি অর্ডার দেখতে কার্ডটি ক্লিক করুন।",
   "dashboard.intro.exceptions":" ব্যতিক্রম",
   "dashboard.intro.exceptions_tip":" Exception order-এর বর্তমান সংখ্যা প্রদর্শন করে। সমস্ত ব্যতিক্রম আদেশ দেখতে কার্ডটি ক্লিক করুন।",
   "dashboard.intro.total_exceptions":" মোট ব্যতিক্রম",
   "dashboard.intro.tracking_pv":" ট্র্যাকিং পৃষ্ঠা পিভি",
   "dashboard.intro.tracking_pv_tip":" গত 7 দিনে আপনার ট্র্যাকিং পৃষ্ঠায় পৃষ্ঠা দর্শন।",
   "dashboard.intro.today_views":" আজকের মতামত",
   "dashboard.intro.unfulfilled_orders":" অপূর্ণ আদেশ",
   "dashboard.intro.unfulfilled_orders_tip":" অপূর্ণ আদেশ এবং আইটেমগুলির বর্তমান সংখ্যা প্রদর্শন করে। সমস্ত অসম্পূর্ণ অর্ডার দেখতে কার্ডটি ক্লিক করুন।",
   "dashboard.intro.total_unfulfilled_order":" মোট অপূর্ণ আদেশ",
   "dashboard.intro.unfulfilled_items":" অপূর্ণ বিষয়োপকরণসমূহ",
   "dashboard.intor.total_pending_count":" মোট মুলতুবি গণনা",
   "dashboard.fulfillment_card.title":" দিনের পর দিন পরিপূর্ণতা এবং আদেশ",
   "dashboard.fulfillment_card.daily_fulfillment":" দৈনিক পরিপূর্ণতা",
   "dashboard.fulfillment_card.date":" তারিখ",
   "dashboard.plan.cacel_plan_tip":" আপনি কি নিশ্চিত যে আপনি আপনার বর্তমান পরিকল্পনা বাতিল করতে চান?",
   "dashboard.plan.cacel_text":" কিছু মনে করবেন না,",
   "dashboard.plan.cacel_confirm":" আমার পরিকল্পনা বাতিল করুন",
   "dashboard.plan.overview":" পরিকল্পনা ওভারভিউ",
   "dashboard.plan.upgrade":" উচ্চায়ন",
   "dashboard.plan.tracking_used":" ব্যবহৃত ট্র্যাকিং",
   "dashboard.plan.next_invoice":" পরবর্তী চালান",
   "dashboard.plan.cancel_subscription":" সাবস্ক্রিপশন বাতিল করুন",
   "billing.plan_feature_1":" 800+ বাহক অ্যাক্সেস",
   "billing.plan_feature_2":" ব্র্যান্ডেড ট্র্যাকিং পৃষ্ঠা",
   "billing.plan_feature_3":" ইমেল বিজ্ঞপ্তি",
   "billing.plan_feature_4":" ড্যাশবোর্ড এবং অ্যানালিটিক্স",
   "billing.plan_feature_5":" দ্রুত সমর্থন",
   "billing.plan_feature_6":" নিবেদিত সমর্থন",
   "billing.redeem_coupon":" কুপন রিডিম করুন",
   "billing.please_enter_coupon_code":" অনুগ্রহ করে কুপন কোড লিখুন",
   "billing.total_shipment_last 30 days":" গত 30 দিনের মধ্যে মোট চালান",
   "billing.upgarde_tips":" একবার সমস্ত 100 ট্র্যাকিং ব্যবহার করা হলে আপনাকে একটি প্রদত্ত পরিকল্পনায় আপগ্রেড করতে হবে,",
   "billing.choose_plan":" পরিকল্পনা নির্বাচন করুন",
   "billing.essential":" অপরিহার্য",
   "billing.month":" মাস",
   "billing.orders":"অর্ধক",
   "billing.extra_price":"সাবস্ক্রিপশন কোটার বাইরে প্রতি অতিরিক্ত চালানের জন্য ",
   "billing.tip_1":" পৃষ্ঠা এবং ইমেল বিজ্ঞপ্তিগুলি ট্র্যাক করার জন্য - যদিও এই দুটি পরিষেবা Parcelecho দ্বারা চালিত হয় তবে আমরা আপনাকে নিশ্চিত করি যে আমরা কখনই আপনার গ্রাহকদের কিছু ডাউনলোড করতে বা তাদের তথ্যের জন্য অনুরোধ করতে বলব না, যেমনটি আমাদের কিছু সমকক্ষ করে। আমাদের মিশন আপনাকে একটি ভাল পোস্ট-বিক্রয় অভিজ্ঞতা প্রদান করা হয়, আপনার গ্রাহকদের মধ্যে নিজেদের প্রচার না।",
   "billing.tip_2":" বর্তমানে যখন আপনার সদস্যতা পরিকল্পনায় ট্র্যাকিংয়ের সংখ্যা ব্যবহার করা হয়, তখন টপ-আপ স্বয়ংক্রিয়ভাবে ট্রিগার হবে। প্রতিটি টপ-আপ প্যাকের মধ্যে 25 টি অতিরিক্ত ট্র্যাকিং রয়েছে এবং $ 1 খরচ করে। এই ক্ষেত্রে, যদি আপনি সাবস্ক্রিপশনের স্বয়ংক্রিয় পুনর্নবীকরণের আগে ট্র্যাকিংগুলি গ্রহণ করতে থাকেন - যার সাথে আপনার ট্র্যাকিং কোটাগুলিও পুনর্নবীকরণ করা হয় - আপনার অ্যাকাউন্টটি একাধিক টপ-আপ প্যাকের সাথে যুক্ত করা যেতে পারে। আপনার অ্যাকাউন্টে একটি টপ-আপ প্যাক যোগ করা হলে প্রতিবার আপনি একটি বিজ্ঞপ্তি ইমেল পাবেন। ব্যবহৃত সমস্ত টপ-আপ প্যাকগুলির মোট অর্থ প্রদান আপনার পরবর্তী Shopify বিলিংয়ের দিনে চার্জ করা হবে।,",
   "menu.Dashboard":" ড্যাশবোর্ড",
   "menu.Analytics":" Analytics",
   "menu.Analytics.Notifications":" প্রজ্ঞাপন",
   "menu.Analytics.Tracking Page":" ট্র্যাকিং পৃষ্ঠা",
   "menu.Shipments":" চালানসমূহ",
   "menu.Notifications":" প্রজ্ঞাপন",
   "menu.Tracking Page":" ট্র্যাকিং পৃষ্ঠা",
   "menu.Billing":" বিলিং",
   "menu.Settings":" সেটিংস",
   "settings.title":" সেটিংস",
   "settings.powered_by_parcelecho":" Parcelecho দ্বারা চালিত",
   "settings.powered_by_parcelecho_desc":" ট্র্যাকিং পৃষ্ঠায় পার্সেলেকো দ্বারা চালিত দেখানো বা অপসারণ করুন",
   "settings.get_started_card":" শুরু করা কার্ড পান",
   "settings.get_started1_card_desc":" ড্যাশবোর্ডে শুরু করুন দেখান বা লুকান",
   "shipments.sync_7_days":" 7 দিনের পরিপূর্ণতাগুলি সিঙ্ক করুন",
   "shipments.sync_14_days":" 14 দিনের পরিপূর্ণতাগুলি সিঙ্ক করুন",
   "shipments.sync_30_days":" 30 দিনের পরিপূর্ণতাগুলি সিঙ্ক করুন",
   "shipments.order_no":" অর্ডার নং।",
   "shipments.product":" পণ্য",
   "shipments.carrier":" বাহক",
   "shipments.tracking_number":" ট্র্যাকিং নং।",
   "shipments.ship_date":" জাহাজের তারিখ",
   "shipments.status":" স্থিতি",
   "shipments.from":" থেকে",
   "shipments.to":" করতে",
   "shipments.time_used":" ব্যবহৃত সময়",
   "shipments.on_track":" ট্র্যাকে",
   "shipments.all":" সকল",
   "shipments.pending":" মুলতুবি",
   "shipments.exception":" ব্যতিক্রম",
   "shipments.undelivered":" অবিভাজ্য",
   "shipments.stalled":" স্থগিত",
   "shipments.in_transit":" ট্রানজিটে",
   "shipments.on_delivery":" অন ডেলিভারি",
   "shipments.delivered":" বিতরণ করা হয়েছে",
   "shipments.by_tracking_no":" ট্র্যাকিং নং দ্বারা।",
   "shipments.by_carrier_name":" ক্যারিয়ারের নাম অনুসারে",
   "shipments.by_order_no":" অর্ডার নং।",
   "shipments.search_to_select_carrier":" ক্যারিয়ার নির্বাচন করতে অনুসন্ধান করুন",
   "shipments.type_keyword":" Keyword টাইপ করুন...",
   "shipments.search":" সন্ধান",
   "shipments.reset":" রিসেট",
   "shipments.not_set":" সেট করা নেই",
   "shipments.modify_success":" সফলভাবে সংশোধন করা হয়েছে",
   "shipments.modfiy_error":" ত্রুটি পরিবর্তন করুন।",
   "shipments.select_a_shipment":" অনুগ্রহ করে কমপক্ষে একটি চালান নির্বাচন করুন।",
   "shipments.edit_carrier":" ক্যারিয়ার সম্পাদনা করুন",
   "shipments.cancel":" বাতিল",
   "shipments.submit":" জমা",
   "shipments.change_to":" পরিবর্তন করুন",
   "tracking_page.page_theme":" পৃষ্ঠা থিম",
   "tracking_page.element_color":" উপাদানের রঙ",
   "tracking_page.languages":" ভাষাগুলো",
   "tracking_page.auto_translate":" স্বতঃ অনুবাদ করুন",
   "tracking_page.custom_html":" কাস্টম HTML",
   "tracking_page.preview":" পূর্বরূপ",
   "tracking_page.apply":" প্রয়োগ",
   "tracking_page.pick_color":" ট্র্যাকিং পৃষ্ঠায় উপাদানগুলি (যেমন বোতামগুলি) কাস্টমাইজ করতে আপনি পছন্দ করেন এমন একটি অ্যাকসেন্ট রঙ চয়ন করুন। পরিবর্তনটি দেখতে সংরক্ষণ করুন ক্লিক করুন এবং ট্র্যাকিং পৃষ্ঠাটি পুনরায় লোড করুন।",
   "tracking_page.save":" সংরক্ষণ",
   "tracking_page.send_email":" আপনার যদি অর্ডার ট্র্যাকিং পৃষ্ঠার একটি মকআপ থাকে এবং এটি পার্সেলেচোতে থিম বিকল্পগুলির মধ্যে একটি করতে চান তবে দয়া করে আমাদের কাছে একটি ইমেল পাঠান,",
   "tracking_page.applied":" প্রয়োগ",
   "tracking_page.translate.desc1":" ডিফল্টরূপে ট্র্যাকিং পৃষ্ঠাটি ইংরেজিতে প্রদর্শিত হয় এবং কেবলমাত্র একাধিক প্রদর্শন ভাষা যোগ করা হলে স্বয়ংক্রিয়ভাবে অনুবাদ প্রদান করে। ,",
   "tracking_page.translate.desc2":" পার্সেলেকো এখন ট্র্যাকিং পৃষ্ঠায় ব্যবহৃত শব্দগুলি কাস্টমাইজ করার জন্য 11 টি ভাষা সমর্থন করে। আপনি যদি ইংরেজি ব্যতীত আপনার পছন্দসই প্রদর্শন ভাষা প্রয়োগ করতে চান তবে ড্রপ-ডাউন মেনু থেকে ভাষাটি নির্বাচন করুন এবং পাঠ্য বাক্সে আপনার কাস্টমাইজড অনুবাদ লিখুন।,",
   "tracking_page.select_language":" ভাষা নির্বাচন করুন",
   "tracking_page.setting_saved":" সেটিংস সংরক্ষিত হয়েছে।",
   "tracking_page.send_email_tips":" * আপনি যে ভাষাটি চান তা যদি তালিকায় অন্তর্ভুক্ত না করা হয় তবে নীচের চ্যাট আইকনের মাধ্যমে আমাদের জানাতে দ্বিধা করবেন না বা support@parcelecho.com এ আমাদের একটি ইমেল ছেড়ে দিন।",
   "tracking_page.custom_html.desc":" আপনার ট্র্যাকিং পৃষ্ঠার উপরে এবং নীচে কাস্টম শিরোনাম এবং পাদচরণ তৈরি করুন।",
   "tracking_page.custom_html.before_html_label":" শীর্ষে কাস্টম HTML যোগ করুন (ঐচ্ছিক)",
   "tracking_page.custom_html.after_html_label":" নীচে কাস্টম HTML যোগ করুন (ঐচ্ছিক)",
   "tracking_page.custom_html.css":" কাস্টম সিএসএস (ঐচ্ছিক)",
   "tracking_page.auto_translate.title":" স্বতঃ অনুবাদ সক্ষম করুন (Microsoft Translator দ্বারা চালিত)",
   "tracking_page.auto_translate.desc":" ডিফল্টরূপে সমস্ত ট্র্যাকিং তথ্য ইংরেজিতে প্রদর্শিত হয় এমনকি যখন আপনার ট্র্যাকিং পৃষ্ঠায় একাধিক থিম ভাষা থাকে। আপনি যদি আপনার গ্রাহকদের স্বয়ংক্রিয়ভাবে অনুবাদগুলি অফার করতে চান তবে আপনি স্বতঃ অনুবাদ বিকল্পটি সক্ষম করতে পারেন। ট্র্যাকিং পৃষ্ঠার প্রদর্শন ভাষা অনুসারে ট্র্যাকিং তথ্য স্বয়ংক্রিয়ভাবে অনুবাদ করা হবে,",
   "notifications.title":" ইমেল বিজ্ঞপ্তি",
   "notifications.in_transit_desc":" আপনার গ্রাহককে একটি ইমেল দিয়ে অবহিত করুন যখন তাদের প্যাকেজটি গন্তব্যে যাওয়ার পথে থাকে।",
   "notifications.exception_desc":" যখন ক্যারিয়ারটি খুঁজে পায় যে তাদের প্যাকেজটি অযাচিত বা কেবল ডেলিভারিটি সম্পূর্ণ করতে পারে না কারণ প্যাকেজটি প্রেরকের কাছে ফেরত দেওয়া হয়েছে বা এটি হারিয়ে / ক্ষতিগ্রস্থ হয়েছে তখন আপনার গ্রাহককে একটি ইমেল দিয়ে অবহিত করুন।",
   "notifications.on_delivery_desc":" আপনার গ্রাহককে একটি ইমেল দিয়ে অবহিত করুন যখন তাদের প্যাকেজটি সরবরাহের জন্য বা স্থানীয় অফিস বা ডাক সুবিধায় পিকআপের জন্য প্রস্তুত করা হয়।",
   "notifications.delivered_desc":" যখন আপনার প্যাকেজটি সফলভাবে বিতরণ করা হয়েছে তখন আপনার গ্রাহককে একটি ইমেল দিয়ে অবহিত করুন।",
   "notifications.view_template":" টেমপ্লেট প্রদর্শন করুন"
}