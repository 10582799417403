export default {
      'menu.Dashboard': 'Dashboard',
  'menu.Analytics': 'Analytics',
  'menu.Analytics.Notifications': 'Notifications',
  'menu.Analytics.Tracking Page': 'Tracking Page',
  'menu.Shipments': 'Shipments',
  'menu.Notifications': 'Notifications',
  'menu.Tracking Page': 'Tracking Page',
  'menu.Billing': 'Billing',
  'menu.Settings': 'Settings', 
  
};
