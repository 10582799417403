// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined';
import RiseOutlined from '@ant-design/icons/RiseOutlined';
import SoundOutlined from '@ant-design/icons/SoundOutlined';
import BookOutlined from '@ant-design/icons/BookOutlined';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined'

export default {
  DashboardOutlined,
FileDoneOutlined,
RiseOutlined,
SoundOutlined,
BookOutlined,
SolutionOutlined,
SettingOutlined
}
    