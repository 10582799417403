import analytic from './en-US/analytic';
import billing from './en-US/billing';
import capped_amount from './en-US/capped_amount';

import dashboard from './en-US/dashboard';
import email_translation from './en-US/email_translation';

import menu from './en-US/menu';
import notifications from './en-US/notifications';

import settings from './en-US/settings';
import shipments from './en-US/shipments';
import tracking_page from './en-US/tracking_page';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.copyright.produced': 'Produced by Parcelecho',
  'app.preview.down.block': 'Install',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Instal shopify app',
  ...menu,
  ...settings,
  ...dashboard,
  ...shipments,
  ...analytic,
  ...billing,
  ...tracking_page,
  ...notifications,
  ...capped_amount,
  ...email_translation
};
