
export default{
   "analytic.tracking_page_pv":" Tampilan Halaman Halaman Pelacakan",
   "analytic.last_7_days":" 7 hari terakhir",
   "analytic.last_30_days":" 30 hari terakhir",
   "analytic.last_90_days":" 90 hari terakhir",
   "analytic.average_pv":" Tampilan Halaman Harian Rata-rata",
   "analytic.total_pv":" Total Tampilan Halaman",
   "analytic.tracking_pv_by_day":" Melacak Tampilan Halaman Halaman Menurut Hari",
   "analytic.pv":" Tampilan halaman",
   "analytic.date":" Tanggal",
   "analytic.email_sent_by_day":" Rata-rata Email Harian Terkirim",
   "analytic.total_emails_sent":" Total Email Terkirim",
   "analytic.emails_sent_by_day":" Email yang dikirim berdasarkan hari",
   "analytic.email_sent_count":" Jumlah email terkirim",
   "dashboard.tab.sync_order":" Sinkronkan Pesanan",
   "dashboard.tab.enable_email_notification":" Aktifkan Notifikasi Email",
   "dashboard.tab.add_tracking_page":" Tambahkan Halaman Pelacakan",
   "dashboard.content.enable_email_notification.one":" Jika Anda memilih untuk mengaktifkan layanan pemberitahuan email, pelanggan Anda akan menerima email pembaruan pada setiap perubahan status utama pesanan mereka,",
   "dashboard.content.enable_email_notification.two":" Anda bisa pergi ke",
   "dashboard.content.enable_email_notification.three":" Pemberitahuan",
   "dashboard.content.enable_email_notification.four":"tab di sebelah kiri untuk mengonfigurasi lebih lanjut apakah akan mengaktifkan pemberitahuan email dari peristiwa pengiriman tertentu.",
   "dashboard.content.add_tracking_page.one":" Tautan default halaman pelacakan Parcelecho menggunakan domain toko Shopify Anda sendiri.",
   "dashboard.content.add_tracking_page.two":" Ini sangat membantu SEO dan dapat meningkatkan peringkat Alexa di toko Anda. Silakan salin tautan di bawah ini dan",
   "dashboard.content.add_tracking_page.three":"follow panduan langkah demi langkah kami",
   "dashboard.content.add_tracking_page.four":"pada menambahkan halaman pelacakan ke toko Shopify Anda.",
   "dashboard.content.add_tracking_page.five":" Saat ini halaman pelacakan menerapkan warna biru secara default. Anda bisa pergi ke tab Halaman Pelacakan di sebelah kiri, dan menyesuaikan warna tema halaman pelacakan sesuka Anda.,",
   "dashboard.content.sync_order.one":" Terima kasih telah mencoba Parcelecho!",
   "dashboard.content.sync_order.two":" Sekarang Parcelecho telah menyinkronkan 10 pemenuhan terbaru Anda ke dalam sistem pelacakan. Lihat di bawah ini Anda dapat melihat cara kerja analitik Parcelecho, serta status penggunaan paket Anda saat ini. Untuk melihat pemenuhan yang disinkronkan, buka,",
   "dashboard.content.sync_order.shipment":" Pengiriman",
   "dashboard.content.sync_order.three":"tab di sebelah kiri. Di sana Anda dapat memilih untuk menyinkronkan pesanan yang lebih terpenuhi dan memilih apakah akan menghapus pemenuhan yang disinkronkan agar tidak dilacak untuk menghemat kuota pelacakan. Jika pemenuhan berada di jalurnya, Parcelecho dapat memberi tahu pelanggan Anda dengan email saat pesanan ini diperbarui.,",
   "dashboard.content.sync_order.four":" Buka tab berikutnya pada layanan notifikasi email untuk melanjutkan penyiapan Anda untuk mulai menggunakan Parcelecho.",
   "dashboard.dismiss":" Mengabaikan",
   "dashboard.get_started":" Memulai",
   "dashboard.proportion_status.order_by_status":" Pesanan Berdasarkan Status",
   "dashboard.intro.vaild_rate":" Tarif Yang Valid",
   "dashboard.intro.vaild_rate_tip":" Harga yang Valid turun ketika jumlah pesanan tertunda meningkat. Klik kartu untuk melihat semua pesanan tertunda yang perlu diperhatikan.",
   "dashboard.intro.exceptions":" Pengecualian",
   "dashboard.intro.exceptions_tip":" Menunjukkan jumlah pesanan Pengecualian saat ini. Klik kartu untuk melihat semua pesanan Pengecualian.",
   "dashboard.intro.total_exceptions":" Total Pengecualian",
   "dashboard.intro.tracking_pv":" Pv Halaman Pelacakan",
   "dashboard.intro.tracking_pv_tip":" Tampilan halaman ke halaman pelacakan Anda dalam 7 hari terakhir.",
   "dashboard.intro.today_views":" Pandangan hari ini",
   "dashboard.intro.unfulfilled_orders":" Pesanan yang tidak terpenuhi",
   "dashboard.intro.unfulfilled_orders_tip":" Menunjukkan jumlah pesanan dan item yang tidak terpenuhi saat ini di dalamnya. Klik kartu untuk melihat semua pesanan yang tidak terpenuhi.",
   "dashboard.intro.total_unfulfilled_order":" Total pesanan yang tidak terpenuhi",
   "dashboard.intro.unfulfilled_items":" Item yang Tidak Terpenuhi",
   "dashboard.intor.total_pending_count":" Total Jumlah Tertunda",
   "dashboard.fulfillment_card.title":" Pemenuhan & Pesanan Berdasarkan Hari",
   "dashboard.fulfillment_card.daily_fulfillment":" Pemenuhan Harian",
   "dashboard.fulfillment_card.date":" Tanggal",
   "dashboard.plan.cacel_plan_tip":" Apakah Anda yakin ingin membatalkan paket Anda saat ini?",
   "dashboard.plan.cacel_text":" Tidak apa-apa,",
   "dashboard.plan.cacel_confirm":" Batalkan rencana saya",
   "dashboard.plan.overview":" Gambaran Umum Rencana",
   "dashboard.plan.upgrade":" Upgrade",
   "dashboard.plan.tracking_used":" Pelacakan yang digunakan",
   "dashboard.plan.next_invoice":" Faktur berikutnya",
   "dashboard.plan.cancel_subscription":" Batalkan Langganan",
   "notifications.title":" Pemberitahuan Email",
   "notifications.in_transit_desc":" Beri tahu pelanggan Anda dengan email saat paket mereka sedang dalam perjalanan ke tujuan.",
   "notifications.exception_desc":" Beri tahu pelanggan Anda dengan email ketika operator menemukan paket mereka tidak terkirim atau tidak dapat menyelesaikan pengiriman karena paket telah dikembalikan ke pengirim atau hilang / rusak.",
   "notifications.on_delivery_desc":" Beri tahu pelanggan Anda dengan email ketika paket mereka telah diserahkan untuk pengiriman atau siap untuk diambil di kantor atau fasilitas pos setempat.",
   "notifications.delivered_desc":" Beri tahu pelanggan Anda dengan email ketika paket mereka telah berhasil dikirim.",
   "notifications.view_template":" Lihat Templat",
   "settings.title":" Pengaturan",
   "settings.powered_by_parcelecho":" Didukung oleh Parcelecho",
   "settings.powered_by_parcelecho_desc":" Perlihatkan atau Hapus Didukung Oleh Parcelecho di halaman pelacakan",
   "settings.get_started_card":" Kartu Mulai",
   "settings.get_started1_card_desc":" Memperlihatkan atau Menyembunyikan Mulai Di Dasbor",
   "menu.Dashboard":" Dashboard",
   "menu.Analytics":" Analytics",
   "menu.Analytics.Notifications":" Pemberitahuan",
   "menu.Analytics.Tracking Page":" Halaman Pelacakan",
   "menu.Shipments":" Pengiriman",
   "menu.Notifications":" Pemberitahuan",
   "menu.Tracking Page":" Halaman Pelacakan",
   "menu.Billing":" Penagihan",
   "menu.Settings":" Pengaturan",
   "billing.plan_feature_1":" Akses ke 800+ operator",
   "billing.plan_feature_2":" Halaman pelacakan bermerek",
   "billing.plan_feature_3":" Pemberitahuan email",
   "billing.plan_feature_4":" Dasbor & Analitik",
   "billing.plan_feature_5":" Dukungan cepat",
   "billing.plan_feature_6":" Dukungan khusus",
   "billing.redeem_coupon":" Kupon Redeem",
   "billing.please_enter_coupon_code":" Silakan Masukkan Kode Kupon",
   "billing.total_shipment_last 30 days":" Total pengiriman dalam 30 hari terakhir",
   "billing.upgarde_tips":" Setelah semua 100 pelacakan habis, Anda harus meningkatkan ke paket berbayar,",
   "billing.choose_plan":" Pilih Paket",
   "billing.essential":" PENTING",
   "billing.month":" Bulan",
   "billing.orders":"orders",
   "billing.extra_price":"* $0.04 untuk per pengiriman tambahan di luar kuota langganan",
   "billing.tip_1":" Untuk melacak halaman dan pemberitahuan Email - Meskipun kedua layanan ini didukung oleh Parcelecho, kami memastikan bahwa kami tidak akan pernah meminta pelanggan Anda untuk mengunduh apa pun atau meminta informasi mereka, seperti yang dilakukan beberapa rekan kami. Misi kami adalah untuk memberi Anda pengalaman pasca-penjualan yang lebih baik, bukan mempromosikan diri kami di antara pelanggan Anda.",
   "billing.tip_2":" Saat ini ketika jumlah pelacakan dalam paket langganan Anda habis, top-up akan dipicu secara otomatis. Setiap paket top-up mencakup 25 pelacakan tambahan dan biaya $ 1. Dalam hal ini, jika Anda terus menggunakan pelacakan sebelum perpanjangan otomatis langganan - yang dengannya kuota pelacakan Anda diperbarui juga - akun Anda dapat ditambahkan dengan lebih dari satu paket top-up. Anda akan menerima email pemberitahuan setiap kali paket isi ulang ditambahkan ke akun Anda. Total pembayaran semua paket top-up yang digunakan akan dibebankan pada hari penagihan Shopify Anda berikutnya.,",
   "shipments.sync_7_days":" Sinkronkan pemenuhan 7 hari",
   "shipments.sync_14_days":" Sinkronkan pemenuhan 14 hari",
   "shipments.sync_30_days":" Sinkronkan pemenuhan 30 hari",
   "shipments.order_no":" Pesan No.",
   "shipments.product":" Hasil",
   "shipments.carrier":" Pembawa",
   "shipments.tracking_number":" Pelacakan No.",
   "shipments.ship_date":" Tanggal Pengiriman",
   "shipments.status":" Keadaan",
   "shipments.from":" Dari",
   "shipments.to":" Ke",
   "shipments.time_used":" Waktu yang Digunakan",
   "shipments.on_track":" Di Jalur",
   "shipments.all":" Semua",
   "shipments.pending":" Tertunda",
   "shipments.exception":" Pengecualian",
   "shipments.undelivered":" Tidak terkirim",
   "shipments.stalled":" Terhenti",
   "shipments.in_transit":" Dalam Perjalanan",
   "shipments.on_delivery":" Saat Pengiriman",
   "shipments.delivered":" Disampaikan",
   "shipments.by_tracking_no":" Dengan melacak No.",
   "shipments.by_carrier_name":" Berdasarkan Nama Operator",
   "shipments.by_order_no":" Dengan perintah No.",
   "shipments.search_to_select_carrier":" Cari untuk Memilih Carrier",
   "shipments.type_keyword":" Ketik kata kunci...",
   "shipments.search":" Mencari",
   "shipments.reset":" Reset",
   "shipments.not_set":" Tidak Diatur",
   "shipments.modify_success":" berhasil dimodifikasi",
   "shipments.modfiy_error":" Ubah kesalahan.",
   "shipments.select_a_shipment":" Setidaknya pilih pengiriman.",
   "shipments.edit_carrier":" Edit Operator",
   "shipments.cancel":" Membatalkan",
   "shipments.submit":" Tunduk",
   "shipments.change_to":" Berubah menjadi",
   "tracking_page.page_theme":" Tema Halaman",
   "tracking_page.element_color":" Warna Elemen",
   "tracking_page.languages":" Bahasa",
   "tracking_page.auto_translate":" Terjemahkan Otomatis",
   "tracking_page.custom_html":" HTML Kustom",
   "tracking_page.preview":" Pratayang",
   "tracking_page.apply":" Berlaku",
   "tracking_page.pick_color":" Pilih warna aksen yang Ingin Anda sesuaikan elemen (misalnya tombol) pada halaman pelacakan. Klik Simpan dan muat ulang halaman pelacakan untuk melihat perubahan.",
   "tracking_page.save":" Simpan",
   "tracking_page.send_email":" Jika Anda memiliki mockup halaman pelacakan pesanan dan ingin menjadikannya salah satu opsi tema di Parcelecho, silakan kirim email kepada kami di,",
   "tracking_page.applied":" Diterapkan",
   "tracking_page.translate.desc1":" Secara default halaman pelacakan muncul dalam bahasa Inggris, dan menawarkan terjemahan secara otomatis hanya ketika lebih dari satu bahasa tampilan ditambahkan. ,",
   "tracking_page.translate.desc2":" Parcelecho sekarang mendukung 11 bahasa yang menyesuaikan kata-kata yang digunakan pada halaman Pelacakan. Jika Anda ingin menerapkan bahasa tampilan yang Anda inginkan selain bahasa Inggris pilih bahasa dari menu drop-down dan masukkan terjemahan yang disesuaikan di kotak teks.,",
   "tracking_page.select_language":" Pilih Bahasa",
   "tracking_page.setting_saved":" Pengaturan disimpan.",
   "tracking_page.send_email_tips":"*Jika bahasa yang Anda inginkan tidak termasuk dalam daftar jangan ragu untuk memberi tahu kami melalui ikon obrolan di bawah ini atau kirimkan email kepada kami di support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Buat header dan footer kustom di bagian atas dan bawah halaman pelacakan Anda.",
   "tracking_page.custom_html.before_html_label":" Menambahkan HTML kustom di bagian atas (opsional)",
   "tracking_page.custom_html.after_html_label":" Menambahkan HTML kustom di bagian bawah (opsional)",
   "tracking_page.custom_html.css":" CSS Kustom (opsional)",
   "tracking_page.auto_translate.title":" Aktifkan Terjemahan Otomatis (didukung oleh Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Secara default semua informasi pelacakan muncul dalam bahasa Inggris bahkan ketika halaman pelacakan Anda memiliki beberapa bahasa tema. Jika Anda ingin menawarkan terjemahan secara otomatis kepada pelanggan Anda, Anda dapat mengaktifkan opsi Terjemahan Otomatis. Informasi pelacakan akan diterjemahkan secara otomatis, sesuai dengan bahasa tampilan halaman pelacakan ,"
}