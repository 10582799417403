
export default{
   "analytic.tracking_page_pv":" Nachverfolgen von Seitenaufrufen",
   "analytic.last_7_days":" Letzte 7 Tage",
   "analytic.last_30_days":" Letzte 30 Tage",
   "analytic.last_90_days":" Letzte 90 Tage",
   "analytic.average_pv":" Durchschnittliche tägliche Seitenaufrufe",
   "analytic.total_pv":" Seitenaufrufe insgesamt",
   "analytic.tracking_pv_by_day":" Nachverfolgen von Seitenaufrufen nach Tag",
   "analytic.pv":" Seitenaufrufe",
   "analytic.date":" Datum",
   "analytic.email_sent_by_day":" Durchschnittlich täglich gesendete E-Mails",
   "analytic.total_emails_sent":" Gesamtzahl der gesendeten E-Mails",
   "analytic.emails_sent_by_day":" Tagsüber gesendete E-Mails",
   "analytic.email_sent_count":" Anzahl der gesendeten E-Mails",
   "menu.Dashboard":" Instrumententafel",
   "menu.Analytics":" Analytik",
   "menu.Analytics.Notifications":" Benachrichtigungen",
   "menu.Analytics.Tracking Page":" Tracking-Seite",
   "menu.Shipments":" Verschiffungen",
   "menu.Notifications":" Benachrichtigungen",
   "menu.Tracking Page":" Tracking-Seite",
   "menu.Billing":" Abrechnung",
   "menu.Settings":" Einstellungen",
   "billing.plan_feature_1":" Zugang zu 800+ Carriern",
   "billing.plan_feature_2":" Marken-Tracking-Seite",
   "billing.plan_feature_3":" E-Mail-Benachrichtigung",
   "billing.plan_feature_4":" Dashboard und Analysen",
   "billing.plan_feature_5":" Schneller Support",
   "billing.plan_feature_6":" Dedizierter Support",
   "billing.redeem_coupon":" Gutschein einlösen",
   "billing.please_enter_coupon_code":" Bitte geben Sie den Gutscheincode ein",
   "billing.total_shipment_last 30 days":" Gesamtlieferungen in den letzten 30 Tagen",
   "billing.upgarde_tips":" Sobald alle 100 Trackings aufgebraucht sind, müssen Sie auf einen kostenpflichtigen Plan upgraden.",
   "billing.choose_plan":" Plan auswählen",
   "billing.essential":" WESENTLICH",
   "billing.month":" Monat",
   "billing.orders":"Bestellungen",
   "billing.extra_price":"* 0,04 USD pro zusätzlicher Sendung über die Abonnementkontingente hinaus",
   "billing.tip_1":" Für Tracking-Seiten und E-Mail-Benachrichtigungen - Obwohl diese beiden Dienste von Parcelecho betrieben werden, versichern wir Ihnen, dass wir Ihre Kunden niemals bitten werden, etwas herunterzuladen oder ihre Informationen anzufordern, wie es einige unserer Kollegen tun. Unsere Mission ist es, Ihnen ein besseres Post-Sale-Erlebnis zu bieten und uns nicht bei Ihren Kunden zu bewerben.",
   "billing.tip_2":" Wenn derzeit die Anzahl der Trackings in Ihrem Abonnement aufgebraucht ist, wird die Aufladung automatisch ausgelöst. Jedes Aufladepaket enthält 25 zusätzliche Trackings und kostet $ 1. Wenn Sie in diesem Fall vor der automatischen Verlängerung des Abonnements weiterhin Trackings konsumieren – mit der auch Ihre Tracking-Kontingente verlängert werden – kann Ihr Konto mit mehr als einem Aufladepaket hinzugefügt werden. Sie erhalten jedes Mal eine Benachrichtigungs-E-Mail, wenn Ihrem Konto ein Aufladepaket hinzugefügt wird. Die Gesamtzahlung aller verwendeten Aufladepakete wird an Ihrem nächsten Shopify-Rechnungstag in Rechnung gestellt.",
   "dashboard.tab.sync_order":" Bestellungen synchronisieren",
   "dashboard.tab.enable_email_notification":" E-Mail-Benachrichtigungen aktivieren",
   "dashboard.tab.add_tracking_page":" Tracking-Seite hinzufügen",
   "dashboard.content.enable_email_notification.one":" Wenn Sie den E-Mail-Benachrichtigungsdienst aktivieren, erhalten Ihre Kunden eine Update-E-Mail zu jeder größeren Statusänderung ihrer Bestellungen.",
   "dashboard.content.enable_email_notification.two":" Sie könnten zum",
   "dashboard.content.enable_email_notification.three":" Benachrichtigungen",
   "dashboard.content.enable_email_notification.four":" Registerkarte auf der linken Seite, um weiter zu konfigurieren, ob die E-Mail-Benachrichtigung über ein bestimmtes Versandereignis aktiviert werden soll.",
   "dashboard.content.add_tracking_page.one":" Der Standardlink der Parcelecho-Tracking-Seite verwendet die Domain Ihres eigenen Shopify-Shops.",
   "dashboard.content.add_tracking_page.two":" Dies ist hilfreich für SEO und könnte das Alexa-Ranking Ihres Geschäfts verbessern. Bitte kopieren Sie den untenstehenden Link und",
   "dashboard.content.add_tracking_page.three":"folgen Sie unserer Schritt-für-Schritt-Anleitung",
   "dashboard.content.add_tracking_page.four":" beim Hinzufügen der Tracking-Seite zu Ihrem Shopify-Shop.",
   "dashboard.content.add_tracking_page.five":" Derzeit wendet die Tracking-Seite standardmäßig die Farbe Blau an. Sie können zur Registerkarte Tracking-Seite auf der linken Seite gehen und die Designfarbe der Tracking-Seite nach Belieben anpassen.",
   "dashboard.content.sync_order.one":" Vielen Dank, dass Sie Parcelecho ausprobiert haben!",
   "dashboard.content.sync_order.two":" Jetzt hat Parcelecho 10 Ihrer letzten Fulfillments in das Tracking-System synchronisiert. Im Folgenden können Sie sehen, wie die Analysen von Parcelecho funktionieren, sowie den aktuellen Nutzungsstatus Ihres Plans. Um die synchronisierten Erfüllungen anzuzeigen, gehen Sie zu",
   "dashboard.content.sync_order.shipment":" Verschiffungen",
   "dashboard.content.sync_order.three":" Registerkarte auf der linken Seite. Dort können Sie auswählen, ob mehr ausgeführte Bestellungen synchronisiert werden sollen, und auswählen, ob eine synchronisierte Erfüllung aus der Nachverfolgung entfernt werden soll, um die Nachverfolgungskontingente zu speichern. Wenn eine Erfüllung auf Kurs ist, kann Parcelecho Ihren Kunden mit E-Mails benachrichtigen, wenn diese Bestellung aktualisiert wird.",
   "dashboard.content.sync_order.four":" Gehen Sie zur nächsten Registerkarte des E-Mail-Benachrichtigungsdienstes, um Ihre Einrichtung für die Verwendung von Parcelecho fortzusetzen.",
   "dashboard.dismiss":" Entlassen",
   "dashboard.get_started":" Loslegen",
   "dashboard.proportion_status.order_by_status":" Bestellungen nach Status",
   "dashboard.intro.vaild_rate":" Gültiger Tarif",
   "dashboard.intro.vaild_rate_tip":" Die gültige Rate sinkt, wenn die Anzahl der ausstehenden Bestellungen steigt. Klicken Sie auf die Karte, um alle ausstehenden Bestellungen anzuzeigen, die Aufmerksamkeit erfordern.",
   "dashboard.intro.exceptions":" Ausnahmen",
   "dashboard.intro.exceptions_tip":" Zeigt die aktuelle Anzahl von Ausnahmeaufträgen an. Klicken Sie auf die Karte, um alle Ausnahmereihenfolgen anzuzeigen.",
   "dashboard.intro.total_exceptions":" Ausnahmen insgesamt",
   "dashboard.intro.tracking_pv":" Tracking-Seite PV",
   "dashboard.intro.tracking_pv_tip":" Seitenaufrufe auf Ihrer Tracking-Seite in den letzten 7 Tagen.",
   "dashboard.intro.today_views":" Heutige Ansichten",
   "dashboard.intro.unfulfilled_orders":" Nicht erfüllte Bestellungen",
   "dashboard.intro.unfulfilled_orders_tip":" Zeigt die aktuelle Anzahl der nicht ausgeführten Bestellungen und darin enthaltenen Artikel an. Klicken Sie auf die Karte, um alle nicht ausgeführten Bestellungen anzuzeigen.",
   "dashboard.intro.total_unfulfilled_order":" Nicht ausgeführte Bestellungen insgesamt",
   "dashboard.intro.unfulfilled_items":" Nicht erfüllte Artikel",
   "dashboard.intor.total_pending_count":" Gesamtzahl der ausstehenden",
   "dashboard.fulfillment_card.title":" Fulfillment & Bestellungen nach Tag",
   "dashboard.fulfillment_card.daily_fulfillment":" Tägliche Erfüllung",
   "dashboard.fulfillment_card.date":" Datum",
   "dashboard.plan.cacel_plan_tip":" Sind Sie sicher, dass Sie Ihren aktuellen Plan kündigen möchten?",
   "dashboard.plan.cacel_text":" Nein, egal,",
   "dashboard.plan.cacel_confirm":" Meinen Plan kündigen",
   "dashboard.plan.overview":" Planübersicht",
   "dashboard.plan.upgrade":" Upgrade",
   "dashboard.plan.tracking_used":" Verwendetes Tracking",
   "dashboard.plan.next_invoice":" Nächste Rechnung",
   "dashboard.plan.cancel_subscription":" Abonnement kündigen",
   "notifications.title":" E-Mail-Benachrichtigungen",
   "notifications.in_transit_desc":" Benachrichtigen Sie Ihren Kunden mit einer E-Mail, wenn sein Paket auf dem Weg zum Zielort ist.",
   "notifications.exception_desc":" Benachrichtigen Sie Ihren Kunden mit einer E-Mail, wenn der Spediteur festgestellt hat, dass sein Paket unzustellbar ist oder die Zustellung einfach nicht abschließen kann, weil das Paket an den Absender zurückgeschickt wurde oder es verloren gegangen / beschädigt ist.",
   "notifications.on_delivery_desc":" Benachrichtigen Sie Ihren Kunden mit einer E-Mail, wenn sein Paket zur Zustellung übergeben wurde oder zur Abholung im örtlichen Büro oder in der Posteinrichtung bereit ist.",
   "notifications.delivered_desc":" Benachrichtigen Sie Ihren Kunden mit einer E-Mail, wenn sein Paket erfolgreich zugestellt wurde.",
   "notifications.view_template":" Vorlage anzeigen",
   "settings.title":" Einstellungen",
   "settings.powered_by_parcelecho":" Unterstützt von Parcelecho",
   "settings.powered_by_parcelecho_desc":" Powered By Parcelecho auf der Tracking-Seite anzeigen oder entfernen",
   "settings.get_started_card":" Karte \"Erste Schritte\"",
   "settings.get_started1_card_desc":" Ein- oder Ausblenden der ersten Schritte im Dashboard",
   "shipments.sync_7_days":" 7 Tage Abwicklung synchronisieren",
   "shipments.sync_14_days":" 14 Tage Abwicklung synchronisieren",
   "shipments.sync_30_days":" 30 Tage Abwicklung synchronisieren",
   "shipments.order_no":" Bestell-Nr.",
   "shipments.product":" Produkt",
   "shipments.carrier":" Träger",
   "shipments.tracking_number":" Tracking-Nr.",
   "shipments.ship_date":" Versanddatum",
   "shipments.status":" Status",
   "shipments.from":" Von",
   "shipments.to":" An",
   "shipments.time_used":" Verwendete Zeit",
   "shipments.on_track":" Auf Kurs",
   "shipments.all":" Alle",
   "shipments.pending":" Anhängig",
   "shipments.exception":" Ausnahme",
   "shipments.undelivered":" Nicht geliefert",
   "shipments.stalled":" Abgewürgt",
   "shipments.in_transit":" Unterwegs",
   "shipments.on_delivery":" Bei Lieferung",
   "shipments.delivered":" Abgegeben",
   "shipments.by_tracking_no":" Über die Tracking-Nr.",
   "shipments.by_carrier_name":" Nach Name des Spediteurs",
   "shipments.by_order_no":" Mit Bestell-Nr.",
   "shipments.search_to_select_carrier":" Suche zur Auswahl des Spediteurs",
   "shipments.type_keyword":" Geben Sie Schlüsselwort ein...",
   "shipments.search":" Suchen",
   "shipments.reset":" Zurücksetzen",
   "shipments.not_set":" Nicht festgelegt",
   "shipments.modify_success":"wurde erfolgreich modifiziert",
   "shipments.modfiy_error":" Fehler ändern.",
   "shipments.select_a_shipment":" Bitte wählen Sie zumindest eine Sendung aus.",
   "shipments.edit_carrier":" Spediteur bearbeiten",
   "shipments.cancel":" Abbrechen",
   "shipments.submit":" Senden",
   "shipments.change_to":" Wechseln Sie zu",
   "tracking_page.page_theme":" Seitenthema",
   "tracking_page.element_color":" Elementfarbe",
   "tracking_page.languages":" Sprachen",
   "tracking_page.auto_translate":" Automatische Übersetzung",
   "tracking_page.custom_html":" Benutzerdefinierter HTML-Code",
   "tracking_page.preview":" Vorschau",
   "tracking_page.apply":" Anwenden",
   "tracking_page.pick_color":" Wählen Sie eine Akzentfarbe, die Sie möchten, um die Elemente (z. B. die Schaltflächen) auf der Tracking-Seite anzupassen. Klicken Sie auf Speichern und laden Sie die Tracking-Seite neu, um die Änderung anzuzeigen.",
   "tracking_page.save":" Retten",
   "tracking_page.send_email":" Wenn Sie ein Modell der Bestellverfolgungsseite haben und es zu einer der Themenoptionen in Parcelecho machen möchten, senden Sie uns bitte eine E-Mail an,",
   "tracking_page.applied":" Angewandt",
   "tracking_page.translate.desc1":" Standardmäßig wird die Tracking-Seite in englischer Sprache angezeigt und bietet Übersetzungen nur dann automatisch an, wenn mehr als eine Anzeigesprache hinzugefügt wurde. ,",
   "tracking_page.translate.desc2":" Parcelecho unterstützt jetzt 11 Sprachen, die den auf der Tracking-Seite verwendeten Wortlaut anpassen. Wenn Sie Ihre gewünschte Anzeigesprache außer Englisch anwenden möchten, wählen Sie die Sprache aus dem Dropdown-Menü aus und geben Sie Ihre angepasste Übersetzung in das Textfeld ein.",
   "tracking_page.select_language":" Sprache auswählen",
   "tracking_page.setting_saved":" Einstellungen gespeichert.",
   "tracking_page.send_email_tips":" * Wenn die gewünschte Sprache nicht in der Liste enthalten ist, zögern Sie nicht, uns dies über das Chat-Symbol unten mitzuteilen oder senden Sie uns eine E-Mail an support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Erstellen Sie eine benutzerdefinierte Kopf- und Fußzeile oben und unten auf Ihrer Tracking-Seite.",
   "tracking_page.custom_html.before_html_label":" Fügen Sie oben benutzerdefinierten HTML-Code hinzu (optional)",
   "tracking_page.custom_html.after_html_label":" Fügen Sie unten benutzerdefinierten HTML-Code hinzu(optional)",
   "tracking_page.custom_html.css":" Benutzerdefiniertes CSS (optional)",
   "tracking_page.auto_translate.title":" Aktivieren von Auto Translate (powered by Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Standardmäßig werden alle Tracking-Informationen auf Englisch angezeigt, auch wenn Ihre Tracking-Seite mehrere Theme-Sprachen hat. Wenn Sie Ihren Kunden automatisch Übersetzungen anbieten möchten, können Sie die Option Automatische Übersetzung aktivieren. Tracking-Informationen werden automatisch übersetzt, in Übereinstimmung mit der Anzeigesprache der Tracking-Seite,"
}