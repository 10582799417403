
export default{
   "tracking_page.page_theme":" Thème de la page",
   "tracking_page.element_color":" Couleur de l’élément",
   "tracking_page.languages":" Traduction",
   "tracking_page.auto_translate":" Traduction automatique",
   "tracking_page.custom_html":" HTML personnalisé",
   "tracking_page.preview":" Aperçu",
   "tracking_page.apply":" Appliquer",
   "tracking_page.pick_color":" Choisissez une couleur d’accent que vous aimez pour personnaliser les éléments (par exemple, les boutons) sur la page de suivi. Cliquez sur Enregistrer et rechargez la page de suivi pour afficher la modification.",
   "tracking_page.save":" Sauvegarder",
   "tracking_page.send_email":" Si vous avez une maquette de la page de suivi des commandes et que vous souhaitez en faire l’une des options de thème dans Parcelecho, veuillez nous envoyer un e-mail à,",
   "tracking_page.applied":" Appliqué",
   "tracking_page.translate.desc1":" Par défaut, la page de suivi s’affiche en anglais et propose des traductions automatiques uniquement lorsque plusieurs langues d’affichage ont été ajoutées. ,",
   "tracking_page.translate.desc2":" Parcelecho prend désormais en charge 11 langues pour personnaliser le libellé utilisé sur la page de suivi. Si vous souhaitez appliquer la langue d’affichage souhaitée autre que l’anglais, sélectionnez la langue dans le menu déroulant et entrez votre traduction personnalisée dans la zone de texte.,",
   "tracking_page.select_language":" Sélectionner une langue",
   "tracking_page.setting_saved":" Paramètres enregistrés.",
   "tracking_page.send_email_tips":"*Si la langue souhaitée n’est pas incluse dans la liste, n’hésitez pas à nous le faire savoir via l’icône de chat ci-dessous ou envoyez-nous un e-mail à support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Créez un en-tête et un pied de page personnalisés en haut et en bas de votre page de suivi.",
   "tracking_page.custom_html.before_html_label":" Ajouter du code HTML personnalisé en haut (facultatif)",
   "tracking_page.custom_html.after_html_label":" Ajouter du code HTML personnalisé en bas (facultatif)",
   "tracking_page.custom_html.css":" CSS personnalisé (facultatif)",
   "tracking_page.auto_translate.title":" Activer la traduction automatique (optimisé par Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Par défaut, toutes les informations de suivi s’affichent en anglais même lorsque votre page de suivi a plusieurs langues de thème. Si vous souhaitez proposer automatiquement des traductions à vos clients, vous pouvez activer l’option Traduction automatique. Les informations de suivi seront automatiquement traduites, conformément à la langue d’affichage de la page de suivi,",
   "tracking_page.porduct_recommendation":" Recommandation de produit",
   "tracking_page.not_show_product_recommendation":" Ne pas afficher la recommandation Porduct sur la page de suivi.",
   "tracking_page.collections_show_on_tracking_page":" Les collections s’affichent sur la page trakcing",
   "tracking_page.select_a_collection":" Sélectionner une collection"
}