
export default{
   "analytic.tracking_page_pv":" Suivi des pages vues",
   "analytic.last_7_days":" 7 derniers jours",
   "analytic.last_30_days":" 30 derniers jours",
   "analytic.last_90_days":" 90 derniers jours",
   "analytic.average_pv":" Nombre quotidien moyen de pages vues",
   "analytic.total_pv":" Nombre total de pages vues",
   "analytic.tracking_pv_by_day":" Suivi des pages vues par jour",
   "analytic.pv":" Pages vues",
   "analytic.date":" Date",
   "analytic.email_sent_by_day":" Moyenne quotidienne des e-mails envoyés",
   "analytic.total_emails_sent":" Nombre total d’e-mails envoyés",
   "analytic.emails_sent_by_day":" Courriels envoyés par jour",
   "analytic.email_sent_count":" Nombre d’e-mails envoyés",
   "dashboard.tab.sync_order":" Synchroniser les commandes",
   "dashboard.tab.enable_email_notification":" Activer les notifications par e-mail",
   "dashboard.tab.add_tracking_page":" Ajouter une page de suivi",
   "dashboard.content.enable_email_notification.one":" Si vous choisissez d’activer le service de notification par e-mail, vos clients recevront un e-mail de mise à jour sur chaque changement de statut majeur de leurs commandes,",
   "dashboard.content.enable_email_notification.two":" Vous pourriez vous rendre à l'",
   "dashboard.content.enable_email_notification.three":" Notifications",
   "dashboard.content.enable_email_notification.four":"tab sur la gauche pour configurer davantage l’activation de la notification par e-mail d’un événement d’expédition spécifique.",
   "dashboard.content.add_tracking_page.one":" Le lien par défaut de la page de suivi Parcelecho utilise le domaine de votre propre boutique Shopify.",
   "dashboard.content.add_tracking_page.two":" Ceci est utile pour le référencement et pourrait améliorer le classement Alexa de votre magasin. Veuillez copier le lien ci-dessous et",
   "dashboard.content.add_tracking_page.three":"suivez notre guide étape par étape",
   "dashboard.content.add_tracking_page.four":"sur l’ajout de la page de suivi à votre boutique Shopify.",
   "dashboard.content.add_tracking_page.five":" Actuellement, la page de suivi applique la couleur bleue par défaut. Vous pouvez aller dans l’onglet Page de suivi sur la gauche et ajuster la couleur du thème de la page de suivi à votre guise.,",
   "dashboard.content.sync_order.one":" Merci d’avoir essayé Parcelecho!",
   "dashboard.content.sync_order.two":" Maintenant, Parcelecho a synchronisé 10 de vos dernières exécutions dans le système de suivi. Voir ci-dessous, vous pouvez voir comment fonctionnent les analyses de Parcelecho, ainsi que l’état d’utilisation actuel de votre plan. Pour afficher les exécutions synchronisées, accédez à la section ,",
   "dashboard.content.sync_order.shipment":" Expéditions",
   "dashboard.content.sync_order.three":"tab à gauche. Là, vous pouvez choisir de synchroniser davantage de commandes exécutées et choisir de supprimer ou non un traitement synchronisé du suivi pour enregistrer les quotas de suivi. Si un traitement est sur la bonne voie, Parcelecho peut informer votre client avec des e-mails lorsqu’il est mis à jour cette commande.,",
   "dashboard.content.sync_order.four":" Passez à l’onglet suivant sur le service de notification par e-mail pour continuer votre configuration pour commencer à utiliser Parcelecho.",
   "dashboard.dismiss":" Congédier",
   "dashboard.get_started":" Démarrer",
   "dashboard.proportion_status.order_by_status":" Commandes par statut",
   "dashboard.intro.vaild_rate":" Tarif valide",
   "dashboard.intro.vaild_rate_tip":" Le taux valide diminue lorsque le nombre de commandes en attente augmente. Cliquez sur la carte pour afficher toutes les commandes en attente qui nécessitent une attention particulière.",
   "dashboard.intro.exceptions":" Exceptions",
   "dashboard.intro.exceptions_tip":" Affiche le nombre actuel de commandes d’exception. Cliquez sur la carte pour afficher toutes les commandes d’exception.",
   "dashboard.intro.total_exceptions":" Nombre total d’exceptions",
   "dashboard.intro.tracking_pv":" Page de suivi PV",
   "dashboard.intro.tracking_pv_tip":" Pages vues sur votre page de suivi au cours des 7 derniers jours.",
   "dashboard.intro.today_views":" Vues d’aujourd\\s\\s",
   "dashboard.intro.unfulfilled_orders":" Commandes non exécutées",
   "dashboard.intro.unfulfilled_orders_tip":" Affiche le nombre actuel de commandes et d’articles non exécutés à l’intérieur. Cliquez sur la carte pour afficher toutes les commandes non exécutées.",
   "dashboard.intro.total_unfulfilled_order":" Total des commandes non exécutées",
   "dashboard.intro.unfulfilled_items":" Éléments non remplis",
   "dashboard.intor.total_pending_count":" Nombre total d’attentes",
   "dashboard.fulfillment_card.title":" Exécution et commandes par jour",
   "dashboard.fulfillment_card.daily_fulfillment":" Exécution quotidienne",
   "dashboard.fulfillment_card.date":" Date",
   "dashboard.plan.cacel_plan_tip":" Êtes-vous sûr de vouloir annuler votre plan actuel?",
   "dashboard.plan.cacel_text":" Qu’à cela ne tienne,",
   "dashboard.plan.cacel_confirm":" Annuler mon forfait",
   "dashboard.plan.overview":" Vue d’ensemble du plan",
   "dashboard.plan.upgrade":" Mise à niveau",
   "dashboard.plan.tracking_used":" Suivi utilisé",
   "dashboard.plan.next_invoice":" Facture suivante",
   "dashboard.plan.cancel_subscription":" Annuler l’abonnement",
   "billing.plan_feature_1":" Accès à plus de 800 opérateurs",
   "billing.plan_feature_2":" Page de suivi de marque",
   "billing.plan_feature_3":" Notification par e-mail",
   "billing.plan_feature_4":" Tableau de bord et analytique",
   "billing.plan_feature_5":" Support rapide",
   "billing.plan_feature_6":" Support dédié",
   "billing.redeem_coupon":" Échanger un coupon",
   "billing.please_enter_coupon_code":" S’il vous plaît entrer le code de coupon",
   "billing.total_shipment_last 30 days":" Total des expéditions au cours des 30 derniers jours",
   "billing.upgarde_tips":" Une fois que les 100 suivis sont épuisés, vous devrez passer à un plan payant,",
   "billing.choose_plan":" Choisissez le plan",
   "billing.essential":" ESSENTIEL",
   "billing.month":" Mois",
   "billing.orders":"commandes",
   "billing.extra_price":"* 0,04 $ par envoi supplémentaire au-delà des quotas d’abonnement",
   "billing.tip_1":" Pour les notifications de page de suivi et d’e-mail - Bien que ces deux services soient alimentés par Parcelecho, nous vous assurons que nous ne demanderons jamais à vos clients de télécharger quoi que ce soit ou de demander leurs informations, comme le font certains de nos homologues. Notre mission est de vous fournir une meilleure expérience après-vente, pas de nous promouvoir auprès de vos clients.,",
   "billing.tip_2":" Actuellement, lorsque le nombre de suivis dans votre plan d’abonnement est épuisé, la recharge sera automatiquement déclenchée. Chaque pack de recharge comprend 25 suivis supplémentaires et coûte 1 $. Dans ce cas, si vous continuez à consommer des suivis avant le renouvellement automatique de l’abonnement – avec lequel vos quotas de suivi sont également renouvelés – votre compte pourrait être ajouté avec plus d’un pack de recharge. Vous recevrez un e-mail de notification chaque fois qu’un pack de recharge est ajouté à votre compte. Le paiement total de tous les packs de recharge utilisés sera facturé le prochain jour de facturation Shopify.,",
   "menu.Dashboard":" Tableau de bord",
   "menu.Analytics":" Analytics",
   "menu.Analytics.Notifications":" Notifications",
   "menu.Analytics.Tracking Page":" Page de suivi",
   "menu.Shipments":" Expéditions",
   "menu.Notifications":" Notifications",
   "menu.Tracking Page":" Page de suivi",
   "menu.Billing":" Facturation",
   "menu.Settings":" Paramètres",
   "notifications.title":" Notifications par e-mail",
   "notifications.in_transit_desc":" Avertissez votre client avec un e-mail lorsque son colis est en route vers la destination.",
   "notifications.exception_desc":" Informez votre client avec un e-mail lorsque le transporteur a constaté que son colis n’est pas livrable ou ne peut tout simplement pas terminer la livraison parce que le colis a été retourné à l’expéditeur ou qu’il est perdu / endommagé.",
   "notifications.on_delivery_desc":" Informez votre client avec un e-mail lorsque son colis a été remis pour livraison ou prêt à être ramassé au bureau local ou à l’installation postale.",
   "notifications.delivered_desc":" Avertissez votre client avec un e-mail lorsque son colis a été livré avec succès.",
   "notifications.view_template":" Afficher le modèle",
   "settings.title":" Paramètres",
   "settings.powered_by_parcelecho":" Propulsé par Parcelecho",
   "settings.powered_by_parcelecho_desc":" Afficher ou supprimer Powered By Parcelecho sur la page de suivi",
   "settings.get_started_card":" Carte de démarrage",
   "settings.get_started1_card_desc":" Afficher ou masquer Prise en main sur le tableau de bord",
   "shipments.sync_7_days":" Synchroniser les exécutions de 7 jours",
   "shipments.sync_14_days":" Synchroniser les exécutions de 14 jours",
   "shipments.sync_30_days":" Synchroniser les exécutions de 30 jours",
   "shipments.order_no":" N° de commande",
   "shipments.product":" Produit",
   "shipments.carrier":" Transporteur",
   "shipments.tracking_number":" N° de suivi",
   "shipments.ship_date":" Date d’expédition",
   "shipments.status":" Statut",
   "shipments.from":" De",
   "shipments.to":" À",
   "shipments.time_used":" Temps utilisé",
   "shipments.on_track":" Sur la bonne voie",
   "shipments.all":" Tout",
   "shipments.pending":" En instance",
   "shipments.exception":" Exception",
   "shipments.undelivered":" Non livré",
   "shipments.stalled":" Bloqué",
   "shipments.in_transit":" En transit",
   "shipments.on_delivery":" À la livraison",
   "shipments.delivered":" Livré",
   "shipments.by_tracking_no":" Par n° de suivi",
   "shipments.by_carrier_name":" Par nom de transporteur",
   "shipments.by_order_no":" Par n° d’ordre",
   "shipments.search_to_select_carrier":" Rechercher pour sélectionner un transporteur",
   "shipments.type_keyword":" Tapez le mot-clé...",
   "shipments.search":" Rechercher",
   "shipments.reset":" Réinitialisation",
   "shipments.not_set":" Non défini",
   "shipments.modify_success":"a été modifié avec succès",
   "shipments.modfiy_error":" Erreur de modification.",
   "shipments.select_a_shipment":" Veuillez au moins sélectionner un envoi.",
   "shipments.edit_carrier":" Modifier l’opérateur",
   "shipments.cancel":" Annuler",
   "shipments.submit":" Envoyer",
   "shipments.change_to":" Passer à",
   "tracking_page.page_theme":" Thème de la page",
   "tracking_page.element_color":" Couleur de l’élément",
   "tracking_page.languages":" Traduction",
   "tracking_page.auto_translate":" Traduction automatique",
   "tracking_page.custom_html":" HTML personnalisé",
   "tracking_page.preview":" Aperçu",
   "tracking_page.apply":" Appliquer",
   "tracking_page.pick_color":" Choisissez une couleur d’accent que vous aimez pour personnaliser les éléments (par exemple, les boutons) sur la page de suivi. Cliquez sur Enregistrer et rechargez la page de suivi pour afficher la modification.",
   "tracking_page.save":" Sauvegarder",
   "tracking_page.send_email":" Si vous avez une maquette de la page de suivi des commandes et que vous souhaitez en faire l’une des options de thème dans Parcelecho, veuillez nous envoyer un e-mail à,",
   "tracking_page.applied":" Appliqué",
   "tracking_page.translate.desc1":" Par défaut, la page de suivi s’affiche en anglais et propose des traductions automatiques uniquement lorsque plusieurs langues d’affichage ont été ajoutées. ,",
   "tracking_page.translate.desc2":" Parcelecho prend désormais en charge 11 langues pour personnaliser le libellé utilisé sur la page de suivi. Si vous souhaitez appliquer la langue d’affichage souhaitée autre que l’anglais, sélectionnez la langue dans le menu déroulant et entrez votre traduction personnalisée dans la zone de texte.,",
   "tracking_page.select_language":" Sélectionner une langue",
   "tracking_page.setting_saved":" Paramètres enregistrés.",
   "tracking_page.send_email_tips":"*Si la langue souhaitée n’est pas incluse dans la liste, n’hésitez pas à nous le faire savoir via l’icône de chat ci-dessous ou envoyez-nous un e-mail à support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Créez un en-tête et un pied de page personnalisés en haut et en bas de votre page de suivi.",
   "tracking_page.custom_html.before_html_label":" Ajouter du code HTML personnalisé en haut (facultatif)",
   "tracking_page.custom_html.after_html_label":" Ajouter du code HTML personnalisé en bas (facultatif)",
   "tracking_page.custom_html.css":" CSS personnalisé (facultatif)",
   "tracking_page.auto_translate.title":" Activer la traduction automatique (optimisé par Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Par défaut, toutes les informations de suivi s’affichent en anglais même lorsque votre page de suivi a plusieurs langues de thème. Si vous souhaitez proposer automatiquement des traductions à vos clients, vous pouvez activer l’option Traduction automatique. Les informations de suivi seront automatiquement traduites, conformément à la langue d’affichage de la page de suivi,"
}