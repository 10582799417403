
export default{
   "tracking_page.page_theme":" Side Tema",
   "tracking_page.element_color":" Element farve",
   "tracking_page.languages":" Sprog",
   "tracking_page.auto_translate":" Automatisk oversættelse",
   "tracking_page.custom_html":" Tilpasset HTML",
   "tracking_page.preview":" Preview",
   "tracking_page.apply":" Anvende",
   "tracking_page.pick_color":" Vælg en accentfarve, du kan lide at tilpasse elementerne (f.eks. Knapperne) på sporingssiden. Klik på Gem, og genindlæs sporingssiden for at se ændringen.",
   "tracking_page.save":" Spare",
   "tracking_page.send_email":" Hvis du har en mockup af ordresporingsside og gerne vil gøre det til en af temamulighederne i Parcelecho, bedes du sende en e-mail til os på,",
   "tracking_page.applied":" Anvendes",
   "tracking_page.translate.desc1":" Som standard vises sporingssiden på engelsk og tilbyder kun oversættelser automatisk, når der er tilføjet mere end ét visningssprog. ,",
   "tracking_page.translate.desc2":" Parcelecho understøtter nu 11 sprog, der tilpasser den ordlyd, der bruges på sporingssiden. Hvis du vil anvende det ønskede visningssprog ud over engelsk, skal du vælge sproget i rullemenuen og indtaste din tilpassede oversættelse i tekstfeltet.",
   "tracking_page.select_language":" Vælg sprog",
   "tracking_page.setting_saved":" Indstillinger gemt.",
   "tracking_page.send_email_tips":" * Hvis det ønskede sprog ikke er inkluderet på listen, tøv ikke med at fortælle os det via chatikonet nedenfor eller send os en e-mail på support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Opret brugerdefineret sidehoved og sidefod øverst og nederst på din sporingsside.",
   "tracking_page.custom_html.before_html_label":" Tilføj brugerdefineret HTML øverst (valgfrit)",
   "tracking_page.custom_html.after_html_label":" Tilføj brugerdefineret HTML nederst (valgfrit)",
   "tracking_page.custom_html.css":" Brugerdefineret CSS (valgfrit)",
   "tracking_page.auto_translate.title":" Aktivér Automatisk oversættelse (drevet af Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Som standard vises alle sporingsoplysninger på engelsk, selv når din sporingsside har flere temasprog. Hvis du vil tilbyde oversættelser automatisk til dine kunder, kan du aktivere indstillingen Automatisk oversættelse. Sporingsoplysninger oversættes automatisk i overensstemmelse med visningssproget på sporingssiden ,",
   "tracking_page.porduct_recommendation":" Produkt anbefaling",
   "tracking_page.not_show_product_recommendation":" Vis ikke porduktanbefaling på sporingssiden.",
   "tracking_page.collections_show_on_tracking_page":" Samlinger vises på trakcing-siden",
   "tracking_page.select_a_collection":" Vælg en kollektion"
}