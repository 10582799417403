
export default{
   "tracking_page.page_theme":" Seitenthema",
   "tracking_page.element_color":" Elementfarbe",
   "tracking_page.languages":" Sprachen",
   "tracking_page.auto_translate":" Automatische Übersetzung",
   "tracking_page.custom_html":" Benutzerdefinierter HTML-Code",
   "tracking_page.preview":" Vorschau",
   "tracking_page.apply":" Anwenden",
   "tracking_page.pick_color":" Wählen Sie eine Akzentfarbe, die Sie möchten, um die Elemente (z. B. die Schaltflächen) auf der Tracking-Seite anzupassen. Klicken Sie auf Speichern und laden Sie die Tracking-Seite neu, um die Änderung anzuzeigen.",
   "tracking_page.save":" Retten",
   "tracking_page.send_email":" Wenn Sie ein Modell der Bestellverfolgungsseite haben und es zu einer der Themenoptionen in Parcelecho machen möchten, senden Sie uns bitte eine E-Mail an,",
   "tracking_page.applied":" Angewandt",
   "tracking_page.translate.desc1":" Standardmäßig wird die Tracking-Seite in englischer Sprache angezeigt und bietet Übersetzungen nur dann automatisch an, wenn mehr als eine Anzeigesprache hinzugefügt wurde. ,",
   "tracking_page.translate.desc2":" Parcelecho unterstützt jetzt 11 Sprachen, die den auf der Tracking-Seite verwendeten Wortlaut anpassen. Wenn Sie Ihre gewünschte Anzeigesprache außer Englisch anwenden möchten, wählen Sie die Sprache aus dem Dropdown-Menü aus und geben Sie Ihre angepasste Übersetzung in das Textfeld ein.",
   "tracking_page.select_language":" Sprache auswählen",
   "tracking_page.setting_saved":" Einstellungen gespeichert.",
   "tracking_page.send_email_tips":" * Wenn die gewünschte Sprache nicht in der Liste enthalten ist, zögern Sie nicht, uns dies über das Chat-Symbol unten mitzuteilen oder senden Sie uns eine E-Mail an support@parcelecho.com.,",
   "tracking_page.custom_html.desc":" Erstellen Sie eine benutzerdefinierte Kopf- und Fußzeile oben und unten auf Ihrer Tracking-Seite.",
   "tracking_page.custom_html.before_html_label":" Fügen Sie oben benutzerdefinierten HTML-Code hinzu (optional)",
   "tracking_page.custom_html.after_html_label":" Fügen Sie unten benutzerdefinierten HTML-Code hinzu(optional)",
   "tracking_page.custom_html.css":" Benutzerdefiniertes CSS (optional)",
   "tracking_page.auto_translate.title":" Aktivieren von Auto Translate (powered by Microsoft Translator)",
   "tracking_page.auto_translate.desc":" Standardmäßig werden alle Tracking-Informationen auf Englisch angezeigt, auch wenn Ihre Tracking-Seite mehrere Theme-Sprachen hat. Wenn Sie Ihren Kunden automatisch Übersetzungen anbieten möchten, können Sie die Option Automatische Übersetzung aktivieren. Tracking-Informationen werden automatisch übersetzt, in Übereinstimmung mit der Anzeigesprache der Tracking-Seite,",
   "tracking_page.porduct_recommendation":" Produktempfehlung",
   "tracking_page.not_show_product_recommendation":" Zeigen Sie die Porduct-Empfehlung nicht auf der Tracking-Seite an.",
   "tracking_page.collections_show_on_tracking_page":" Sammlungen werden auf der Trakcing-Seite angezeigt",
   "tracking_page.select_a_collection":" Wählen Sie eine Sammlung aus"
}