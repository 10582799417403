import build from "./da-DK/build";
import capped_amount from "./da-DK/capped_amount";
import email_translation from "./da-DK/email_translation";
import tracking_page from "./da-DK/tracking_page";

export default {
    ...build,
    ...capped_amount,
    ...email_translation,
    ...tracking_page
}